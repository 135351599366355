import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiPlus, BiSearchAlt2, BiSolidDownArrowAlt, BiSolidUpArrowAlt, BiUpArrow } from 'react-icons/bi';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { IoIosClose } from 'react-icons/io';
const FeaturePopup = (props) => {

    const clickMeButtonRef = useRef();

    const [name, setName] = useState("Ali Ahmad")
    const sortData = [{ name: "Brick", }, { name: "Ideal For Outdoor Living", }, { name: "Perfectn For Pets", }, { name: "Staycation Ready", }, { name: "Backyard", }]
    let dispatch = useDispatch();


    let selectedFeatures = useSelector((store) => store.productSection.selectedFeatures)



    return (


        <div className='border border-gray-400 bg-white  p-[10px] rounded-2xl'>
            <div className='  w-full flex justify-center rounded-full border border-gray-600  items-center px-[3px]'>
                <input className='w-[90%]  py-1  input-border bg-inherit px-2' placeholder='Search by address, city, neighbourhood, postal code' type="text" />
                <BiSearchAlt2 className='text-[16px]' />
            </div>
            <div>

                <div >
                    {
                        selectedFeatures.map((data) => {
                            return (
                                <div className='bgLightGray borderPrimery w-fit rounded-full  flex font-semibold items-center py-1 px-3 my-1 primery'>
                                    <p>{data}</p>

                                    <IoIosClose onClick={() => {
                                        dispatch({
                                            type: "D_UN_SLECTED_FEATURE",
                                            payload: data
                                        })


                                    }} className='text-[20px] ml-1 primery' />
                                </div>

                            )
                        })
                    }

                </div>


            </div>
            {
                sortData.map((data) => {
                    const checkFeatures = selectedFeatures.includes(data.name);

                    // console.log('map check', checkFeatures);
                    return (
                        <div>
                            {
                                !checkFeatures ? 
                            
                        <div onClick={() => {
                            dispatch({
                                type: "SLECTED_FEATURE",
                                payload: data.name
                            })

                        }}
                            className='my-1 cursor-pointer px-[5px] flex py-2 hover:bg-gray-100 primeryH  justify-between'>



                            <p className='font-bold'>{data.name}</p>

                            <BiPlus className='text-[16px]' />
                        </div>
                             :''
                            }
                        </div>
                   
                    )
                })
            }
        </div>
    );
}

export default FeaturePopup;
