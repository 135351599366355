import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import House2 from '../../img/house2.jpg'
import { TbMessageCircle2Filled } from "react-icons/tb"

const PropertyDetailTabTwo = () => {

    const [data, setData] = useState(['Garage parking', 'Den', 'Patio', 'Laminate floors', 'Townhouse', 'Private', 'Storage locker', 'Outdoor living', 'Perfect for block parties', 'Perfect for hosting'])
    const Key_Insight=[
    {val1:'This home is',
average:'5%',
val2:'Above the Toronto neigbourhood market average price'},

{val1:'Time on Houseful',
average:'3 Days',
val2:'Above shows how long a home has been for sale on Houseful'},

{val1:'Home features',
average:'Party room',
val2:'Highlighted attributes of this home'},

{val1:'Schools rated',
average:'7/10',
val2:'Data computed based on school score and ranking provided by the Fraser Institute'},

{val1:'Toronto',
average:'-1.42%',
val2:'Home prices in Toronto dropped since May, 2022'},

];
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 0 // optional, default to 1.
    },

    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    custom: {
        breakpoint: { max: 768, min: 512 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 512, min: 200 },
        items: 1.5,
        slidesToSlide: 1 // optional, default to 1.
    }
}

const insightView=[
    {
        img:<img src={House2} alt='' className=' rounded-xl' />,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, {
        img:<img src={House2} alt='' className=' rounded-xl'/>,
        title:"Luxury Home",
        subtitle:"1 Homes"
    }, 
]


    const viewInside = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },

        tablet: {
            breakpoint: { max: 1024, min: 840},
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
         mobile0: {
            breakpoint: { max: 840, min: 768 },
            items:2.5,
            slidesToSlide: 1 
        },
        mobile: {
            breakpoint: { max: 768, min: 570 },
            items:2,
            slidesToSlide: 1 
        },
        mobilesmall: {
            breakpoint: { max: 570, min: 300 },
            items: 1,
            slidesToSlide: 1 
        }
        
    }
    return (
        <div className='px-2'>
            <div>
                <p className='text-[32px] font-bold py-4'>Our Features & Insights</p>
            </div>
            <div className='flex'>
                <TbMessageCircle2Filled className='text-[#BF1017] h-8 w-8'/>
                <div>
                    <p className='text-[22px] font-bold ml-1' >Are these important to you?</p>
                    <p className='font-semibold text-[14px] ml-1 text-gray-500'>Select your Favourite features</p>

                </div>
            </div>
            <div className='flex flex-wrap'>
                {
                    data.map((data, i) => {
                        return (
                            <div className='bg-gray-100 mx-2 rounded-full px-3 py-1 my-2 cursor-pointer'>{data}</div>
                        )
                    })
                }
            </div>
            <div className='text-right'>
                <p className='text-[#BF1017] font-semibold text-[14px] translate-y-7'>See less features</p>
            </div>
            <p className='text-[24px] font-bold'>Key Insights</p>
            <div className='mt-3 border border-[#BF1017] rounded h-[200px] '>
    <Carousel className='mx-3 mt-3' responsive={responsive}>
        {Key_Insight?.map((item) => {
                        return <div key={item.id} className='h-[170px] w-[150px] bg-gray-100  shadow-md border rounded-md p-2 mx-2'>
                <p className='text-base font-semibold'>{item.val1}</p>
                <p className='text-[24px] font-bold text-center my-3'>{item.average}</p>
                <p className='text-xs'>{item.val2}</p>
            </div>
                })}
    </Carousel>
</div>
<div className='w-full rounded bg-gray-100 px-2 py-3 mt-3'> <p className='text-base '>This property is one of 24 for sale in L'Amoreaux. The median days on Houseful for properties in this area is 9.5 with a median list price of $649K . The list price of this property is 1% above the L'Amoreaux median, and has been on the market for 3 days.</p> </div>
      <div className=' border h-[330px]'>
<Carousel className=' mt-3 ' responsive={viewInside}>
        {insightView?.map((item) => (
            <div key={item.id} className='w-[285px] h-[224px] rounded-md p-2 mx-2'>
                <p >{item.img}</p>
                <p className='text-base font-bold'>{item.title}</p>
                <p className='text-xs'>{item.subtitle}</p>
            </div>
        ))}
    </Carousel>
    </div>
      
        </div>
    );
}

export default PropertyDetailTabTwo;
