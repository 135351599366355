import React from "react";
import ReactApexChart from "react-apexcharts";
// Chart01

const Chart01 = (props) => {
    const state = {
        series: [
            {
                name: "Desktops",
                data: props.chartNumber[0].data,
            },
        ],

        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Product Trends by Month",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    };

    return (
        <div className="col-sm-12">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={350}
            />
        </div>
    );
};

// Chart02

export const Chart02 = (props) => {
    const state = {
        series: [
            {
                name: "Desktops",
                data: props.chartNumber[0].data,
            },
        ],

        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Product Trends by Month",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    };

    return (
        <div className="col-sm-12">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={350}
            />
        </div>
    );
};

// Chart03
export const Chart03 = (props) => {
    const state = {
        series: [
            {
                name: "Desktops",
                data: props.chartNumber[0].data,
            },
        ],

        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Product Trends by Month",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    };

    return (
        <div className="col-sm-12">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={350}
            />
        </div>
    );
};

// Chart04
export const Chart04 = (props) => {
    const state = {
        series: [
            {
                name: "Desktops",
                data: props.chartNumber[0].data,
            },
        ],

        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Product Trends by Month",
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
    };

    return (
        <div className="col-sm-12">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={350}
            />
        </div>
    );
};

export default Chart01;
