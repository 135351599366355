import React from 'react';
import { useEffect } from 'react';
import { BiBuilding } from 'react-icons/bi';
import PropertyDetailTab from '../propertyDetailTab/PropertyDetailTab';
import { useState } from 'react';
import PropertyDetailTabOne from '../propertyDetailTabOne/PropertyDetailTabOne';
import PropertyDetailTabTwo from '../ProperyDetailTabTwo/PropertyDetailTabTwo';
import PropertyDetailTabThree from '../PropertyDetailTabThree/PropertyDetailTabThree';
import PropertyDetailTabFour from '../PropertyDetailTabFour/PropertyDetailTabFour';
import ContactAgent from '../contactAgent/ContactAgent';
import HomeDetail from '../contactAgent/HomeDetail';
import ContactAgentModal from '../contactAgent/ContactAgentModal';

const PropertyDetailDiscription = () => {
    const [active, setActive] = useState(0)
    const [agentModule,setAgentModule]=useState(false)
    const [isFixed, setIsFixed] = useState(false);

  const checkPosition = () => {
    const divPosition = document.getElementById('myDiv').getBoundingClientRect().top;
    if (divPosition <= 1) {
      setIsFixed(true);
    }
  };
  const checkTopImgPosition=()=>{
    const homeDetail = document.getElementById('homeDetail').getBoundingClientRect().top;
    if(homeDetail >=1){
        setIsFixed(false)
    }

  }

    const tabSet = (num) => {
        setActive(num)
    }

     const AgentContactForm=()=>{
        setAgentModule(!agentModule);
    }
    useEffect(() => {
        window.addEventListener('scroll', checkPosition);
    
        return () => {
          window.removeEventListener('scroll', checkPosition);
        };
      }, []);
      useEffect(() => {
        window.addEventListener('scroll', checkTopImgPosition);
        return () => {
          window.removeEventListener('scroll', checkTopImgPosition);
        };
      }, []);
    return (
        <div className=' mt-2'>
        
            <div className='max-w-[1400px] relative m-auto flex'>
                <div className=' w-[90%] xl:w-[70%] w-full '>
                    <div className='bg-white text-colour p-2 rounded-md' id='homeDetail'>
                        <div className='flex items-center'>
                                <p className=' font-semibold text-[14px] w-full'>  <span className='text-white bg-[#BF1017] px-2 rounded '><BiBuilding className='inline text-[14px]'/> For Sale New</span> <span className='bg-[#BF1017] text-white px-2 rounded float-right'>5 Hours</span> </p>
                        </div>
                        <div className='sm:flex block'>
                            <div className='sm:w-2/4'>
                                <p className='text-[20px] font-bold  md:text-[24px]'>$9999,90</p>
                                <p >2 + 1 Bd 3 Ba 1,000 - 1,199 Sqft</p>
                                <p>
                                    <strong>3</strong><sup>Bd</sup>&nbsp;&nbsp; <strong>2</strong><sup>Ba</sup>&nbsp;&nbsp; <strong>1,000 - 1,199</strong>&nbsp;<sup>Sqft</sup> &nbsp;
                                </p>
                                <p className='font-semibold'>Get Pre-approved</p>
                            </div>
                            <div className='mt-2'>
                                <p className=' text-[20px] font-bold  md:text-[24px]'>1359 Neilson Rd #59</p>
                                <p className=' font-semibold text-[14px]'>Toronto, ON M1B 0C6</p>
                            </div>
                        </div>
                        <div>
        <button className='mt-2 w-full xl:hidden block bg-[#BF1017] text-white font-bold rounded-full flex justify-center items-center h-8' onClick={AgentContactForm}>Contact Agent</button>
        {agentModule && (
            <div className={`${agentModule ? 'w-[80%] block':'hidden'}`} style={{top: '20%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed', zIndex: 100}}>
                <ContactAgentModal  />
            </div>
        )}
    </div>

                    </div>
                    <div className='w-full h-8 xl:hidden mt-3'><HomeDetail/></div>

                    <div className='mt-10 xl:mt-0 rounded-full py-2'><PropertyDetailTab active={active} tabSet={tabSet}/></div>
                    {
                        active === 0 ? <PropertyDetailTabOne /> : active === 1 ? <PropertyDetailTabTwo/> : active === 2 ? <PropertyDetailTabThree/> : active === 3 ? <PropertyDetailTabFour/> : null
                    }
                    
                </div>
                <div className='xl:w-[30%] flex' id="myDiv" 
      style={{
        position: isFixed ? 'fixed' : 'relative',
        top: isFixed ? '0px' : '',
        right:isFixed ? '8px':''}} >        
              <div className=' xl:w-[80%]  xl:block hidden'><ContactAgent/></div>
                <div className=' xl:w-[20%] xl:block hidden'><HomeDetail/></div>
                </div>

            </div>
{/* } */}
            </div>

    );
}

export default PropertyDetailDiscription;
