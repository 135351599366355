import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Formcom from '../../component/Formcom/Formcom';
import './OfficePage.css'
import { BsFillArrowRightCircleFill } from 'react-icons/bs';


export default function Office() {

    const [open, setOpen] = useState(false);
    return (
        <>
            {/* Section no 1 */}
            <div className="container-fluid text-center hero-section bg-want">
                <div className="description">
                    <h1 className=' font-semibold text-[40px] text-color'>Our Office</h1>
                    <div className='text-secondary mt-4'>At Houseful, we believe that agents can be more successful when homebuyers and sellers have access to the tools, support, and guidance they need throughout their home journey.</div>
                </div>
            </div>

            {/* Section no 2 */}

            <div className="my-Container dis-flex justify-content-between mt-4">

                <div className="Child-1">
                    <div className=' p-2-3 br border-radius-20'>
                        <h5>Contact Information</h5>
                        <div className='my-4'>
                            Houseful™ Brokerage,<br />
                            3080 Yonge Street, Suite 6060<br />
                            Toronto, Ontario M4N 3N1
                        </div>
                        <div className='fs-11 fw-bold text-secondary'>LANGUAGE</div>
                        <div className='mb-4'>English</div>
                        <div className='fs-11 fw-bold text-secondary'>EMAIL</div>
                        <a href="/" className='text-decoration-none'>brokerage@houseful.ca</a>
                    </div>
                </div>
                <div className='Child-2 unique'>
                    <div className=" br border-radius-20 p-2-3">
                        <div className=' flex '>
                       
                            <img className='fit-cover border-radius-20 president' src={require(`../../img/member-1.png`)} alt="" width={"176px"} height={"242px"} />
                          
                            <div className="details my-ms-5 dis-flex flex-column">
                                <d iv className='fs-11 fw-bold text-orange'>OUR TEAM</d>
                                <div className=' fs-5 fw-bold'>Natalka Falcomer</div>
                                <div className='mb-auto-md'>Broker & President of Houseful</div>
                                <div className='fs-11 fw-bold text-secondary mt-2 hidden-md'>ABOUT</div>
                                <div className='hidden-md'>As President of Houseful, Natalka Falcomer leads the development and expansion of the company’s personalized home buying and selling experience...</div>

                                <Collapse in={open}>
                                    <div id="example-collapse-text" className='hidden-md'>
                                        for the Canadian market. Natalka is a tenacious leader with an entrepreneurial spirit and bias toward action. She brings more than 10 years of experience in the real estate industry, and a deep understanding of the challenges Canadian consumers, agents and brokerages face. As a former real estate agent herself, Natalka seeks to build solutions that remove barriers to home ownership for Canadian buyers, while supporting the broader industry through partnerships and tools that enable agents and brokers to better serve clients. She was named one of Canada's Top 100 Elite Women in Real Estate by the Real Estate Professional Magazine in 2016, 2017 and 2019 and is a board member of NAR REACH, an incubator for real estate start-ups.

                                        Prior to joining Houseful, Natalka served as the Executive Vice President of Corporate Development at a Toronto based real estate brokerage. She previously built her own law practice, and hosted a live TV segment answering common legal questions. Natalka is a frequent speaker at industry events such as RealLeasing, SpringFest, OREA conference and the Real Estate Forum and Christie's International Real Estate Conference, and a regular contributor to Real Estate Magazine. In addition, Natalka is the author of The Accommodating Kitchen cookbook which was nominated for Taste Canada's 2012 Food Writing Award and has black belt in shudokan karate.
                                    </div>
                                </Collapse>
                                <div className='text-primary fw-bold me-3 cursor-pointer collapser text-right mb-2 hidden-md' onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}> {open ? "-Read less" : "+Read more "} </div>
                              <div>
                                <div className='text-secondary fs-11 fw-bold my-float-right lg:mt-0 md:mt-3  sm:mt-3 mt-3'>FIND NATALKA FALCOMER ON</div>
                                <div className='text-secondary fs-11 fw-bold hidden-md mb-auto'>EMAIL</div>
                                </div>
                                <a href='/' className='text-decoration-none primery collapser hidden-md'>nfalcomer@houseful.ca</a>
                                <a href="/" className='text-light mt-1 '>
                                    <p className=' justify-center fs-11 w-[20px] h-[20px] flex items-center  rounded-circle font-bold' style={{ backgroundColor: "#043683" }}>in</p>
                                </a>


                            </div>
                        </div>



                        <div className='hidden-lg mt-3'>
                            <div className='fs-11 fw-bold text-secondary mt-2'>ABOUT</div>
                            <p>As President of Houseful, Natalka Falcomer leads the development and expansion of the company’s personalized home buying and selling experience for the Canadian market. Natalka is a tenacious leader with an entrepreneurial spirit and bias toward action. She brings more than 10 years of experience in the real estate industry, and a deep understanding of the challenges Canadian consumers, agents and brokerages face. As a former real estate agent herself, Natalka seeks to build solutions that remove barriers to home ownership for Canadian buyers, while supporting the broader industry through partnerships and tools that enable agents and brokers to better serve clients. She was named one of Canada's Top 100 Elite Women in Real Estate by the Real Estate Professional Magazine in 2016, 2017 and 2019 and is a board member of NAR REACH, an incubator for real estate start-ups.

                                Prior to joining Houseful, Natalka served as the Executive Vice President of Corporate Development at a Toronto based real estate brokerage. She previously built her own law practice, and hosted a live TV segment answering common legal questions. Natalka is a frequent speaker at industry events such as RealLeasing, SpringFest, OREA conference and the Real Estate Forum and Christie's International Real Estate Conference, and a regular contributor to Real Estate Magazine. In addition, Natalka is the author of The Accommodating Kitchen cookbook which was nominated for Taste Canada's 2012 Food Writing Award and has black belt in shudokan karate.</p>
                            <div className='text-secondary fs-11 fw-bold'>EMAIL</div>
                            <a href='/' className='text-decoration-none collapser'>nfalcomer@houseful.ca</a>
        
                        </div>


                        <div className='d-flex mt-5'>
                            <img src={require(`../../img/member-2.png`)} />
                            <div className='my-ms-5 d-flex  flex-column'>
                                <div className='fs-11 fw-bold text-orange'>OUR TEAM</div>
                                <div className=' fs-5 fw-bold mb-auto'>Vineeta Handa</div>
                                <div className='text-secondary fw-bold fs-11'>FIND VINEETA HANDA ON</div>
                                <a href="/" className='text-light ms-2'>
                                    <p className='  fs-11 w-[20px] h-[20px] flex items-center justify-center rounded-circle font-bold' style={{ backgroundColor: "#043683" }}>in</p>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div className=' br border-radius-20 p-2-3 mt-4'>
                        <div className='d-flex'>
                            <img src={require(`../../img/member-3.png`)} alt="" width={"176px"} height={"176px"} />

                            <div className="details my-ms-5 d-flex flex-column">
                                <div className='fs-11 fw-bold text-orange'>OUR ADVISOR</div>
                                <div className=' fs-5 fw-bold'>Chris Heller</div>
                                <div className='mb-auto'>Chief Real Estate Officer</div>
                                <a href='/' className='d-flex text-decoration-none primery flex items-center i-hover'>Read bio
                                  <BsFillArrowRightCircleFill className='ml-1'/>
                                </a>


                            </div>
                        </div>

                        <div className='d-flex mt-5'>
                            <img src={require(`../../img/member-4.png`)} alt="" width={"176px"} height={"176px"} />

                            <div className="details my-ms-5 d-flex flex-column">
                                <div className='fs-11 fw-bold text-orange'>OUR ADVISOR</div>
                                <div className=' fs-5 fw-bold'>Jerimiah Taylor</div>
                                <div className='mb-auto'>VP of Real Estate and Mortgage</div>
                                <a href='/' className='d-flex text-decoration-none primery flex items-center i-hover'>Read bio
                                  <BsFillArrowRightCircleFill className='ml-1'/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-Container mt-5 border-radius-20 bg-want">
                <h3 className='font-semibold text-[40px]  text-center text-color pt-5 '>Apply Now</h3>
                <div style={{ width: "70%", margin: "auto" }}>
                    <p className='text-center my-4'>Apply to join our team of hand-selected agents who are committed to fostering more meaningful connections with buyers and sellers to guide them throughout their home journey. Depending on capacity limits you may be placed on our waitlist. However, we are actively expanding and will reach out as soon as space allows.
                        Please note to qualify we require 5 years of experience and 15+ transactions in the last 12 months</p>

                    <Formcom />

                </div>

            </div>


        </>
    )
}
