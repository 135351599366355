import React, { useState, useEffect } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
const HomeSize = () => {


  const itemList = ['No Min', '500', '1000', '1250', '1500', '2000', '2250', '$2500', '2750', '3000', '3500', '4000', '5000', '7500']
  const itemList2 = ['No Max', '500', '1000', '1250', '1500', '2000', '2250', '$2500', '2750', '3000', '3500', '4000', '5000', '7500']
  const [slectedHouseSizeC, setSlectedHouseSizeC] = useState();
  const [minI, setMinI] = useState();
  let dispatch = useDispatch()

  let slectedHouseSize = useSelector((store) => store.productSection.slectedHouseSize)
  let slectedHouseSizeMax = useSelector((store) => store.productSection.slectedHouseSizeMax)

  useEffect(() => {
    if (slectedHouseSizeC) {
      let find = itemList.indexOf(slectedHouseSizeC);
      setMinI(find)
      console.log('indexFound', find);
    }
  }, [slectedHouseSizeC]);

  useEffect(() => {
    if (Array.isArray(slectedHouseSize)) {
      const result = slectedHouseSize.join('').replace(/\s+/g, ' ');
      setSlectedHouseSizeC(result);
    }
  }, [slectedHouseSize]);

  return (
    <div>
      <p className='font-bold text mb-4 mt-4'>Home Size (sq ft)</p>
      <div className='flex items-center'>
        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p>{!slectedHouseSize > 0 ? 'No Min' : slectedHouseSize}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
            {
              itemList.map((item) => {
                return <Dropdown.Item
                  onClick={() => {
                    dispatch({ type: 'HOMESIZE_MIN', payload: item })
                  }}
                  className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
              })
            }

          </Dropdown.Menu>
        </Dropdown>





        <p className='mx-1'>-</p>

        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p className=''>{!slectedHouseSizeMax > 0 ? 'No Max' : slectedHouseSizeMax}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
            {
              itemList2.map((item, i) => {
                return <div>
                  {
                    minI ?
                      <div>
                        {
                          minI < i &&

                          <Dropdown.Item
                            onClick={() => {
                              dispatch({ type: 'HOMESIZE_MAX', payload: item })
                            }}
                            className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                        }
                      </div>
                      :
                      <Dropdown.Item
                        onClick={() => {
                          dispatch({ type: 'HOMESIZE_MAX', payload: item })
                        }}
                        className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                  }
                </div>
              })
            }

          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
}

export default HomeSize;
