import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { BiHomeAlt2, BiSolidDownArrowAlt, BiSolidUpArrowAlt, BiUpArrow } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
const HomeTypePopup = (props) => {
    const sortData = ['Single Family', 'Multi-Family', 'Condo', 'Townhouse', 'Farm', 'Lot/Land', 'Mobile', 'Other']

    const [checked, setChecked] = useState()
    let dispatch = useDispatch();
    let selectedPropertyType = useSelector((store) => store.productSection.selectedPropertyType)
    return (



        <div className='border bg-white border-gray-400 p-[10px] rounded-2xl'>
            <p onClick={() => {


                dispatch({
                    type: "SORT_SLECT",
                    payload: "Sort"
                })

            }} className=' text-gray-700 cursor-pointer font-bold'>Newest</p>
            {
                sortData.map((data) => {
                    const isSelected = selectedPropertyType.includes(data);
                    return (
                        <div onClick={() => {


                            if (!isSelected) {
                                dispatch({
                                    type: "PROPERTY_SLECT",
                                    payload: data
                                })

                            } else {
                                dispatch({
                                    type: "PROPERTY_UNSLECT",
                                    payload: data
                                })
                            }

                        }} className='my-1 cursor-pointer flex py-2 justify-between hover:bg-gray-100 primeryH min-w-[140px] '>
                            <div className='flex items-center  '>
                            <BiHomeAlt2 className='text-[14px] mr-1' />


                                <p className='font-bold'>{data}</p>
                            </div>

                            <input type='checkbox' checked={isSelected ? true : false} />
                        </div>
                    )
                })
            }
        </div>


    )


}

export default HomeTypePopup;
