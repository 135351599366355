import Container from 'react-bootstrap/Container';
import React, { useState, useEffect, useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from 'react-avatar';
import { PiPhoneBold } from "react-icons/pi";
import LoginModal from './modal/LoginModal';
import Drawar from './drawar/Drawar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BuyTooltip from './buyTooltip/BuyTooltip';
import SellTooltip from './sellTooltip/SellTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { useNavigate } from 'react-router-dom';

function Header() {
    const Navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [show, setShow] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const clickMeButtonRef = useRef()
    const dispatch = useDispatch()

    const signUpComplete = useSelector((store) => store.signUp.signUpComplete);
    // let bathAdd = useSelector((store) => store.productSection.bathAdd)
    console.log('log in header', signUpComplete);


    let token = localStorage.getItem('Token')
    let email = localStorage.getItem('email')
    //    const storedData = localStorage.getItem('yourKey');

    console.log(token);

    // useEffect(() => {
    //   return () => {
    //     console.log('log in header', signUpComplete);

    //   };
    // }, [signUpComplete]);

    const handleClose = () => {
        setShow(false)
        console.log("drawar running");
    };
    const handleShow = () => setShow(true);

const logout = () =>{
    localStorage.removeItem('Token')
    localStorage.removeItem('email')
    window.location.reload();
    
}

    return (
        <Navbar className="bg-white h-[70px]">


            <div className='flex justify-between w-full mt-2 items-center mr-[1rem]'>

                <div className='lg:hidden md:hidden sm:flex flex mr-2 w-full justify-between ml-2'>
                    <div className='border border-gray'>
                        <img onClick={handleShow} className='lg:hidden md:hidden cursor-pointer sm:block text-gray ' src={require('../img/menu.png')} width={20} height={20} alt="" />
                    </div>
                    <div onClick={() => Navigate('/')} className='text-gray text-lg font-bold cursor-pointer'>
                    {/* VIVA House */}
                        <img className='lg:hidden md:hidden sm:block w-[70px] h-[35px] ' src={require('../img/main-logo.png')} width={20} height={20} alt="" />
                    </div>
                    <div >
                        <PiPhoneBold className='text-gray w-[20px] cursor-pointer h-[20px]' />
                    </div>
                </div>
                <div onClick={() => Navigate('/')} className='ml-2 lg:flex md:flex hidden  text-lg font-bold cursor-pointer'>
                <img src={require('../img/main-logo.png')} className='w-[80px] ml-[2.75rem]' alt="" />
                    {/* <img onClick={() => Navigate('/')} className='lg:flex md:flex sm:hidden hidden w-[50px] h-[20px]' src={require('../img/logo.png')} alt="" /> */}
                </div>
                <Nav className=" lg:flex md:flex sm:hidden hidden text-[15px] font-bold  ">
                    {/* <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={BuyTooltip}

                    >
                        <Nav.Link className='' >Buy</Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={SellTooltip}

                    >
                        <Nav.Link className='' href="#link">Sell</Nav.Link>
                    </OverlayTrigger> */}
                    <Nav.Link href="#link">Resource Center</Nav.Link>
                    <Nav.Link href="#link">1-243-764-9867</Nav.Link>
                    {
                        signUpComplete > 1 || token ?
                            <Popover
                                isOpen={isPopoverOpen}
                                positions={['bottom']}
                                padding={10}
                                onClickOutside={() => setIsPopoverOpen(false)}
                                ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                                content={({ position, childRect, popoverRect }) => (
                                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                        position={position}
                                        childRect={childRect}
                                        popoverRect={popoverRect}
                                        // arrowColor={}
                                        arrowSize={10}
                                        arrowStyle={{ opacity: 0.7 }}
                                        className='popover-arrow-container'
                                        arrowClassName='popover-arrow'
                                    >
                                        <div className='bg-white rounded-2xl p-3'>
                                            <p className='font-semibold text-[16px] my-3 hover:bg-gray-100 primeryH cursor-pointer min-w-[150px]'>Saved Homes</p>
                                            <p className='font-semibold text-[16px] my-3 hover:bg-gray-100 primeryH cursor-pointer min-w-[150px]'>Saved Searches</p>
                                            <p className='font-semibold text-[16px] my-3 hover:bg-gray-100 primeryH cursor-pointer min-w-[150px]'>Profile</p>
                                            <p onClick={logout} className='font-semibold text-[16px] my-3 hover:bg-gray-100 primeryH cursor-pointer min-w-[150px]'>Sign Out</p>
                                        </div>


                                    </ArrowContainer>
                                )}
                            >
                                <div className='cursor-pointer' onClick={() => setIsPopoverOpen(true)}>
                                    <Avatar className='mr-2' name={email} size="35" color="#D3D3D3" round={true} />
                                </div>
                            </Popover>
                            :
                            <button onClick={() => setModalShow(true)} className="btn-bg text-white font-semibol  py-2 px-3 rounded-lg">
                            Sign up
                          </button>
                            // <div onClick={() => setModalShow(true)} className='btn-bg text-white font-semibol  py-2 px-3 rounded-lg'>
                            // Sign up
                            // </div>

                    }

                </Nav>

            </div>
            <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Drawar show={show} handleClose={handleClose} />
        </Navbar>
    );
}

export default Header;