import React from 'react';
import Header from '../component/Header';
import SearchBox from '../component/searchbox/SearchBox';
import NewHomeList from '../component/newHomeList/NewHomeList';
import AgentForm from '../component/agentForm/AgentForm';
import OptionComponent from '../component/optionComponent/OptionComponent';
import LatestListing from '../component/latestListing/LatestListing';
import Footer from '../component/footer/Footer';

const Home = () => {
  return (
    <div>
      <div className=" ">
        <div className='bg-img h-[350px]'> 
          <Header />
          <SearchBox />
          <NewHomeList />
          <OptionComponent />
          <AgentForm />
          <LatestListing />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
