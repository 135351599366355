import React, { useState, useEffect } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
const DaysOnHouseful = () => {
  const itemList = ['No Min', '1 Days', '3 Days', '7 Days', '14 Days', '30 Days', '45 Days', '60 Days', '90 Days']
  const itemList2 = ['No Max', '1 Days', '3 Days', '7 Days', '14 Days', '30 Days', '45 Days', '60 Days', '90 Days']
  const [dayofHousFullC, setDayofHousFullC] = useState();
  const [minI, setMinI] = useState();

  let dispatch = useDispatch()

  let dayofHousFull = useSelector((store) => store.productSection.dayofHousFull)
  let dayofHousFullMax = useSelector((store) => store.productSection.dayofHousFullMax)


  useEffect(() => {
    if (dayofHousFullC) {
      let find = itemList.indexOf(dayofHousFullC);
      setMinI(find)
      console.log('indexFound', find);
    }
  }, [dayofHousFullC]);

  useEffect(() => {
    if (Array.isArray(dayofHousFull)) {
      const result = dayofHousFull.join('').replace(/\s+/g, ' ');
      setDayofHousFullC(result);
    }
  }, [dayofHousFull]);


  return (
    <div>
      <p className='font-bold text mb-4 mt-4'>Days on Houseful</p>
      <div className='flex items-center'>
        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p>{!dayofHousFull > 0 ? 'No Min' : dayofHousFull}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0'>
            {
              itemList.map((item) => {
                return <Dropdown.Item
                  onClick={() => {
                    dispatch({ type: 'DaysONHOUSEFULL_MIN', payload: item })
                  }}
                  className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
              })
            }

          </Dropdown.Menu>
        </Dropdown>





        <p className='mx-1'>-</p>

        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p className=''>{!dayofHousFullMax > 0 ? 'No Max' : dayofHousFullMax}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0'>
            {
              itemList2.map((item, i) => {
                return <div>
                  {
                    minI ?
                      <div>
                        {
                          minI < i &&

                          <Dropdown.Item
                            onClick={() => {
                              dispatch({ type: 'DaysONHOUSEFULL_MAX', payload: item })
                            }}
                            className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                        }
                      </div>
                      :
                      <Dropdown.Item
                        onClick={() => {
                          dispatch({ type: 'DaysONHOUSEFULL_MAX', payload: item })
                        }}
                        className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                  }
                </div>
              })
            }

          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
}

export default DaysOnHouseful;
