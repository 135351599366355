import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { BiBuildingHouse } from 'react-icons/bi';

const PropertyCard = () => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
      <div className="relative">
        <img className="w-full" src={require('../../img/homes.png')} alt="Property" />
        <span className="absolute top-0 left-0 bg-blue-600 text-white text-xs px-2 py-1">New 0 Minutes</span>
        <button className="absolute top-0 right-0 p-2">
          <FaHeart className="h-6 w-6 text-gray-500 hover:text-red-500" />
        </button>
      </div>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">$448,800</div>
        <p className="text-gray-700 text-base mb-2">
          1 Bd | 1 Ba | 886 Sqft
        </p>
        <p className="text-gray-700 text-base">
          <BiBuildingHouse className="inline-block h-5 w-5 mr-2 text-gray-500" />
          CONDO
        </p>
        <p className="text-gray-700 text-base">
          158 Front St E #1111
        </p>
      </div>
    </div>
  );
};

export default PropertyCard;