import React from 'react';

const Success = () => {
  return (
    <div className='flex justify-center p-6'>
        <div>
      <div className='bg-green-100 w-[350px] text-center p-[20px] rounded-xl'>
        <p className='font-bold text-[24px]'>Welcom to VIVA</p>
        <p>Check your email to verify and activate your account</p>
      </div>
      <div className='border border-green-200 mt-4'>
        <p className='font-semibold text-gray-400 w-full text-center my-3 '>YOU NOW HAVE ACCESS TO</p>
        <div className='flex justify-center mb-3'>
            <div className=''>
        <p className='my-2 font-semibold'>The latest listings</p>
        <p className='my-2 font-semibold'>Expert help 7 days</p>
        <p className='my-2 font-semibold'>instant update on saves & favourites</p>
        <p className='my-2 font-semibold'>Expert local agent support</p>
        <p className='my-2 font-semibold'>New ways to optimize your mortgage</p>
        </div>
        </div>
      </div>
      <div>
        <button className='border w-[350px] py-2 rounded-full font-semibold text-[18px] mt-4'>
            see Latest Listing
        </button>
      </div>
      </div>
    </div>
  );
}

export default Success;
