import React, { useRef, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import NewHomeCard from '../NewListCard/NewListCard';
import { BiFilter } from 'react-icons/bi';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import SortTooltip from '../sortTooltip/SortTooltip';
import './MapListCard.css'
import MapForm from '../mapForm/MapForm';
import Footer from '../footer/Footer';
import NearbyMapCard from '../nearbyMapCards/NearbyMapCard';
import jsonData from '../../data/data.json';
const MapListingCard = ({ hide }) => {

  const [open, setOpen] = useState(false)
  const [card, setCard] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [mapListCardData, setMapListCardData] = useState('Map')
  const clickMeButtonRef = useRef();

  let data = jsonData.results

  return (
    <div className='px-2 h-[90vh] overflow-auto scroll'>
      <div className='m-2'>
        <p className='text-[20px] font-bold'>Toronto, ON New Listings For Sale</p>
        <div className='flex justify-between'>
          <p className='text-[12px]'>1 - 50 of 1,359 Homes</p>


          <div className='xl:hidden lg:hidden md:hidden sm:block block' >
            <Popover
              isOpen={open === true}
              positions={['bottom']}
              padding={10}
              onClickOutside={() => setIsPopoverOpen(false)}
              ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={'#D3D3D3'}
                  arrowSize={10}
                  arrowStyle={{ opacity: 0.7 }}
                  className='popover-arrow-container'
                  arrowClassName='popover-arrow'
                >


                  <SortTooltip />
                </ArrowContainer>
              )}
            >


              <div onClick={() => setOpen(!open)} className='border cursor-pointer rounded-full  flex font-semibold items-center  textBlack border-gray-400 mx-[2px] py-[3px] px-[10px]'>
                <BiFilter className='mr-2' />
                <p className='mr-2'> Sort</p>
                <div>
                  {
                    open === true ?
                      <img className='  ' src={require('../../img/dropUp.png')} width={20} height={20} alt="" />

                      :

                      <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
                  }
                </div>
              </div>
            </Popover>

          </div>

        </div>
      </div>
      <div className={`${!hide ? 'grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-1' : 'grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-1'} `}>

        {
          data.map((data) => {
            return (<div className='px-[2px] mb-3'>
              <NewHomeCard check={mapListCardData} data={data} />
            </div>
            )
          })
        }

      </div>
      <div className='w-full text-center'>
        <p className='text-[18px] font-semibold'>1 - 50 of 2,611 Homes For Sale in Louisville, KY</p>
      </div>
      <div>
        <MapForm />
      </div>
      <div className='w-full flex justify-center'>
      <div className='max-w-[1500px]'>
        <p className='text=[24px] font-bold'>The Houseful Advantage</p>
        <p className='font-semibold'>Houseful gives you access to the most up-to-the-minute real estate information in Kitchener. As a licensed brokerage in Ontario (and across Canada), Houseful has access to the latest real estate data including <a className='primery'>Kitchener single family homes, Kitchener condos, Kitchener townhouses, Kitchener open houses, Kitchener new listings, Kitchener price reduced homes, Kitchener recently sold homes, market trends, Kitchener homes with a pool </a> and more in Ontario and beyond.
          Not seeing what you're looking for? Expand your real estate search to other areas, such as Waterloo and Woolwich
          When you're ready to buy, Houseful will connect you directly to a licensed local real estate agent who can guide you on your journey to buying your dream home.</p>
      </div>
      </div>
      <div>
        <NearbyMapCard/>
      </div>
      <Footer/>
    </div>
  );
}

export default MapListingCard;
