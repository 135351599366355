import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'

const Neighbourhoods = () => {
 
    const data = ['Regent Park', 'Garden District', 'Cabbagetown' , 'Old Town' , 'Downtown Tronto' , 'Saint Lawrence' , 'West Don Lands', 'East Bayfront' , 'Waterfront' , 'Sout']
    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Neighbourhoods</p>
            <div className='flex flex-wrap  items-center'>

                {
                    data.map((item, index) => {
                        return (
                            <div className='my-1 cursor-pointer flex justify-center  hover:bg-gray-100 primeryH border rounded-md min-w-[80px]   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2'>
                                <p className=''>{item}</p>

                            </div>
                        )
                    })
                }


            </div>

        </div>
    );
}

export default Neighbourhoods;
