import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


export default function Formcom() {

  const [validationMessages, setValidationMessages] = useState({
    pswd: '', // Initial empty message for the password field
  });

  const [canSubmit, setCanSubmit] = useState(true); // Initially, allow submission

  const handleChange = (e) => {
    const { name, value } = e.target;

    // const regEmail = /^[A-Za-z0-9.]{3,20}@[a-z]{3,5}.[a-z]{2,3}$/;
    const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Check if the name is 'email'
    if (name === 'email') {
      if (!regEmail.test(value)) {
        setValidationMessages({
          ...validationMessages,
          email: 'Please enter a valid email', // Set the validation message
        });
        setCanSubmit(false); // Disable submission
      }

      else {
        setValidationMessages({
          ...validationMessages,
          email: '', // Reset the validation message
        });
        setCanSubmit(true); // Allow submission
      }
    }

  }
  return (
    <>
      <form action="" className="form">

        <Row className="g-2">
          <Col sm>
            <FloatingLabel className='text-secondary' controlId="floatingInputGrid" label="Full Name">
              <Form.Control className='input m-1' type="text" placeholder="name@example.com" required />
            </FloatingLabel>
          </Col>

          <Col sm>
            <FloatingLabel className='text-secondary' controlId="floatingInputGrid" label="Email">
              <Form.Control className='input m-1' type="email" name='email' placeholder="name@example.com" required onChange={handleChange} />
              {validationMessages.email && (
                <div className="text-danger">{validationMessages.email}</div>
              )}
            </FloatingLabel>
          </Col>

        </Row>


        <Row className="g-2">
          <Col sm>
            <FloatingLabel className='text-secondary' controlId="floatingInputGrid" label="Phone">
              <Form.Control className='input m-1' type="number" placeholder="name@example.com" required />
            </FloatingLabel>
          </Col>
          <Col sm>
            <FloatingLabel className='text-secondary' controlId="floatingInputGrid" label="Years in Business">
              <Form.Control className='input m-1' type="number" placeholder="name@example.com" required />
            </FloatingLabel>
          </Col>

        </Row>


        <Row className="g-2">
          <Col sm>
            <FloatingLabel className='text-secondary' controlId="floatingInputGrid" label="Service Area">
              <Form.Control className='input m-1' type="text" placeholder="name@example.com" required />
            </FloatingLabel>
          </Col>
          <Col sm>
            <Form.Select size="lg" className='m-1 cursor-pointer custom-select-height'>
              <option className='cursor-pointer'>Lisence Type</option>
              <option className='cursor-pointer'>Sales Representative</option>
              <option className='cursor-pointer'>Broker</option>

            </Form.Select>
          </Col>

          <div className='mt-3'>
            <p id='last'>
              We will contact you regarding your application. By submitting this information, I acknowledge I have read and agree to the <span className='text-primary'>Terms of use</span>, including its Privacy section.</p>
          </div>

        </Row>


        <div className='d-flex justify-content-center'>
          <button className="btn btn-primary border-radius-20 mb-5 w-30"> 
            <span className='my-fw-bold'>Apply</span>
          </button>
        </div>
      </form>
    </>
  )
}
