import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../img/Near_House.jpg'

const ContactAgent = () => {

    return (

       <div className=' border mx-[8px] p-4 rounded-md fixed bg-gray-100 xl:relative'>
            <div className='xl:block hidden'>
                <div className='text-[20px] font-bold'>Go See This Home</div>
                <div className='text-base font-semibold'>with a Toronto <span className='font-bold'>Buyer's</span> Agent</div>
            </div>
            <div className='xl:hidden'>
            <p className='text-sm sm:text-[24px] font-bold mt-2'><center>Contact Agent for 2330 Bridletown Cir #1604</center> </p>
             <div className='flex mt-2'>
              <div ><img src={Home} alt="" className='h-[120px] w-[120px] rounded-xl' /> </div>
              <div className='mx-2'>
                 <div className='text-[20px] font-bold'>#9999,90</div>
                 <div className='text-base font-semibold'>3 bd, 2 bt </div>
                 </div>
             </div>
            </div>

            <form className='mt-3'>
                <input type="text" className='rounded-md w-full h-10 p-2' placeholder='Full Name' />
                <input type="text" className='rounded-md w-full my-2 h-10 p-2' placeholder='Email Address' />
                <input type="text" className='rounded-md w-full h-10 p-2' placeholder='Phone Number' />
            </form>
            <div className='mt-3 text-xs leading-5'>
                    We'll call you within the next business hour to match you with an agent from the Houseful™ agent network. By submitting this information, I acknowledge I have read and agree to the
                <Link className='text-xs font-semibold text-colour'> Terms of use</Link>
                <span className='text-xs'> including its Privacy section.</span>
            </div>
            <div className='text-xs flex mt-3'> 
            <input type="checkbox" className='mx-2 h-6 w-6 border' />
            <div>
             <span>I would like to receive marketing and promotional messages by telephone, text message, and email from Houseful, including information and updates about properties of interest and the services and features of Houseful and our selected partners. I may withdraw my consent at any time. Message and data rates may apply. Consent is not required to receive real estate services.</span></div>
             </div>

             <button className='mt-3 font-bold btn-bg rounded-full w-full text-white m-auto h-10'>Contact Agent</button>
             <hr  className='mt-3'/>

             <div className='font-bold mt-3 '>
                <div className='text-sm text-center'>Not a good item?</div>
                <div className='text-colour text-base text-center'><Link>Schedule a Call</Link></div>
             </div>
             </div>
        
    )
}

export default ContactAgent




