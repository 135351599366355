import React, { useState ,memo} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'
import { BiBuilding, BiHeart, BiHome, BiSolidHeart } from "react-icons/bi";
import { useDispatch , useSelector } from 'react-redux';



function NewHomeCard({ check , data }) {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch()
  let favList = useSelector((store) => store.productSection.favList)
  const fav = () =>{
dispatch({
  type: 'FAV' , 
  payload: data.imgSrc
})
  }
console.log('card' , data);

  return (
    <Card className={`cursor-pointer w-full hover:shadow-md ${check === 'Map' ? 'border-0  ' : ''} `} >
      <div className='absolute w-full pt-2 pl-2 pr-2'>
        <div className='flex justify-between'>
          <div></div>
          <p className='text-white font-semibold px-[2px] btn-bg text-[10px] h-[15px] rounded-full w-[40%] text-center'>New {data.daysOnZillow} Minutes</p>
          
        </div>
      </div>

              <Card.Img variant="top" className={`  ${check === 'Map' ? 'rounded-2xl h-[300px]' : 'h-[200px]' }`} src={show ? require('../../img/map.png') : data.imgSrc} />
            

      <div className='p-2'>
        <div className='flex justify-between'>
          <p className='text-[24px] font-bold'>$  {data.price}</p>

          <div className='w-[25px] h-[25px] bg-white rounded-full flex justify-center items-center'>
         
         {
          favList.includes(data.imgSrc) ? <BiSolidHeart onClick={fav} className='text-colour' /> :  <BiHeart onClick={fav} className='text-colour' />
         }  
          {/* <BiHeart onClick={fav} className='text-blue-600' />
            <BiSolidHeart className='text-blue-600'/> */}
          </div>

                                    {/* preview image */}
          {/* {
            check != 'Map' ?
            <div>
              {show ?
                <img src={require(`../../img/mapIcon.png`)} onClick={() => setShow(false)} className='rounded-full w-[25px] h-[25px]' alt="" />
                :
                <img src={require(`../../img/imgIconn.png`)} onClick={() => setShow(true)} className='rounded-full w-[25px] h-[25px]' alt="" />

              }
            </div>
            :
            ''
          } */}

        </div>
        <Card.Text className='text-[14px] truncate font-semibold'>
        <strong>{data.bedrooms}</strong><sup>Bd</sup>&nbsp;&nbsp; <strong>{data.bathrooms}</strong><sup>Ba</sup>&nbsp;&nbsp; <strong>{data.lotAreaValue}</strong>&nbsp;<sup>{data.lotAreaUnit}</sup> &nbsp; {data.homeType === 'SINGLE_FAMILY' ? <BiHome className='inline '/> : <BiBuilding className='inline'/>} {data.homeType}
        </Card.Text>
        <Card.Text className='text-xs font-semibold'>
         {data.streetAddress}
        </Card.Text>
      </div>
    </Card>
  );
}

export default memo(NewHomeCard);


