import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmailModall from './EmailModall';
import { useDispatch, useSelector } from 'react-redux';
import Stapper from './Stapper';
import Success from './Success';
import LoginWithEmail from './LoginWithEmail';
import PasswordValidator from "password-validator";
import isValidEmail from "email-validator";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import './Modal.css'
import axios from 'axios';
import { useEffect } from 'react';
const LoginModal = (props) => {
    const [stapper, setStapper] = useState(false);
    const [firstClick, setFirstClick] = useState();
    const [secClick, setSecClick] = useState()
    const [thrClick, setThrClick] = useState()
    const [signUp, setSignUp] = useState(false)
    const [signWithEmailView, setSignWithEmailView] = useState(false)
    const [checkEmail, setCheckEmail] = useState()
    const [dataPass, setDataPass] = useState()
    const [validPass, setValidPass] = useState(false)
    const [isValidE, setIsValidE] = useState()
    const [isValidP, setIsValidP]=useState(false);
    const [showPassworderror, setShowPassworderror]=useState(false)
    const [showEmailerror, setShowEmailerror] = useState(false)
    const [signWithEmail, setSignWithEmail] = useState({
        email: '', password: ''

    })
    const [sign, setSign] = useState({

        email: "aligmail.com",
        password: '12345678',
        persona: "JUST_BROWSING",
        homeownerStatus: "NO_HOMEOWNER",
        mortgagePreApprovalStatus: "CASH_BUYER",
        agentCommitmentStatus: "NO_AGENT_COMMITMENT"
    })

    let dispatch = useDispatch()

    // console.log('check email valid', isValidE);

    // console.log('stapper', sign);
    // console.log('sign up', signUp);


    const schema = new PasswordValidator();

    schema.is().min(6).has().not();
    const submitEmail = (dataEmail,dataPassword, catagory) => {

        if (catagory === 'sign up') {
            setSign({ ...sign, email: dataEmail , password:dataPassword })
            // console.log('----------sigun up chuuling--------', email,'--', isValidEmail.validate(email), isValidEmail.validate('usman@gmail.com'));
            setIsValidE(isValidEmail.validate(sign.email));
            setIsValidP(sign.password?.length >= 6);
            if (isValidE && isValidP) {
                setShowPassworderror(false)

                dispatch(
                    {


                        type: "EMAIL_VERIFY",
                        payload: signWithEmail.email


                    }
                )

                // setSignWithEmailView(true)
            setStapper(true)

            } else {
                setShowEmailerror(true)
             if(!isValidP?.length){setShowPassworderror(true)}
            }
        
        }else if(catagory === 'login' ){
            console.log('login chulling');
            setSignWithEmail({ ...signWithEmail, email: dataEmail })
            setIsValidE(isValidEmail.validate(signWithEmail.email));

            if (isValidE) {

                dispatch(
                    {


                        type: "EMAIL_VERIFY",
                        payload: signWithEmail.email


                    }
                )

                setSignWithEmailView(true)
                // setStapper(true)

            } else {
                setShowEmailerror(true)
            }
        }

        // console.log('email a rhi hai', data);
        

     
        // if (isValidE) {

        //     dispatch(
        //         {


        //             type: "EMAIL_VERIFY",
        //             payload: signWithEmail.email


        //         }
        //     )

        //     // setSignWithEmailView(true)
        //     setStapper(true)

        // } else {
        //     setShowEmailerror(true)
        // }
        setCheckEmail(true)




        // console.log("click ho gia" , data);

    }


    const PasswordSet = (data) => {
        const isValid = schema.validate(data);
        // setSignUp(true)
        // setStapper(true)
        // toast.success("Login complete");
        console.log('check is cvalid', isValid);
        if (isValid) {
            setSignWithEmail({ ...signWithEmail, password: data })


        } else {
            setValidPass(true)
        }

    }



    useEffect(() => {

        const log = async () => {
            console.log('emailChulling');
            try {
                let response = await axios.post('http://localhost:8000/api/v1/users/login', signWithEmail);
                console.log(response.status);
                if (response.status === 200) {

                    setSignUp(true)
                    setStapper(true)
                    console.log('data', response.data.data.email);
                    localStorage.setItem('Token', response.data.token);
                    localStorage.setItem('email', response.data.data.email)
                    toast.success("Login complete");
                    dispatch({
                        type: 'SIGHN_UP_COMPLETE',

                    })
                }

            } catch (error) {
                console.log('error', error.response.data.message);
                toast.error(error.response.data.message);
                setSignWithEmailView(false)
            }
        }
        if (signWithEmail.password != '') {
            log()
            // setSignUp(true)
        }
    }, [signWithEmail]);


    const stepOne = (data, i, step) => {
        console.log("cheking first stapper data", data);


        if (step === 'stepOne') {
            setSign({ ...sign, homeownerStatus: data })
            setFirstClick(i)
        }
        if (step === 'stepSec') {
            setSign({ ...sign, mortgagePreApprovalStatus: data })
            setSecClick(i)
        }
        if (step == 'stepthr') {
            setSign({ ...sign, agentCommitmentStatus: data })

            setThrClick(i)

        }
    }

    const steperDataSubmit = async () => {
        // props.onHide()
        // toast.success('SignUp completed')
        // setSignUp(true)
        dispatch(
            {


                type: "SIGN_UP",
                payload: sign


            }
        )


        try {
            let response = await axios.post('http://localhost:8000/api/v1/users/register', sign);
            console.log(response.status);
            if (response.status === 200) {
                setSignUp(true)
                console.log('data', response.data.data.email);
                localStorage.setItem('Token', response.data.token);
                localStorage.setItem('email', response.data.data.email)
                toast.success("Sign up complete");
                dispatch({
                    type: 'SIGHN_UP_COMPLETE',

                })
            }

        } catch (error) {
            console.log('error', error);
            console.log('error message', error.response.data.message);
            toast.error(error.response.data.message);
            setStapper(false)
            props.onHide()
        }
    }


    const closeModall = () => {
        props.onHide()

    }

    const setPass = (e) => {
        setDataPass(e.target.value)
        const isValid = schema.validate(e.target.value);
        console.log('done hoa hai', isValid);

    }

    const checkEmailValidation = (e) => {
        setIsValidE(isValidEmail.validate(e.target.value));

    }
    const checkPasswordValidation = (e) => {
        setIsValidP(e.target.value);

    }
    return (

        <Modal
            {...props}

            id="my"
            contentClassName="radios"
            // dialogClassName="modal-80w"

            fullscreen='sm-down'
            aria-labelledby="example-custom-modal-styling-title"
            centered
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className='flex pr-2 pt-2 justify-end'>
                    <img className=' w-[20px] h-[20px] cursor-pointer' onClick={props.onHide} src={require('../../img/close.png')} alt="" />
                </div>
                {
                    stapper ?
                        <div>
                            {
                                signUp ?
                                    <Success />
                                    :
                                    <Stapper closeModall={closeModall} stepOne={stepOne} firstClick={firstClick} secClick={secClick} thrClick={thrClick} steperDataSubmit={steperDataSubmit} />

                            }


                        </div>
                        :
                        <div>
                            {
                                !signWithEmailView ?
                                    <EmailModall submitEmail={submitEmail} checkEmailValidation={checkEmailValidation} checkPasswordValidation={checkPasswordValidation} showEmailerror={showEmailerror} showPassworderror={showPassworderror}/>
                                    :
                                    <LoginWithEmail PasswordSet={PasswordSet} setPass={setPass} validPass={validPass} />

                            }
                        </div>
                }
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>

    );
}

export default LoginModal;
