import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
const BedsPopup = () => {

    let dispatch = useDispatch();
    
    let beds = ['Any', "1+", "2+", "3+", "4+", "5+"]
    return (
        <div className='border border-gray-400 bg-white  p-[10px] rounded-2xl'>
            {
                beds.map((data , i ) => {
                    return <div onClick={() => {

                        dispatch({
                            type: "BEDS_SLECT",
                            payload: i === 0 ? '' :  data
                        })

                    }} className='my-1 cursor-pointer flex py-2 hover:bg-gray-100 primeryH'>
                        <p className='font-bold min-w-[80px]'>{data}</p>
                    </div>
                })
            }

        </div>
    );
}

export default BedsPopup;
