import React, { useState } from 'react';
import OptionCard from '../optionCrad/OptionCard';
import { IoHomeSharp } from "react-icons/io5";

const OptionComponent = () => {
    const [number, setNumber] = useState([
        { icon:<i className='fa fa-home fs-1 text-colour'></i>, title: "Find your home", para: "Is natural light a must-have? Garage non-negotiable? Find what you’re looking for with our customizable search filters.", button: "Explore homes", link: '/' },
        // { img: require(`../../img/budget.png`), title: "Know your budget", para: "Get pre-approved and be prepared to make an offer as you navigate your home journey", button: "Get pre-approved", link: '/' },
        { icon: <i className='fa fa-user  fs-1 text-colour' ></i>, title: "Find an agent", para: "When you’re ready to make your next move, we’ll match you with an experienced VIVA Network Agent.", button: "Get connected", link: '/verifyAccount/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NGM5MmU3MjE3NTg2YzIxZjdjMGMxZSIsImlhdCI6MTY5OTUxNzE1OSwiZXhwIjoxNjk5Njg5OTU5fQ.dDWPhip5lpx7AZA4b2XicIkU6eqPM0SHZAfMGqJBA8g' }
    ])
    return (
        <div className='flex justify-center mt-5'>
            <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3 max-w-[1400px] mx-3'>
                {
                    number.map((data) => {
                        return <OptionCard data={data} />

                    })
                }
            </div>
        </div>
    );
}

export default OptionComponent;
