import React , {useState , useEffect} from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
const YeareBuilt = () => {

  const itemList = ['No Min', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1995', '1990', '1970', '1960', '1950', '1940', '1930', '1920', '1910', '1900']
  const itemList2 = ['No Max', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1995', '1990', '1970', '1960', '1950', '1940', '1930', '1920', '1910', '1900']
  const [slectedYeareBuiltC, setSlectedYeareBuiltC] = useState();
  const [minI, setMinI] = useState();
  let dispatch = useDispatch()

  let slectedYeareBuilt = useSelector((store) => store.productSection.slectedYeareBuilt)
  let slectedYeareBuiltMax = useSelector((store) => store.productSection.slectedYeareBuiltMax)


  useEffect(() => {
    if (slectedYeareBuiltC) {
      let find = itemList.indexOf(slectedYeareBuiltC);
      setMinI(find)
      console.log('indexFound', find);
    }
  }, [slectedYeareBuiltC]);

  useEffect(() => {
    if (Array.isArray(slectedYeareBuilt)) {
      const result = slectedYeareBuilt.join('').replace(/\s+/g, ' ');
      setSlectedYeareBuiltC(result);
    }
  }, [slectedYeareBuilt]);

  return (
    <div>
      <p className='font-bold text mb-4 mt-4'>Year Built</p>
      <div className='flex items-center'>
        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p>{!slectedYeareBuilt > 0 ? 'No Min' : slectedYeareBuilt}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
            {
              itemList.map((item) => {
                return <Dropdown.Item
                  onClick={() => {
                    dispatch({ type: 'YEAR_BUILT_MIN', payload: item })
                  }}
                  className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
              })
            }

          </Dropdown.Menu>
        </Dropdown>





        <p className='mx-1'>-</p>

        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p className=''>{!slectedYeareBuiltMax > 0 ? 'No Max' : slectedYeareBuiltMax}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
            {
              itemList2.map((item , i) => {
                return <div>
                {
                  minI ?
                    <div>
                      {
                        minI < i &&

                        <Dropdown.Item
                          onClick={() => {
                            dispatch({ type: 'YEAR_BUILT_MAX', payload: item })
                          }}
                          className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                      }
                    </div>
                    :
                    <Dropdown.Item
                      onClick={() => {
                        dispatch({ type: 'YEAR_BUILT_MAX', payload: item })
                      }}
                      className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                }
              </div>
              })
            }

          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
}

export default YeareBuilt;
