import React from 'react';
import { BiHomeAlt2 } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

const PropertyType = () => {
  let typeList = ['Single Family', 'Multi-Family', 'Condo', 'Townhouse', 'Farm', 'Lot/Land', 'Mobile', 'Other']
  let dispatch = useDispatch();

  let selectedPropertyType = useSelector((store) => store.productSection.selectedPropertyType)

  // console.log(selectedPropertyType);

  return (
    <div className=''>
      <p className='font-bold text mb-2'>Property Type</p>
      <div className='flex flex-wrap '>
        {
          typeList.map((data) => {


            const isSelected = selectedPropertyType.includes(data);
            console.log('check', isSelected);



            return (
              <div onClick={() => {
                if (!isSelected) {
                  dispatch({
                    type: "PROPERTY_SLECT",
                    payload: data
                  })

                } else  {
                  dispatch({
                    type: "PROPERTY_UNSLECT",
                    payload: data
                  })
                }
              
              }}
                className={` cursor-pointer ${isSelected ? 'bgLightGray borderPrimery rounded-full w-fit m-1  flex font-semibold items-center py-1 px-2 primery my-1' : 'border rounded-full w-fit  flex font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2'}`}>
                <BiHomeAlt2 className='text-[14px]' />
                <p className='text-[14px]  '>{data}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default PropertyType;
