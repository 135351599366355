import React, { useRef, useEffect, useState } from 'react';
import './Modal.css'
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import GoogleSignIn from '../googleSignIn/GoogleSignIn';
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
const EmailModall = ({ submitEmail, checkEmailValidation,checkPasswordValidation,showEmailerror,showPassworderror }) => {

    const emailRef = useRef(null);
    const passwordRef = useRef(null)
    const [signIn, setSignIn] = useState(false)
    // const login = useGoogleLogin({
    //     onSuccess: tokenResponse => console.log(tokenResponse),
    // });

    const signInSet = () => {
        setSignIn(!signIn)
    }
    return (
        <div className='flex justify-center'>
            <div className='lg:w-[55%] md:w-[55%] sm:[70%] w-[75%] '>
                <div className='flex justify-center my-3'>
                    <img className='  w-[80px] h-[50px]' src={require('../../img/main-logo.png')} alt="" />
                </div>
                <div className='flex justify-center text-center '>
                    <p className='text-[24px] textt font-bold'>Sign in to unlock the best of VIVA</p>
                </div>
                <div className='flex justify-center my-3'>
                    <p className='text-[14px] w-full text-center font-semibold text-gray-700'>
                        Including personalized listing updates, detailed market insights and market support.
                    </p>
                </div>
                <div>
                    {/* <div className="mt-3 mb-2 flex justify-center">
                        <GoogleOAuthProvider clientId="831055028752-aooihnlfcc9e5vm44vb3iv8huvhtlbcv.apps.googleusercontent.com">
                            <GoogleLogin
                                shape={"rectangular"}
                                width={"40%"}
                                onSuccess={(credentialResponse) => {

                                }}
                                onError={() => {
                                    // console.log("Login Failed");
                                }}
                            />
                        </GoogleOAuthProvider>
                    </div> */}

                    {/* <GoogleOAuthProvider  clientId="831055028752-aooihnlfcc9e5vm44vb3iv8huvhtlbcv.apps.googleusercontent.com">

                        <div onClick={() => login()} className='Button_border flex rounded-full py-[10px] px-3 hover:bg-gray-50 cursor-pointer  '>
                            <img className='lg:flex md:flex sm:hidden hidden w-[20px] h-[20px]' src={require('../../img/google.png')} alt="" />
                            <span className='text-[14px] w-full text-center font-semibold text-gray-700'>Continue with google</span>
                        </div>

                    </GoogleOAuthProvider> */}

                    {/* <GoogleSignIn /> */}
                    
                    {/* <div className='Button_border flex rounded-full py-[10px] hover:bg-gray-50 px-3 my-2 cursor-pointer '>
                        <img className='lg:flex md:flex sm:hidden hidden w-[20px] h-[20px]' src={require('../../img/apple.png')} alt="" />
                        <span className='text-[14px] w-full text-center font-semibold text-gray-700'>Continue with Apple</span>
                    </div> */}
                </div>

                {/* <div className='flex justify-center'>
                    <div className='w-full flex items-center justify-center'>
                        <hr className='w-full ' /><p className='mx-2'>or</p>
                        <hr className='w-full' />
                    </div>
                </div> */}

                <div className=''>
                    <div className="relative m-auto mb-1 my-3 w-full" >
                        {/* <input
                            type="text"
                            className="peer  block min-h-[auto] w-full rounded border-0 outline-1 outline-gray-400  px-3 py-[0.50rem] bg-white leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                            id="exampleInput124"
                            aria-describedby="emailHelp124"
                            placeholder="Last name" />
                        <label
                            for="exampleInput124"
                            className="pointer-events-none absolute left-3 top-0  max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.2] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.3rem] peer-focus:scale-[0.6] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none mb-0 dark:text-gray-500 "
                        >Enter Email address
                        </label> */}
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Enter password"
                            className="lg:mb-1 md:mb-1  text-sm"

                        >
                            <Form.Control type="password" ref={passwordRef} onChange={checkPasswordValidation} id='inputH' className=' ' placeholder="name@example.com" />
                        </FloatingLabel>
                        <div className='h-5'>
                        {
                            showPassworderror &&
                            <p className='text-red-600 text-sm'>Password must be 6 Letters</p>
                        }
                        </div>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Enter email address"
                            className="lg:mb-1 md:mb-1  text-sm mt-2"

                        >
                            <Form.Control type="email" ref={emailRef} onChange={checkEmailValidation} id='inputH' className=' ' placeholder="name@example.com" />
                        </FloatingLabel>
                    </div>
                    <div className='h-5' >
                    {
                            showEmailerror &&
                            <p className='text-red-600 text-sm'> Enter correct email</p>
                        } 
                        </div>
                    <div className='flex justify-center mt-3 mb-1'>
                        {
                            signIn ?
                                <button onClick={() => submitEmail(emailRef.current.value , 'login')} className='relative m-auto Button_border w-full rounded-full py-[10px] hover:bg-gray-50 '>Login with Email</button>
                                :
                                <button onClick={() => submitEmail(emailRef.current.value, passwordRef.current.value , 'sign up')} className='relative m-auto Button_border w-full rounded-full py-[10px] hover:bg-gray-50 '>Continue with Email</button>

                        }
                    </div>
                    <div className='w-full text-center'>
                        <p onClick={signInSet} className='primery cursor-pointer font-semibold'>{signIn ? 'Sign up' : 'sign in'}</p>
                    </div>
                </div>

                <div>
                    <p className='text-[10px] font-semibold mb-5 text-gray-700'>
                        By clicking "Continue" you consent to the receipt of marketing and promotional messages from VIVA House, including information and updates about properties of interest and the services and features of VIVA House and our selected partners. You may unsubscribe at any time. By joining VIVA House, you agree to Terms of Use & Privacy & Security
                    </p>
                </div>


            </div>

        </div>
    );
}

export default EmailModall;
