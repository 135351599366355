import React, { useState } from 'react';
import './ErrorPage.css';
import Header from '../../component/Header';
import MapNav from '../../component/mapNave/MapNav';
import { BiBarChart, BiSearch, BiSolidNavigation } from 'react-icons/bi';
import Collapse from 'react-bootstrap/Collapse';

const ErrorPAge = () => {

    const [open, setOpen] = useState(false);
    let page = 'error'
    return (
        <>
            <MapNav page={page} />
            <div className='information   text-center'>
                <div className='w-full flex justify-center'>
                    <img src={require('../../img/homes.png')} className='img h-[250px]' alt="" />
                </div>
                <h4 className='heading  fw-bold fs-2 my-5'>Oops! Looks like this page is off the map.</h4>

                <h6 className='text-color fs-4 fw-bold '>Find your way home.</h6>


                {/* <input class="p-3 search my-2 h-25 rounded-pill" type="text" placeholder="Search by Neighbourhood,city,zip..." /> */}

                <div>
                    <div className='flex justify-center mt-3 mb-3 '>
                        <div className='lg:w-[500px] md:w-[500px] sm:w-[500px] w-[300px] h-[40px] flex justify-center'>
                            <div className={` lg:w-[500px] md:w-[500px] sm:w-[500px] cursor-pointer w-[270px] bg-white absolute border border-black rounded-3xl pt-2  `}>
                                <div className='flex items-center pb-2 w-full px-2'>
                                    <input className='w-[90%] input-border bg-inherit px-2' placeholder='Search by address, city, neighbourhood, postal code' type="text" />
                                    <div className='w-[10%] flex justify-end'>

                                        <BiSearch className='w-[20px] h-[20px] text-gray-400' onClick={() => setOpen(!open)} />
                                    </div>
                                </div>
                                <Collapse in={open}>

                                    <div id="example-collapse-text ">
                                        <div className='flex justify-between t-border  py-1 items-center px-2'>
                                            <p>Current Location</p>
                                            <BiSolidNavigation className='w-[20px] h-[15px] ' />
                                        </div>
                                        <div className='t-border px-2'>
                                            <p className='text-[10px] font-bold text-gray-500 mt-2'>RECENT SEARCHES</p>

                                            <div className='flex justify-between   py-1 items-center'>
                                                <div className='pl-2'>
                                                    <p className='text-sm font-bold p-0  '>kichener ON</p>
                                                    <p className='text-[10px] text-gray-500 font-semibold '>Canada</p>
                                                </div>
                                                < BiSearch className='w-[20px] h-[20px] text-gray-400 ' />
                                            </div>
                                        </div>
                                        <div className='t-border px-2'>
                                            <p className='text-[10px] font-bold text-gray-500 mt-2'>MARKET TRENDS</p>

                                            <div className='flex justify-between   py-1 items-center'>
                                                <div className='pl-2'>
                                                    <p className='text-sm font-bold p-0  '> Markit trend in kichener ON</p>

                                                </div>
                                                < BiBarChart className='w-[20px] h-[20px] text-gray-400 ' />
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>


                <p className='text-color fs-6'>A better way to home search in collaboration with Royal Bank of Canada.</p>
            </div>
        </>
    );
}

export default ErrorPAge;
