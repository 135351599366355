
import React from 'react';
import './App.css';
import AppRoutes from './routes/Routes';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import  ListningModal   from '../src/component/LatestListingCard/listingModal.jsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className=" ">
      <GoogleOAuthProvider clientId="831055028752-aooihnlfcc9e5vm44vb3iv8huvhtlbcv.apps.googleusercontent.com">
        <AppRoutes />
      </GoogleOAuthProvider>
      <ToastContainer />
      {/* <ListningModal/> */}
    </div >
  );
}

export default App;
