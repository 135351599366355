import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { BiSolidDownArrowAlt, BiSolidUpArrowAlt, BiUpArrow } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
const SortTooltip = (props) => {
    const sortData = [{ name: "Price (high to low)", icon: "down" }, { name: "Price (low to high)", icon: "up" }, { name: "Price (big to small)", icon: "down" }, { name: "Price (small to big)", icon: "Up" }, { name: "$ / Sqft (high to low)", icon: "down" }, { name: "$ / Sqft ( low to high )", icon: "Up" }]

    let dispatch = useDispatch();

    return (



        <div className='border border-gray-400 bg-white  p-[10px] rounded-2xl'>
            <p onClick={() => {


                dispatch({
                    type: "SORT_SLECT",
                    payload: "Sort"
                })

            }} className=' text-gray-700 cursor-pointer font-bold'>Newest</p>
            {
                sortData.map((data) => {
                    return (
                        <div onClick={() => {


                            dispatch({
                                type: "SORT_SLECT",
                                payload: data.name
                            })

                        }} className='my-1 cursor-pointer flex py-2 hover:bg-gray-100 primeryH '>
                            {
                                data.icon === "up" ?
                                    <BiSolidUpArrowAlt className='text-[18px]' />
                                    :
                                    <BiSolidDownArrowAlt className='text-[18px]' />
                            }


                            <p className='font-bold'>{data.name}</p>


                        </div>
                    )
                })
            }
        </div>


    )


}

export default SortTooltip;
