import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div>
     <h1>Page Not Found!</h1>
     <Button variant="outline-primary" onClick={() => navigate("/")}>Go to Home Page</Button>
    </div>
  );
}

export default PageNotFound;
