import React, { useState } from 'react';
import { BiSearch, BiSolidNavigation, BiBarChart ,BiHeart } from "react-icons/bi";
import './SearchBox.css'
import Collapse from 'react-bootstrap/Collapse';

const SearchBox = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className='Search-Box w-full h-[300px] pt-16'>
            <div className='flex justify-center'>
                <h1 className='text-white font-bold lg:text-4xl md:text-4xl sm:text-4xl text-2xl mb-4'>Find your new home</h1>
            </div>
            <div className='flex justify-center  '>
                <div className='lg:w-[500px] md:w-[500px] sm:w-[500px] w-[300px] h-[40px] flex justify-center'>
                    <div className={` lg:w-[500px] md:w-[500px] sm:w-[500px] cursor-pointer w-[270px] bg-white absolute  border-gray rounded-3xl pt-2  `}>
                        <div className='flex items-center pb-2 w-full px-2'>
                            <input className='w-[90%] input-border bg-inherit px-2' placeholder='Search by address, city, neighbourhood, postal code' type="text" />
                            <div className='w-[10%] flex justify-end'>

                                <BiSearch className='w-[20px] h-[20px] text-gray-400' onClick={() => setOpen(!open)} />
                            </div>
                        </div>
                        <Collapse in={open}>

                            <div id="example-collapse-text ">
                                <div className='flex justify-between t-border  py-1 items-center px-2'>
                                    <p>Current Location</p>
                                    <BiSolidNavigation className='w-[20px] h-[15px] ' />
                                </div>
                                <div className='t-border px-2'>
                                    <p className='text-[10px] font-bold text-gray-500 mt-2'>RECENT SEARCHES</p>

                                    <div className='flex justify-between   py-1 items-center'>
                                        <div className='pl-2'>
                                            <p className='text-sm font-bold p-0  '>kichener ON</p>
                                            <p className='text-[10px] text-gray-500 font-semibold '>Canada</p>
                                        </div>
                                        < BiSearch className='w-[20px] h-[20px] text-gray-400 ' />
                                    </div>
                                </div>
                                <div className='t-border px-2'>
                                    <p className='text-[10px] font-bold text-gray-500 mt-2'>MARKET TRENDS</p>

                                    <div className='flex justify-between   py-1 items-center'>
                                        <div className='pl-2'>
                                            <p className='text-sm font-bold p-0  '> Markit trend in kichener ON</p>

                                        </div>
                                        < BiBarChart className='w-[20px] h-[20px] text-gray-400 ' />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>

            <div className='flex justify-center w-full mt-4 font-semibold text-white'>
                <a href="">Explore Nearby Homes</a>

            </div>
        </div>
    );
}

export default SearchBox;
