import React from "react";
// import "../App.css";
import { useState } from "react";
import { CiHome, CiLock } from "react-icons/ci";
import { BsCalendarEvent } from "react-icons/bs";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { TbBuildingCommunity } from "react-icons/tb";
import { FiMapPin } from "react-icons/fi";
import Chart from 'react-apexcharts'

import Chart01 ,  { Chart02, Chart03, Chart04 } from "../allCharts/AllCharts";

function TrendStatus() {
  const [chartData, setChartData] = useState([
    0, 10, 20, 40, 50, 60, 70, 80, 90,
  ]);
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [, "Today", , , , "This Month", , , , "This Year", ,],
    },
  });
  const chartData1 = [0, 100, 0, 10, 90, 60, 20, 80, 90];
  const chartData2 = [30, 90, 10, 30, 0, 90, 50, 20, 10];
  const chartData3 = [0, 50, 70, 20, 5, 10, 90, 50, 90];
  const chartData4 = [100, 40, 90, 10, 20, 10, 70, 80, 90];

  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: chartData,
    },
  ]);

  const price = [
    {
      heading: "Median List Price",
      rate: "$749,767",
      progress: -6.07,
    },
    {
      heading: "Median $/Sqft",
      rate: "$497",
      progress: +1.43,
    },
    {
      heading: "Homes For Sale",
      rate: "629",
      progress: +13.74,
    },
    {
      heading: "Median Days on",
      rate: "17",
      progress: -15,
    },
  ];

  const card = [
    {
      heading: "Active Listings",
      icon: "",
      number: 704,
      link: "View All",
    },
    {
      heading: "Singale Family",
      icon: <CiHome />,
      number: 301,
      link: "View All",
    },
    {
      heading: "Townhouse",
      icon: <TbBuildingCommunity />,
      number: 697,
      link: "View All",
    },
    {
      heading: "Condo",
      icon: <HiOutlineBuildingOffice />,
      number: 697,
      link: "View All",
    },
    {
      heading: "New Homes",
      icon: <FiMapPin />,
      number: 697,
      link: "View All",
    },
    {
      heading: "Open House",
      icon: <HiOutlineBuildingOffice />,
      number: 697,
      link: "View All",
    },
    {
      heading: "Day on VIVA",
      icon: <BsCalendarEvent />,
      number: 697,
      link: "View All",
    },
    {
      heading: "Madian List Price",
      icon: "$",
      number: 697,
      link: "View All",
    },
  ];

  const handleButtonClick = (index) => {
    console.log(index);
    switch (index) {
      case 0:
        setSeries([
          {
            data: chartData1,
          },
        ]);
        setChartData([
          {
            data: chartData1,
          },
        ]);
        break;
      case 1:
        setSeries([
          {
            data: chartData2,
          },
        ]);
        setChartData([
          {
            data: chartData2,
          },
        ]);
        break;
      case 2:
        setSeries([
          {
            data: chartData3,
          },
        ]);
        setChartData([
          {
            data: chartData3,
          },
        ]);
        break;
      case 3:
        setSeries([
          {
            data: chartData2,
          },
        ]);
        setChartData([
          {
            data: chartData4,
          },
        ]);
        break;
      default:
        alert("You clicked on a button");
        break;
    }
  };

  return (
    <div className="my-container font-fly">
      <div className="row">
        {/*map-section1-start*/}
        <div className="col-12">
          <p className="mb-3 text-gray-600">REAL ESTATE INSIGHTS</p>
          <h4 className="font-bold text-[22px] mb-2">Kitchener Housing Market Trends</h4>
          <span>KITCHENER, ON REAL ESTATE INSIGHTS</span>
        </div>
        <div className="col-lg-7 col-md-12 col-sm-12">
          <div className="conatainer">
            <div className="row">
              {card.map((item, index) => (
                <>
                  <div className="col-lg-3 col-md-3 col-sm-6 p-1">
                    <div className="active-listing-card p-3 rounded fw-bold">
                      <small className="my-1">{item.heading}</small>
                      <p className="flex  items-center mb-2">
                        <i className="fs-4 mr-1"> {item.icon} </i>
                        <i className="fs-4 fw-bold">{item.number}</i>
                      </p>
                      <a className="primery" href="/">{item.link}</a>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <p className="my-3">
              There are currently 137 new listings out of 704{" "}
              <u className="fw-bold primery">homes for sale in Kitchener, Ontario.</u>{" "}
            </p>
            <div className="position-relative d-flex justify-content-center align-items-center">
              <div className="blur-box">
                <p>
                  here are currently 137 new listings out of 704 homes for sale
                  in Kitchener, Ontario.here are currently 137 new listings out
                  of 704 homes for sale in Kitchener, Ontario here are currently
                  137 new .
                </p>
              </div>
              <div className="position-absolute lock-button">
                <button
                  className="p-2  btn btn-light bg-slate-100 flex items-center  text-primary rounded-pill fw-bold"
                  type="button"
                >
                  <CiLock className="mr-1" /> Activete Your Account
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-12 col-sm-12">
          <img className="mx-auto" src={require(`../../img/map.png`)} alt="" width={"90%"} />
        </div>
        {/*map-section1-end*/}
        {/*pricing-table-section2-start*/}
        <div className="row my-5">
          <div className="col-lg-7 col-md-6 col-sm-12">
            {" "}
            <h2 className="font-bold text-[22px] mb-2">Kitchener Housing Prices</h2>
            <table className="table">
              <thead>
                <tr style={{ backgroundColor: "black" }}>
                  <th scope="col"></th>
                  <th scope="col">Today</th>
                  <th scope="col">1Month Ago</th>
                  <th scope="col">6Month Ago</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Median List Price</th>
                  <td>724,999</td>
                  <td> 743,475</td>
                  <td>792,490</td>
                </tr>
                <tr>
                  <th scope="row">Median $/Sqft</th>
                  <td>495</td>
                  <td>486</td>
                  <td>490</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-5 col-md-6 col-sm-12">
            <Chart options={options} series={series} type="bar" width="100%" />
          </div>
        </div>
        {/*pricing-table-section2-end*/}
        {/* City-Supply-Summary-section-3-start*/}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row ">
                <h2 className="font-bold text-[22px] mb-2">Kitchener City Supply Summary</h2>
                {price.map((item, index) => (
                  <div className="sales-catagory-card">
                    <div className="active-listing-card p-3 rounded fw-bold">
                      <small className="my-1">{item.heading}</small>
                      <p className="mb-0">
                        <i className="fs-4">{item.rate}</i>
                        <i className="fs-4 fw-bold"></i>
                      </p>
                      <button
                        id={`btn${index}`}
                        onClick={() => handleButtonClick(index)}
                        className={`${
                          item.progress > 0 ? "value-incease" : "value-decrease"
                        } border-0  progress-number`}
                      >
                        {item.progress}% month/month
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <p>
                The median list price of homes in Kitchener, ON overall was
                $749,767 in September 2023, compared to $798,183 in August 2023,
                trending down by 6.07% month-over-month.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <Chart01 chartNumber={chartData} />
            </div>
          </div>
        </div>
        {/* City-Supply-Summary-section-3-end*/}
        {/* single-family-homes-section-4-start*/}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <h2 className="font-bold text-[22px] mb-2">
                  Kitchener City Supply Summary for Single Family Homes
                </h2>
                {price.map((item, index) => (
                  <div className="sales-catagory-card">
                    <div className="active-listing-card p-3 rounded fw-bold">
                      <small className="my-1">{item.heading}</small>
                      <p className="mb-0">
                        <i className="fs-4">{item.rate}</i>
                        <i className="fs-4 fw-bold"></i>
                      </p>
                      <button
                        id={`btn${index}`}
                        onClick={() => handleButtonClick(index)}
                        className={`${
                          item.progress > 0 ? "value-incease" : "value-decrease"
                        } border-0  progress-number`}
                      >
                        {item.progress}% month/month
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <p>
                The median list price of homes in Kitchener, ON overall was
                $749,767 in September 2023, compared to $798,183 in August 2023,
                trending down by 6.07% month-over-month.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <Chart02 chartNumber={chartData} />
            </div>
          </div>
        </div>
        {/*single-family-homes-section-4-end*/}
        {/* Summary for Condos-section-5-start*/}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <h2 className="font-bold text-[22px] mb-2">
                  Kitchener City Supply Summary for Condos
                </h2>
                {price.map((item, index) => (
                  <div className="sales-catagory-card">
                    <div className="active-listing-card p-3 rounded fw-bold">
                      <small className="my-1">{item.heading}</small>
                      <p className="mb-0">
                        <i className="fs-4">{item.rate}</i>
                        <i className="fs-4 fw-bold"></i>
                      </p>
                      <button
                        id={`btn${index}`}
                        onClick={() => handleButtonClick(index)}
                        className={`${
                          item.progress > 0 ? "value-incease" : "value-decrease"
                        } border-0  progress-number`}
                      >
                        {item.progress}% month/month
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <p>
                The median list price of homes in Kitchener, ON overall was
                $749,767 in September 2023, compared to $798,183 in August 2023,
                trending down by 6.07% month-over-month.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <Chart03 chartNumber={chartData} />
            </div>
          </div>
        </div>
        {/* Summary for Condos-section-5-end*/}
        {/* Summary for Townhomes-section-6-start*/}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <h2 className="font-bold text-[22px] mb-2">
                  Kitchener City Supply Summary for Townhomes
                </h2>
                {price.map((item, index) => (
                  <div className="sales-catagory-card">
                    <div className="active-listing-card p-3 rounded fw-bold">
                      <small className="my-1">{item.heading}</small>
                      <p className="mb-0">
                        <i className="fs-4">{item.rate}</i>
                        <i className="fs-4 fw-bold"></i>
                      </p>
                      <button
                        id={`btn${index}`}
                        onClick={() => handleButtonClick(index)}
                        className={`${
                          item.progress > 0 ? "value-incease" : "value-decrease"
                        } border-0  progress-number`}
                      >
                        {item.progress}% month/month
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <p>
                The median list price of homes in Kitchener, ON overall was
                $749,767 in September 2023, compared to $798,183 in August 2023,
                trending down by 6.07% month-over-month.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <Chart04 chartNumber={chartData} />
            </div>
          </div>
        </div>
        {/* Summary for Townhomes-section-6-end*/}
      </div>
      {/*container of next all sections*/}

      <div className="font-fly">
        <div className="row">
          {/*Price table*/}
          <div className="">
            {/* Housing Prices section-7-start*/}
            <div className="row my-3 d-flex justify-content-center align-items-center">
              <div className="position-relative col-lg-7 col-md-6 col-sm-12">
                {" "}
                <h2 className="font-bold text-[22px] mb-2">Kitchener Housing Prices</h2>
                <table className="table price-box">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Today</th>
                      <th scope="col">1Month Ago</th>
                      <th scope="col">6Month Ago</th>
                    </tr>
                  </thead>
                  <tbody className="position-relative">
                    <div
                      id="pricing-table"
                      className="position-absolute lock-button w-75 d-flex justify-content-end"
                    >
                      <button
                        className="p-2 btn btn-outline-primary flex items-center bg-salte-200 rounded-pill fw-bold"
                        type="button"
                      >
                        <CiLock className="mr-1" /> Activate Your Account
                      </button>
                    </div>

                    <tr>
                      <th scope="row">Median List Price</th>
                      <td>724,999</td>
                      <td> 743,475</td>
                      <td>792,490</td>
                    </tr>
                    <tr>
                      <th scope="row">Median $/Sqft</th>
                      <td>495</td>
                      <td>486</td>
                      <td>490</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*Table Close */}
              <div className=" d-flex align-items-center justify-content-center col-lg-5 col-md-6 col-sm-12">
                <div className="position-absolute lock-button">
                  <button
                    className="p-2 bg-salte-200 flex items-center  btn btn-light bg-slate-200 text-primary rounded-pill fw-bold"
                    type="button"
                  >
                    <CiLock className="mr-1" /> Activate Your Account
                  </button>
                </div>
                <Chart
                  className="blur-box"
                  options={options}
                  series={series}
                  type="bar"
                  width="100%"
                />
              </div>
            </div>
            {/* Housing Prices section-7-end*/}
          </div>
          {/* City Supply Summary section-8-start*/}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2 className="font-bold text-[22px] mb-2">
                    Kitchener City Supply Summary
                  </h2>

                  {price.map((item, index) => (
                    <div className="sales-catagory-card">
                      <div className="active-listing-card p-3 rounded fw-bold">
                        <small className="my-1">{item.heading}</small>
                        <p className="mb-0">
                          <i className="fs-4">{item.rate}</i>
                          <i className="fs-4 fw-bold"></i>
                        </p>
                        <button
                          id={`btn${index}`}
                          onClick={() => handleButtonClick(index)}
                          className={`${
                            item.progress > 0
                              ? "value-incease"
                              : "value-decrease"
                          } border-0  progress-number`}
                        >
                          {item.progress}% month/month
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="my-4  position-relative d-flex justify-content-center align-items-center">
              <div className="position-absolute lock-button">
              <button
                    className="p-2 bg-salte-200 flex items-center  btn btn-light bg-slate-200 text-primary rounded-pill fw-bold"
                    type="button"
                  >
                    <CiLock className="mr-1" /> Activate Your Account
                  </button>
              </div>
              <div className="row blur-box">
                <div className="col-lg-5 col-md-6 col-sm-12">
                  <p>
                    The median list price of homes in Kitchener, ON overall was
                    $749,767 in September 2023, compared to $798,183 in August
                    2023, trending down by 6.07% month-over-month.
                  </p>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <Chart01 chartNumber={chartData} />
                </div>
              </div>
            </div>
          </div>
          {/* City Supply Summary section-8-end*/}
          {/*  Single Family Home section-9-start*/}

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2 className="font-bold text-[22px] mb-2">
                    Kitchener City Supply Summary for Single Family Home
                  </h2>
                  {price.map((item, index) => (
                    <div className="sales-catagory-card">
                      <div className="active-listing-card p-3 rounded fw-bold">
                        <small className="my-1">{item.heading}</small>
                        <p className="mb-0">
                          <i className="fs-4">{item.rate}</i>
                          <i className="fs-4 fw-bold"></i>
                        </p>
                        <button
                          id={`btn${index}`}
                          onClick={() => handleButtonClick(index)}
                          className={`${
                            item.progress > 0
                              ? "value-incease"
                              : "value-decrease"
                          } border-0  progress-number`}
                        >
                          {item.progress}% month/month
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <p>
                  The median list price of homes in Kitchener, ON overall was
                  $749,767 in September 2023, compared to $798,183 in August
                  2023, trending down by 6.07% month-over-month.
                </p>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <Chart02 chartNumber={chartData} />
              </div>
            </div>
          </div>
          {/*  Single Family Home section-9-end*/}
          {/*  Summary for Condos section-10-start*/}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2 className="font-bold text-[22px] mb-2">
                    Kitchener City Supply Summary for Condos
                  </h2>
                  {price.map((item, index) => (
                    <div className="sales-catagory-card">
                      <div className="active-listing-card p-3 rounded fw-bold">
                        <small className="my-1">{item.heading}</small>
                        <p className="mb-0">
                          <i className="fs-4">{item.rate}</i>
                          <i className="fs-4 fw-bold"></i>
                        </p>
                        <button
                          id={`btn${index}`}
                          onClick={() => handleButtonClick(index)}
                          className={`${
                            item.progress > 0
                              ? "value-incease"
                              : "value-decrease"
                          } border-0  progress-number`}
                        >
                          {item.progress}% month/month
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <p>
                  The median list price of homes in Kitchener, ON overall was
                  $749,767 in September 2023, compared to $798,183 in August
                  2023, trending down by 6.07% month-over-month.
                </p>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <Chart03 chartNumber={chartData} />
              </div>
            </div>
          </div>
          {/*  Summary for Condos section-10-end*/}
          {/*  Summary for Townhomes section-11-start*/}

          <div className="container ">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <h2 className="font-bold text-[22px] mb-2">
                    Kitchener City Supply Summary for Townhomes
                  </h2>
                  {price.map((item, index) => (
                    <div className="sales-catagory-card">
                      <div className="active-listing-card p-3 rounded fw-bold">
                        <small className="my-1">{item.heading}</small>
                        <p className="mb-0">
                          <i className="fs-4">{item.rate}</i>
                          <i className="fs-4 fw-bold"></i>
                        </p>
                        <button
                          id={`btn${index}`}
                          onClick={() => handleButtonClick(index)}
                          className={`${
                            item.progress > 0
                              ? "value-incease"
                              : "value-decrease"
                          } border-0  progress-number`}
                        >
                          {item.progress}% month/month
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>   
            <div className="row my-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p>
                  The median list price of homes in Kitchener, ON overall was
                  $749,767 in September 2023, compared to $798,183 in August
                  2023, trending down by 6.07% month-over-month.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Chart04 chartNumber={chartData} />
              </div>
            </div>
          </div>
          {/*  Summary for Townhomes section-11-end*/}
        </div>
      </div>
      {/*  Compare Nearby Cities section-12-start*/}
      <div className="row my-4">
        <div className="col-12">
          <h1 className="my-3 font-bold text-[22px] mb-2">Compare Nearby Cities</h1>
          <div className="col-12 d-flex">
          
            {" "}
            {/*  drop-down-buttons div */}
            <div class="dropdown col-3 mx-1">
              <button
                class="col-12 btn btn-outline-secondary rounded-pill dropdown-toggle  learest-city-list"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Wellesley
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown col-3 mx-1">
              <button
                class="learest-city-list btn col-12 btn-outline-secondary rounded-pill dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Median List Price
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown  col-3 mx-1">
              <button
                class="learest-city-list col-12 btn btn-outline-secondary rounded-pill dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All Home Types
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p className="my-3">Time Period: 6 Month</p>

          <Chart02 chartNumber={chartData} />
        </div>
      </div>
      {/*  Compare Nearby Cities section-12-end*/}
      {/*  Home Features Trend section-13-start*/}

      <div className="row">
        <h1 className="my-4 font-bold text-[22px] mb-2">Kitchener Home Features Trend</h1>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <table
            style={{ lineHeight: "40px" }}
            id="pricing-table"
            class="table"
          >
            <thead className="font-weight-bold">
              <tr>
                <th scope="col">Price by Features</th>
                <th scope="col">Avg Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Staycation ready</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Basement</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Open floor plan</th>
                <td colspan="2">$599K</td>
              </tr>
              <tr>
                <th scope="row">Staycation ready</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Basement</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Open floor plan</th>
                <td colspan="2">$599K</td>
              </tr>
              <tr>
                <th scope="row">Staycation ready</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Basement</th>
                <td>$650K</td>
              </tr>
              <tr>
                <th scope="row">Open floor plan</th>
                <td colspan="2">$599K</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 p-0">
          <div className="position-relative d-flex justify-content-center align-items-center">
            <table style={{ lineHeight: "40px" }} class="table blur-box">
              <thead className="font-weight-bold">
                <tr>
                  <th scope="col">Sold by Features</th>
                  <th scope="col">Total Sold</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Staycation ready</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Basement</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Open floor plan</th>
                  <td colspan="2">$599K</td>
                </tr>
                <tr>
                  <th scope="row">Staycation ready</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Basement</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Open floor plan</th>
                  <td colspan="2">$599K</td>
                </tr>
                <tr>
                  <th scope="row">Staycation ready</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Basement</th>
                  <td>$650K</td>
                </tr>
                <tr>
                  <th scope="row">Open floor plan</th>
                  <td colspan="2">$599K</td>
                </tr>
              </tbody>
            </table>
            <div className="position-absolute lock-button">
            <button
                    className="p-2 bg-salte-200 flex items-center  btn btn-light bg-slate-200 text-primary rounded-pill fw-bold"
                    type="button"
                  >
                    <CiLock className="mr-1" /> Sign up to unlock
                  </button>
            </div>
          </div>
        </div>
      </div>
      {/*  Home Features Trend section-13-end*/}
      {/* Tips for Moving to Kitchener section-14-start */}

      <div className="row my-5">
        <div className="">
          <div className="col-6">
            <p>
              <b className="font-semibold text-gray-600 mb-4">HOUSEFUL BLOG</b>
            </p>
            <h3>
              <b className="font-bold text-[22px]  mb-2">Tips for Moving to Kitchener</b>
            </h3>
            <p className="font-semibold text-gray-600 mb-2 mt-2">Tailored resources to guide you on your real estate journey</p>
          </div>
        </div>
        <div className="row">
          <div className="conatainer">
            <div className="row">
              <div className="col-lg-6">
                <img
                
                  src={require(`../../img/home-journeys-how-to.png`)}
                  width={"100%"}
                  alt="sd"
                />
              </div>
              <div className="col-lg-6">
                <div className="p-2 border-bottom py-3 ">
                  <h5 className="tips-heading">
                    <strong className="font-semibold  mb-3">Tips for First-Time Homebuyers</strong>
                  </h5>
                  <p className="tips-details">
                    These top five tips will help first-time homebuyers navigate
                    the process with confidence.
                  </p>
                </div>

                <div className="p-2 border-bottom py-3">
                  <h5 className="tips-heading">
                    <strong className="font-semibold  mb-3">Mortgage Tips 101</strong>
                  </h5>
                  <span className="tips-details">
                    Follow these home financing tips to understand the mortgage
                    basics and know how much you need to buy a home.
                  </span>
                </div>

                <div className="p-2 border-bottom py-3 ">
                  <h5 className="tips-heading">
                    <strong className="font-semibold  mb-3">Home Buying Guides & Resources</strong>
                  </h5>
                  <p className="tips-details">
                    Navigate your home buying journey with our comprehensive
                    guides and helpful tips. Get advice on selecting a real
                    estate agent or finding the perfect home.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendStatus;
