import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import ListningModal from './listingModal';

const LatestListingCard = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div className='w-full flex justify-center text-center '>
            <div>
                {open && <ListningModal citiesList={props.data.list}
                />}
            </div>
            <div>
                <div className=' flex justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg hover:shadow-2xl'>
                    <img src={props.data.img} className=' w-[180px] h-[140px] rounded-lg' alt="" />

                </div>
                <p className='text-[16px] font-bold my-3  text-gray-500 '>{props.data.title}</p>

                {/* <div>
                  {props.index === 0 ?
                    homesForSale.map((data, i) => {
                        return (
                            <div>
                                {
                                    i < 3 ?
                                        <p className='text-[16px] cursor-pointer font-semibold  text-colour '>{data}</p>
                                        :
                                        <Collapse in={open}>
                                            <p className='text-[16px] cursor-pointer font-semibold  text-colour '>{data}</p>
                                        </Collapse>
                                }
                            </div>
                        )
                    })
                    : props.index === 1 ?

                        homesForSaleArray.map((data, i) => {
                            return (
                                <div>
                                    {
                                        i < 3 ?
                                            <p className='text-[16px] cursor-pointer font-semibold  text-colour '>{data}</p>
                                            :
                                            <Collapse in={open}>
                                                <p className='text-[16px] cursor-pointer font-semibold  text-colour'>{data}</p>
                                            </Collapse>
                                    }
                                </div>
                            )
                        })
                        : props.index === 2 ?
                            realEstateCategories.map((data, i) => {
                                return (
                                    <div>
                                        {
                                            i < 3 ?
                                                <p className='text-[16px] cursor-pointer font-semibold  text-colour '>{data}</p>
                                                :
                                                <Collapse in={open}>
                                                    <p className='text-[16px] cursor-pointer font-semibold text-colour '>{data}</p>
                                                </Collapse>
                                        }
                                    </div>
                                )
                            })
                            : props.index === 3 ?
                                openHousesLocations.map((data, i) => {
                                    return (
                                        <div>
                                            {
                                                i < 3 ?
                                                    <p className='text-[16px] cursor-pointer font-semibold text-colour '>{data}</p>
                                                    :
                                                    <Collapse in={open}>
                                                        <p className='text-[16px] cursor-pointer font-semibold  text-colour '>{data}</p>
                                                    </Collapse>
                                            }
                                        </div>
                                    )
                                })
                                : ''
                }
                </div> */}

                <div className='my-3'>
                    <a onClick={() => setOpen(!open)} className='text-[16px] cursor-pointer  font-bold text-colour ' >
                        View All
                    </a>
                </div>
            </div>
        </div >
    );
}

export default LatestListingCard;
