import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, ArrowContainer } from 'react-tiny-popover'


const Stories = () => {
    const [sleStories, setSleStories] = useState('');
    let dispatch = useDispatch()

    const data = ['Any', 'Single', 'Multiple']

let slectedStories = useSelector((store) => store.productSection.slectedStories)

useEffect(() => {
    if (Array.isArray(slectedStories)) {
        const result = slectedStories.join('').replace(/\s+/g, ' ');
        setSleStories(result);
    }
}, [slectedStories]);

console.log("chh" , slectedStories);

    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Stories</p>
            <div className='flex items-center'>

                {
                    data.map((item, i) => {
                        return (
                            <div 
                            onClick={()=>{
                                dispatch(
                                    {
                                        type: "STORIES_SLECT",
                                        payload: item
                                    }
                                )
                            } }
                            className={` ${!slectedStories.length > 0 && i === 0 ? 'borderPrimery bgLightGray primery' : ''} ${sleStories === item && 'borderPrimery bgLightGray primery'}  my-1 cursor-pointer flex justify-center  hover:bg-gray-100 primeryH border rounded-md min-w-[80px]   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2`}>
                                <p className=''>{item}</p>

                            </div>
                        )
                      })
                }

            
            </div>

        </div>
    );
}

export default Stories;
