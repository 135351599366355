const newlyAddedHouses = [
    'Ottawa Homes for Sale',
    'London Homes for Sale',
    'Kitchener Homes for Sale',
    'Niagara Falls Homes for Sale',
    'St. Catharines Homes for Sale',
    'Kingston Homes for Sale',
    'Fort Erie Homes for Sale',
    'Waterloo Homes for Sale',
    'Kawartha Lakes Homes for Sale',
    'Guelph Homes for Sale',
    'Cambridge Homes for Sale',
    'Huntsville Homes for Sale',
    'Brantford Homes for Sale',
    'Prince Edward County Homes for Sale',
    'Niagara-on-the-Lake Homes for Sale',
    'Carleton Place Homes for Sale',
    'Innisfil Homes for Sale',
    'The Blue Mountains Homes for Sale',
    'Welland Homes for Sale',
    'Wasaga Beach Homes for Sale',
    'Peterborough Homes for Sale',
    'Collingwood Homes for Sale',
    'Belleville Homes for Sale',
    'Newmarket Homes for Sale',
    'Ajax Homes for Sale',
    'New Tecumseth Homes for Sale',
    'Smiths Falls Homes for Sale',
    'Ramara Homes for Sale'
    ];
    
const popularCities = [
    'Toronto Homes for Sale',
    'Calgary Homes for Sale',
    'Mississauga Homes for Sale',
    'Brampton Homes for Sale',
    'Hamilton Homes for Sale',
    'Edmonton Homes for Sale',
    'Markham Homes for Sale',
    'Vaughan Homes for Sale',
    'Oakville Homes for Sale',
    'Richmond Hill Homes for Sale',
    'Vancouver Homes for Sale',
    'Oshawa Homes for Sale',
    'Barrie Homes for Sale',
    'Surrey Homes for Sale',
    'Burlington Homes for Sale',
    'Milton Homes for Sale',
    'Whitby Homes for Sale',
    'Clarington Homes for Sale',
    'Saskatoon Homes for Sale',
    'Pickering Homes for Sale',
    'Red Deer Homes for Sale',
    'Regina Homes for Sale',
    'Airdrie Homes for Sale',
    'Burnaby Homes for Sale',
    'Richmond Homes for Sale',
    'Caledon Homes for Sale',
    'Langley Homes for Sale',
    'Aurora Homes for Sale',
    'Georgina Homes for Sale',
    'Abbotsford Homes for Sale',
    'Halifax Homes for Sale',
    'Coquitlam Homes for Sale',
    'Whitchurch-Stouffville Homes for Sale',
    'Kelowna Homes for Sale',
    'North Vancouver Homes for Sale',
    'Cochrane Homes for Sale',
    'Maple Ridge Homes for Sale',
    'Rocky View County Homes for Sale',
    'King Homes for Sale',
    'West Vancouver Homes for Sale',
    'Camrose Homes for Sale',
    'Spruce Grove Homes for Sale',
    'Leduc Homes for Sale',
    'Moose Jaw Homes for Sale',
    'Shelburne Homes for Sale',
    'Prince Albert Homes for Sale',
    'Fort Saskatchewan Homes for Sale',
    'Paradise Homes for Sale',
    'Conception Bay South Homes for Sale',
    'Cold Lake Homes for Sale',
    'Swift Current Homes for Sale',
    'Mount Pearl Homes for Sale',
    'Yorkton Homes for Sale',
    'Corner Brook Homes for Sale',
    'Estevan Homes for Sale',
    'North Battleford Homes for Sale',
    'Warman Homes for Sale',
    'Weyburn Homes for Sale',
    'Grand Falls-Windsor Homes for Sale',
    'Gander Homes for Sale',
    'Kentville Homes for Sale',
    'Yarmouth Homes for Sale',
    'Lunenburg Homes for Sale',
    'Bay Roberts Homes for Sale',
    'Truro Homes for Sale',
    'Chester Homes for Sale',
    'Clarenville Homes for Sale',
    'Digby Homes for Sale',
    'Stephenville Homes for Sale',
    'Carbonear Homes for Sale',
    'East Hants Homes for Sale',
    'West Hants Homes for Sale'
];

const nearHouses = [
    'Houses for Sale Near Me',
    'Newest Listings for Sale Near Me',
    'Condos for Sale Near Me',
    'Townhouses for Sale Near Me',
    'Land for Sale Near Me',
    'Mobile Homes for Sale Near Me',
    'Farms for Sale Near Me',
    'Open Houses Near Me'
];
const openHouses = [
    'Vancouver Open Houses',
    'Surrey Open Houses',
    'Ottawa Open Houses',
    'Burnaby Open Houses',
    'Richmond Open Houses',
    'London Open Houses',
    'North Vancouver Open Houses',
    'Coquitlam Open Houses',
    'Victoria Open Houses',
    'Edmonton Open Houses',
    'Langley Open Houses',
    'West Vancouver Open Houses',
    'Chilliwack Open Houses',
    'Windsor Open Houses',
    'Maple Ridge Open Houses',
    'Abbotsford Open Houses',
    'Kitchener Open Houses',
    'Delta Open Houses',
    'Guelph Open Houses',
    'Saskatoon Open Houses',
    'Saanich Open Houses',
    'Port Coquitlam Open Houses',
    'Kingston Open Houses',
    'New Westminster Open Houses',
    'Langford Open Houses',
    'St. Catharines Open Houses',
    'Nanaimo Open Houses',
    'Kamloops Open Houses',
    'Brantford Open Houses',
    'Waterloo Open Houses',
    'Stratford Open Houses',
    'Barrie Open Houses',
    'Oakville Open Houses',
    'Esquimalt Open Houses',
    'Niagara Falls Open Houses',
    'Regina Open Houses',
    'Mission Open Houses',
    'Port Moody Open Houses',
    'Cambridge Open Houses',
    'Woodstock Open Houses'
];

module.exports = {
    newlyAddedHouses,
    popularCities,
    nearHouses,
    openHouses
}