import React, { useState } from 'react'
import { IoIosWalk } from "react-icons/io";
import { RiMotorbikeFill } from "react-icons/ri";
import { IoCarSport } from "react-icons/io5";
import { IoCafeOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { IoSchoolOutline } from "react-icons/io5";
import { IoIosRestaurant } from "react-icons/io";
import { CgTrees } from "react-icons/cg";
// import GoogleApiWrapper from './googleMap';

const PropertyDetailTabThree = () => {

  const [viewMore,setViewMore]=useState(false);
 
  const ResturantsDistance=[
    {
type:{
  name:'Cafes',
icon:<IoCafeOutline/>
},
type2:'Presotea Bridlwood',
distance:'0.30KM',
time:{
  min:'1 min',
  icon:<IoIosWalk/>
}
    },
    {
      type:{
        name:'grocery stores',
      icon:<IoCafeOutline/>
      },
      type2:'louis dessert village',
      distance:'0.30KM',
      time:{
        min:'1 min',
        icon:<IoIosWalk/>
      }
          },
          {
            type:{
              name:'parks',
            icon:<IoCafeOutline/>
            },
            type2:'bridletowne park',
            distance:'0.30KM',
            time:{
              min:'1 min',
              icon:<IoIosWalk/>
            }
                },
                {
                  type:{
                    name:'Cafes',
                  icon:<IoCafeOutline/>
                  },
                  type2:'Presotea Bridlwood',
                  distance:'0.30KM',
                  time:{
                    min:'1 min',
                    icon:<IoIosWalk/>
                  }
                      },
                      {
                        type:{
                          name:'Cafes',
                        icon:<IoCafeOutline/>
                        },
                        type2:'Presotea Bridlwood',
                        distance:'0.30KM',
                        time:{
                          min:'1 min',
                          icon:<IoIosWalk/>
                        }
                            },


  ]

  return (
    <div className='px-2'>
      <p className='text-[32px] font-bold text-[#212529]'>Neighbourhood</p>
      <div className=''>
        <div className='flex justify-between items-center px-2'>
        <div className=' h-[100px]' >
        <p className='text-[24px] font-bold text-[#212529]'>Walkability Scores</p>
          {/* <div className="">
    <svg viewBox="0 0 90 100">
      <circle stroke="#EAEAEA" stroke-width="1" cx="50" cy="5"  r="3" fill="none"/>
      <circle stroke="#28411B" stroke-width="1" cx="50" cy="5" r="3" fill="none" stroke-dasharray="17.59 25.13" stroke-dashoffset="0"/>
    </svg>
</div> */}
 </div>
 <div className='flex items-center'>
 <div className='progress-bar mx-1'> <IoIosWalk className='h-6 w-6 text-colour' /></div> <p>5/10</p>
 </div>
 </div>
 <hr />
 <div className='block md:flex mt-3 py-2'>
  <div className='flex md:w-1/2 justify-evenly py-2 sm:py-0' >
    <div className='text-base font-bold'> Shopping Center
  <div className='sm:flex items-center text-sm block'><div className='progress-bar mx-1'> <IoIosWalk className='h-6 w-6 text-colour'/></div> Very Walkable</div> </div>
  <div className='text-base font-bold '> Shopping Center
  <div className='sm:flex items-center text-sm block'> <div className='progress-bar mx-1'> <IoSchoolOutline className='h-6 w-6 text-colour'/> </div> Car Dependent</div>
  </div> </div>
  <div className='flex md:w-1/2 justify-evenly py-2 sm:py-0'>
  <div className='text-base font-bold'> Parks
  <div className='sm:flex items-center text-sm block'> <div className='progress-bar mx-1'><CgTrees className='h-6 w-6 text-colour'/> </div> Very Walkable</div> </div>
  <div className='text-base font-bold'> Restaurants
  <div className='sm:flex items-center text-sm block'> <div className='progress-bar mx-1 '><IoIosRestaurant className='h-6 w-6 text-colour'/> </div> Car Dependent</div></div> 
  </div>

 </div>
        
      </div>
      <div className='text-[24px] font-bold'>Location</div>

      <div className='h-[300px] border bg-gray-100'>
      {/* <GoogleApiWrapper />       */}
      </div>

      <div className='pb-2'>
              <p className='text-[24px] font-bold'>Nearby Points of Interest</p>
      <div className='block sm:flex text-base mt-3'>
        <p> Select your preferred traspotation</p>
        <div className='flex justify-between'>
        <div className=' py-1 md:px-3 rounded-full flex items-center md:mx-2 btn-bg text-white'> <IoIosWalk className='mx-2'/> Walking</div>
        <div className=' py-1 md:px-3 rounded-full flex items-center md:mx-2 btn-bg text-white'> <RiMotorbikeFill className='mx-2'/> Biking</div>
        <div className=' py-1 md:px-3 rounded-full flex items-center md:mx-2 btn-bg text-white'> <IoCarSport className='mx-2'/> Car</div>
        </div>
        </div>

        {ResturantsDistance?.map((item)=>{
  return <div className='flex justify-between mt-3 border-b md:text-base text-sm'>
    <div className='md:flex justify-evenly md:w-[40%] '>
   <p className='flex items-center '>{item.type.icon}<span className='mx-1'>{item.type.name}</span></p>
  <p className='font-bold text-[#212529]'>{item.type2} </p> </div>
  <div className='md:flex justify-evenly md:w-[40%]'>
  <p>{item.distance}</p>
  <p className='flex items-center'>{item.time.icon} <span className='mx-1'>{item.time.min}</span></p> </div>
  <p className='md:flex items-center text-colour'><span className='mx-1'>View More</span><IoIosArrowDown className='h-5 w-5 md:h-6 md:w-6 cursor-pointer' onClick={()=>setViewMore(!viewMore)}/> </p>
</div>
})}
</div>
<p>Data provided by Precisely.</p>
<p> <span className='text-colour'> ©2013-2022</span> Pitney Bowes Inc . All rights reserved.</p>

    </div>
  )
}

export default PropertyDetailTabThree


