import React, { useRef, useState } from 'react';
import { FloatingLabel } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import PasswordValidator from "password-validator";
import { BiHide, BiShowAlt } from 'react-icons/bi';
import isValidEmail from "email-validator";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const LoginWithEmail = ({ PasswordSet, setPass, validPass }) => {


    const [data, setData] = useState('')
    const [hide, setHide] = useState(false)
    const [forget, setForget] = useState(false)
    const [isValidE, setIsValidE] = useState()
    const [email, setEmail] = useState({email:''})
    const emailRef = useRef(null);
    const forgetFunction = () => {
        setForget(!forget)
    }
    console.log('email', email.email);
    console.log('email validi', isValidE);
    const emailSet = (e) => {
        setEmail({...email , email: e.target.value})
        setIsValidE(isValidEmail.validate(email.email));

    }

    const sliceToken = (e) =>{
        const resetToken = e.substring(e.lastIndexOf('/') + 1);
        console.log('check token' , resetToken);
        Navigate(`/passwordReset/${resetToken}`)
    }

    let Navigate = useNavigate()
    const PasswordReset = async () => {
        if (isValidE) {
            try {
                let response = await axios.post('http://localhost:8000/api/v1/users/forgetPassword' , email);
                console.log('check response' , response.status);
                if (response.status === 200) {
                    console.log('done', response.data.resetTokenUrl);

                    sliceToken(response.data.resetTokenUrl)
                   

               
                    // setSignUp(true)
                    // setStapper(true)
                    // console.log('data', response.data.data.email);
                    // localStorage.setItem('Token', response.data.token);
                    // localStorage.setItem('email', response.data.data.email)
                    // toast.success("Login complete");
                    // dispatch({
                    //     type: 'SIGHN_UP_COMPLETE',

                    // })
                }

            } catch (error) {
                console.log('error', error);
            }
        }
    }

    return (
        <div className='flex justify-center '>
            <div className=''>
                <div className='flex justify-center'>
                    <BsCheckCircle className='text-[25px]' />
                </div>
                <div className='flex justify-center'>
                    <p className='font-semibold text-[18px] my-1'>{forget ? 'Forget your Password?' : 'Enter Password'}</p>
                </div>
                {
                    forget &&
                    <div className='text-center flex justify-center'>
                        <p className='font-semibold my-2 w-[90%] '>Enter your email address and we’ll send you a link to reset your password.</p>
                    </div>
                }

                <div>
                    {
                        !forget ?
                            <div className='flex items-center '>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password"
                                    className="lg:mb-1  md:mb-1 w-full  text-sm"

                                >
                                    <Form.Control type={hide ? "text" : "password"} ref={emailRef} onChange={setPass} id='inputH' className=' ' placeholder="name@example.com" />
                                </FloatingLabel>
                                {
                                    hide ?
                                        <BiHide onClick={() => setHide(false)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />
                                        :
                                        <BiShowAlt onClick={() => setHide(true)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />

                                }
                            </div>
                            :
                            <div className="  mb-1 my-3 flex justify-center  " >

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="lg:mb-1 md:mb-1 w-[80%] text-sm"

                                >
                                    <Form.Control onChange={emailSet} type="email" id='inputH' className=' ' placeholder="name@example.com" />
                                </FloatingLabel>
                            </div>
                    }

                </div>
                <div className='h-5' >
                    {
                        validPass &&
                        <p className='text-red-600 text-sm'> Password must have 6 digit</p>

                    }




                </div>

                <div className='flex justify-end'>
                    {
                        forget ?
                            <div className='flex'>
                                <p>know your password?</p>
                                <p onClick={forgetFunction} className='primery cursor-pointer'>Sigin in</p>

                            </div>
                            :

                            <p onClick={forgetFunction} className='primery cursor-pointer'>Forget Password</p>

                    }
                </div>


                <div className='flex justify-center'>
                    {
                        forget ?
                            <button onClick={PasswordReset} className='border bgPrimery text-white w-[350px] py-2 rounded-full font-semibold text-[18px] mt-2'>
                                Send reset link
                            </button>
                            :
                            <button onClick={() => PasswordSet(emailRef.current.value)} className='border bgPrimery text-white w-[350px] py-2 rounded-full font-semibold text-[18px] mt-2'>
                                Continue with Email
                            </button>
                    }
                    {/* <button onClick={() => PasswordSet(emailRef.current.value)} className='border bgPrimery text-white w-[350px] py-2 rounded-full font-semibold text-[18px] mt-2'>
                        {forget ? 'Send reset link' : 'Continue with Email'}
                    </button> */}
                </div>
            </div>
        </div>
    );
}

export default LoginWithEmail;
