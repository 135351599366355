import React from 'react';
import Form from 'react-bootstrap/Form';
const Tour = () => {
    return (
        <div className='flex justify-between  mt-4 items-center'>
            <p className='font-bold text '>Open House / 3D Tour</p>
            <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
            
            />
        </div>
    );
}

export default Tour;
