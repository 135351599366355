import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindow, OverlayView } from "@react-google-maps/api";
import Location from './data.json'
import NewHomeCard from "../NewListCard/NewListCard";
import jsonData from '../../data/data.json';
import { BiHome } from "react-icons/bi";
// import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";

const MapListing = () => {
  const [infoWindow, setInfoWindow] = useState(null)
  const [icon, setIcon] = useState("http://maps.google.com/mapfiles/ms/icons/green-dot.png");
  const [check, setChecke] = useState('inMap')
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBsytc-M60hbCyVoYJaLYYb6hMfwgmB3Mc",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 13.0609681, lng: 80.2341533
  });
  const [pageRefresh, setPageRefresh] = useState(false)
  const mapRef = React.useRef();

  console.log('return =========' , pageRefresh);

  // useEffect(() => {
  //   setPageRefresh(true)
  // }, [])

  let data = jsonData.results

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;

    if(!isLoaded){
      setPageRefresh(true)
      
    }
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";
 

  console.log('working on data', Location.features[0].geometry.coordinates);




  return (
    <div style={{ marginTop: "0px" }}>
      <GoogleMap
        mapContainerStyle={{
          height: "90vh",
        }}
        // center={selectedLocation}
        center={{ lat: data[0].latitude, lng: data[0].longitude }}
        zoom={12}
        onLoad={onMapLoad}
      >
        {
          data.map((data) => (
            <MarkerF
              // position={selectedLocation}
              position={{
                lat: data.latitude,
                // lat: data.geometry.coordinates[0],
                lng: data.longitude
                // lng: data.geometry.coordinates[1]
              }}
              icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
              // onMouseOver={() => {
              //   setIcon(data)
              //   setInfoWindow(data)
              // }}

              onClick={
                () => {
                  setIcon(data)
                  if (infoWindow === null || infoWindow != data) {
                    setInfoWindow(data)
                  } else {
                    setInfoWindow(null)
                  }

                }
              }
            />
          ))
        }

        {

          pageRefresh &&

          data.map((data) => {

            if (data.price >= 1000000) {
              let inChangeing = (data.price / 1000000).toFixed(1) + 'M';
              data.price = inChangeing
            } else if (data.price >= 1000) {
              let inChangeing = (data.price / 1000).toFixed(1) + 'k'
              console.log(inChangeing);
              data.price = inChangeing;
            }


            return <InfoWindow

              position={{
                lat: data.latitude,

                lng: data.longitude
              }}
              onCloseClick={() => setInfoWindow(null)}
            >

              <div className="cursor-pointer">

                <div onClick={
                  () => {
                    setIcon(data)
                    if (infoWindow === null || infoWindow != data) {
                      setInfoWindow(data)
                    } else {
                      setInfoWindow(null)
                    }

                  }
                } className="bg-white px-2 py-2 z-[10] flex rounded-full ">
                  <BiHome className="mr-1" />
                  <p className=" font-extrabold"> $ {data.price}</p>
                </div>
              </div>
            </InfoWindow>
          })
        }

        {infoWindow &&

          <InfoWindow



            position={{
              lat: infoWindow.latitude + 0.01,

              lng: infoWindow.longitude + 0.01
            }}

            options={{ zIndex: 1000 }}
            onCloseClick={() => setInfoWindow(null)}
          >

            <div className="zIndexClass">
              <NewHomeCard data={infoWindow} />

            </div>
          </InfoWindow>

        }






      </GoogleMap>
    </div>
  );
}

export default MapListing;
