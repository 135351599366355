import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'


const ListningModal = ({ citiesList, ...props }) => {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)

  const array1 = [];
  const array2 = [];


  for (let i = 0; i < citiesList.length; i++) {
    if (i % 2 === 1) {
      array2.push(citiesList[i]);
    } else {
      array1.push(citiesList[i]);
    }
  }

  const allCitiesList = [array1, array2];
  return (

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem]">

                <h1 className='pt-[2rem] text-center font-bold text-[25px] text-colour '>Newly added cities </h1>
                <h1 className='pt-[.5rem] text-center font-bold text-[25px] text-colour '>View All</h1>
                <div className='bg-white  px-4 pb-4 pt-5 sm:p-6 w-[full]  justify-around text-[rgb(110,123,140)] sm:pb-4 flex flex-wrap leading-6 text-[14px]'>
                  {allCitiesList.map((array, index) => (
                    <div>
                      {array.map((item, itemIndex) => (
                        <Link key={itemIndex} to={`/${index + 1}`}>
                          <li className='cursor-pointer hover:text-red-700'>{item}</li>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default ListningModal