import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const PropertyDetailTabOne = () => {
    const [show, setShow] = useState(false)
    const [shift, setShift] = useState(0)
    const [shiftTwo, setShiftTwo] = useState(0)


    const responsive = {
      

        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 200 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }



    };



    return (
        <div className=''>
            <div>
                <p className='text-[32px] font-bold py-4 text-[#212529]'>Highlights</p>
            </div>
            <div className='bg-gray-100 rounded-md border px-[12px]'>
                <p className='text-[24px] text-[#212529] font-bold py-4'>Description</p>

                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Home value ($/Sqft)</p>
                    <p className=' font-bold'>$787/Sqft</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Time on Houseful</p>
                    <p className=' font-bold'>New 5 Hours</p>
                </div>
                  </div>
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Property type</p>
                    <p className=' font-bold'>Condo townhouse</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Style</p>
                    <p className=' font-bold'>Stacked townhse</p>
                </div>
            </div>
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Community</p>
                    <p className=' font-bold'>Malvern</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Garage spaces</p>
                    <p className=' font-bold'>1</p>
                </div>
                </div>

                {/* <div className='flex border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Property type</p>
                    <p className=' w-[25%] font-bold'>Condo townhouse</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Style</p>
                    <p className=' w-[25%] font-bold'>Stacked townhse</p>

                </div> */}

                {/* <div className='flex border-b border-b-gray-300 py-2 text-base'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Community</p>
                    <p className=' w-[25%] font-bold'>Malvern</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Garage spaces</p>
                    <p className=' w-[25%] font-bold'>1</p>

                </div> */}
                <div className='flex border-b border-b-gray-300 py-2 px-4 '>
                    <p className='w-[50%] sm:w-[25%] text-gray-700 text-[14px] sm:text-base font-semibold'>Mortgage payment</p>
                    <p className='btn-bg w-[50%] sm:w-[25%] font-semibold text-[14px] sm:text-base text-white text-center rounded-full m-auto'>Get Pre-qualified Now</p>
                    {/* <p className=' w-[25%] text-gray-700 font-semibold'>Garage spaces</p>
                    <p className=' w-[25%] font-bold'>1</p> */}

                </div>
                <div>
                    <p className='py-3 font-semibold'>
                        Introducing an exquisite stacked townhouse with 2 bedrooms, a den, and 1 bathroom. This expansive residence features a 730 sqft floor plan, coupled with an additional 186 sqft of outdoor living space. Recently refreshed, the townhouse showcases hardwood/laminate flooring, an enhanced kitchen, and 9-foot smooth ceilings. The spacious private patio, equipped with a gas line, is an ideal setting for outdoor gatherings. Enjoy the ambiance of new pot lights and an upgraded bathroom. The property includes 1 underground parking space and a storage locker. Ideally situated, this townhouse offers proximity to a shopping center, restaurants, schools, parks, and various amenities. Secure the opportunity to own this captivating home in a prime location.
                        <br />
                        <br />
                        ZOWN REALTY INC. MLS®#E7330696 TREB MLS® updated 5 hours ago. Houseful checked TREB MLS® for data 8 minutes ago.
                    </p>
                </div>
            </div>


            <div className='bg-gray-100 rounded-md border px-[12px] mt-4 text-[14px] sm:text-base'>
                <p className='text-[24px] text-[#212529] font-bold py-4'>Home Overview</p>

                {/* <div className='flex border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Status</p>
                    <p className=' w-[25%] font-bold'>Active</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Property sub type</p>
                    <p className=' w-[25%] font-bold'>Condo townhouse</p>

                </div> */}
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Status</p>
                    <p className=' font-bold'>Active</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Property sub type</p>
                    <p className=' font-bold'>Condo townhouse</p>
                </div>
                </div>
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Mls® #</p>
                    <p className=' font-bold'>E7330696</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Approx square feet (range)</p>
                    <p className=' font-bold'>700 - 799</p>
                </div>
                </div>

                {/* <div className='flex border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Mls® #</p>
                    <p className=' w-[25%] font-bold'>E7330696</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Approx square feet (range)</p>
                    <p className=' w-[25%] font-bold'>700 - 799</p>

                </div> */}

                {/* <div className='flex border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Storage unit (locker)</p>
                    <p className=' w-[25%] font-bold'>Owned</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Basement information</p>
                    <p className=' w-[25%] font-bold'>None</p>

                </div> */}
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Storage unit (locker)</p>
                    <p className=' font-bold'>Owned</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Basement information</p>
                    <p className=' font-bold'>None</p>
                </div>
                </div>
                {/* <div className='flex border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Taxes</p>
                    <p className=' w-[25%] font-bold'>$2,118</p>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Tax year</p>
                    <p className=' w-[25%] font-bold'>2023</p>

                </div> */}
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Taxes</p>
                    <p className=' font-bold'>$2,118</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Tax year</p>
                    <p className=' font-bold'>2023</p>
                </div>
                </div>
                {/* <div className='flex  border-b border-b-gray-300 py-2'>
                    <p className=' w-[25%] text-gray-700 font-semibold'>Maintenance fee</p>
                    <p className=' w-[25%] font-bold'>$2,118</p>
                    //   <p className=' w-[25%] text-gray-700 font-semibold'>Tax year</p>
                    // <p className=' w-[25%] font-bold'>2023</p>

                </div> */}
                <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Maintenance fee</p>
                    <p className=' font-bold'>$2,118</p>
                    </div>
                   
                </div>

                <p className={`text-colour font-semibold py-2 cursor-pointer ${show == false ? 'block' : 'hidden'}`} onClick={() => setShow(true)}>See More</p>
                <div className={`${show == false ? 'hidden' : 'block'}`}>
                    <p className=' font-bold py-2'>Interior</p>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'># total bathrooms</p>
                        <p className=' w-[25%] font-bold'>1</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'># full baths</p>
                        <p className=' w-[25%] font-bold'>1</p>

                    </div> */}

                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '># total bathrooms</p>
                    <p className=' font-bold'>1</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'># full baths</p>
                    <p className=' font-bold'>1</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'># of above grade bedrooms</p>
                        <p className=' w-[25%] font-bold'>3</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'># of below grade bedrooms</p>
                        <p className=' w-[25%] font-bold'>1</p>

                    </div> */}

                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '># of above grade bedrooms</p>
                    <p className=' font-bold'>3</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'># of below grade bedrooms</p>
                    <p className=' font-bold'>1</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Family room available</p>
                        <p className=' w-[25%] font-bold'>No</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Laundry information</p>
                        <p className=' w-[25%] font-bold'>En suite laundry</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Family room available</p>
                    <p className=' font-bold'>No</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Laundry information</p>
                    <p className=' font-bold'>En suite laundry</p>
                </div>
                </div>
                    <p className=' font-bold py-2'>Exterior</p>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Construction materials</p>
                        <p className=' w-[25%] font-bold'>Brick, concrete</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'># garage spaces</p>
                        <p className=' w-[25%] font-bold'>1</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Construction materials</p>
                    <p className=' font-bold'>Brick, concrete</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'># garage spaces</p>
                    <p className=' font-bold'>1</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Parking desc</p>
                        <p className=' w-[25%] font-bold'>Undergrnd, parking included, exclusive</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'># parking spaces</p>
                        <p className=' w-[25%] font-bold'>1</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Parking desc</p>
                    <p className=' font-bold'>Undergrnd, parking included, exclusive</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'># parking spaces</p>
                    <p className=' font-bold'>1</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Parking desc</p>
                        <p className=' w-[25%] font-bold'>Undergrnd, parking included, exclusive</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'># parking spaces</p>
                        <p className=' w-[25%] font-bold'>1</p>

                    </div> */}
                   

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Garage features</p>
                        <p className=' w-[25%] font-bold'>Underground</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Has basement (y/n)</p>
                        <p className=' w-[25%] font-bold'>Yes</p>

                    </div> */}

                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Garage features</p>
                    <p className=' font-bold'>Underground</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Has basement (y/n)</p>
                    <p className=' font-bold'>Yes</p>
                </div>
                </div>
                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Has garage (y/n)</p>
                        <p className=' w-[25%] font-bold'>Yes</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Exterior features</p>
                        <p className=' w-[25%] font-bold'>Terr</p>

                    </div> */}

                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Has garage (y/n)</p>
                    <p className=' font-bold'>Yes</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Exterior features</p>
                    <p className=' font-bold'>Terr</p>
                </div>
                </div>

                    <p className=' font-bold py-2'>Amenities / Utilities</p>
                        {/* <div className='flex border-b border-b-gray-300 py-2'>
                            <p className=' w-[25%] text-gray-700 font-semibold'>Cooling</p>
                            <p className=' w-[25%] font-bold'>Central air</p>
                            <p className=' w-[25%] text-gray-700 font-semibold'>Heat source</p>
                            <p className=' w-[25%] font-bold'>Gas</p>

                        </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Cooling</p>
                    <p className=' font-bold'>Central air</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Heat source</p>
                    <p className=' font-bold'>Gas</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Heat type</p>
                        <p className=' w-[25%] font-bold'>Forced air</p>
                        // <p className=' w-[25%] text-gray-700 font-semibold'>Heat source</p>
                        // <p className=' w-[25%] font-bold'>Gas</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Heat type</p>
                    <p className=' font-bold'>Forced air</p>
                    </div>
                    
                </div>
                    <p className=' font-bold py-2'>Location</p>
                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Area</p>
                        <p className=' w-[25%] font-bold'>Toronto</p>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Community</p>
                        <p className=' w-[25%] font-bold'>Malvern</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Area</p>
                    <p className=' font-bold'>Toronto</p>
                    </div>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold'>Community</p>
                    <p className=' font-bold'>Malvern</p>
                </div>
                </div>

                    {/* <div className='flex border-b border-b-gray-300 py-2'>
                        <p className=' w-[25%] text-gray-700 font-semibold'>Directions</p>
                        <p className=' w-[25%] font-bold'>Neilson and mclevin</p>
                        // <p className=' w-[25%] text-gray-700 font-semibold'>Community</p>
                        // <p className=' w-[25%] font-bold'>Malvern</p>

                    </div> */}
                    <div className='block  sm:flex border-b border-b-gray-300 py-2 text-[14px] sm:text-[16px]'>
                    <div className='sm:w-1/2 flex justify-start sm:px-4'>
                    <p className='w-1/2 text-gray-700 font-semibold '>Directions</p>
                    <p className=' font-bold'>Neilson and mclevin</p>
                    </div>
                   
                </div>
                    <p className={`primery font-semibold cursor-pointer py-2 ${show == false ? 'hidden' : 'block'}`} onClick={() => setShow(false)}>See Less</p>
                </div>
            </div>
            <div className='w-full bg-gray-100 border-2 rounded-md mt-4 block md:flex'>
                <div>
                    <img className='rounded-l-md h-full m-auto' src="https://static.ojohosts.ca/p/1001/E7330696_0_nzfunb_p.jpeg" alt="" />
                </div>
                <div className='py-4 sm:w-[70%] md:w-2/4 w-[90%]  m-auto'>
                    <div className='text-center'>
                        <p className='text-[24px] font-bold mb-3 text-[#212529]'>Schedule a Viewing</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <div onClick={() => setShift(0)} className={` cursor-pointer py-2 text-colour font-bold w-[130px] text-center shadow-md rounded-l-md  ${shift == 0 ? 'btn-bg text-white ' : 'bg-white'}  `}>
                            In Person
                        </div>
                        <div onClick={() => setShift(1)} className={` cursor-pointer py-2 px-3 ml-[2px] text-colour font-bold w-[130px] text-center shadow-md rounded-r-md ${shift == 1 ? 'btn-bg text-white ' : 'bg-white'}`}>
                            Virtual Tour
                        </div>
                    </div>

                    <div>
                        <Carousel className=' mx-3 mt-3   ' responsive={responsive}> 
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>
                            <div className='bg-white text-center m-2 py-2 rounded-md shadow-md'>
                                <p className='text-[12px] font-semibold text-colour '>FRI</p>
                                <p className='font-bold text-[24px] text-colour'>01</p>
                                <p className='text-[12px] font-semibold text-colour '>DEC</p>
                            </div>

                        </Carousel>
                    </div>

                    <div className='flex justify-center'>
                        <div onClick={()=>setShiftTwo(0)} className={` text-center cursor-pointer m-2 text-colour py-2 rounded-md shadow-md w-[110px] ${shiftTwo == 0 ? 'btn-bg text-white' : 'bg-white'} `}>

                            <p className='font-bold text-[16px] '>Morning</p>
                            <p className='text-[12px] font-semibold  '>8pm-12pm</p>
                        </div>
                        <div onClick={()=>setShiftTwo(1)} className={` text-center cursor-pointer m-2 text-colour py-2 rounded-md shadow-md w-[110px] ${shiftTwo == 1 ? 'btn-bg text-white' : 'bg-white'} `}>

                            <p className='font-bold text-[16px] '>Morning</p>
                            <p className='text-[12px] font-semibold  '>8pm-12pm</p>
                        </div>
                        <div onClick={()=>setShiftTwo(2)} className={` text-center cursor-pointer m-2 text-colour py-2 rounded-md shadow-md w-[110px] ${shiftTwo == 2 ? 'btn-bg text-white' : 'bg-white'} `}>

                            <p className='font-bold text-[16px] '>Morning</p>
                            <p className='text-[12px] font-semibold  '>8pm-12pm</p>
                        </div>
                    </div>
                    <div className='text-center'>
                        <p className='text-[10px] font-bold'>No obligation or purchase necessary, cancel at any time</p>
                    </div>

                    <div className='btn-bg mt-3 cursor-pointer w-[90%] relative m-auto text-white font-bold text-center py-2 rounded-full'>
                        Request Tour
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyDetailTabOne;
