import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
const SoldPopup = () => {

    let dispatch = useDispatch();
    let Sold = ['Sold < 1yr', "Sold < 1 mo", "Sold < 3 mo", "Sold < 6 mo",]
    return (
        <div className='border bg-white border-gray-400 p-[10px] rounded-2xl'>
            {
                Sold.map((data , i ) => {
                    return <div onClick={() => {

                        dispatch({
                            type: "SOLD_SLECT",
                            payload:   data
                        })

                    }} className='my-1 cursor-pointer flex py-2 hover:bg-gray-100 primeryH'>
                        <p className='font-bold min-w-[80px]'>{data}</p>
                    </div>
                })
            }

        </div>
    );
}

export default SoldPopup;
