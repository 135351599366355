import React, { useState } from 'react';
import { BiSolidCheckCircle } from 'react-icons/bi';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Modal.css'
import { Toast } from 'bootstrap';
const Stapper = ({ closeModall , stepOne , firstClick ,secClick , thrClick , steperDataSubmit}) => {

    const [first, setFirst] = useState([{ label: "Looking for my first home" }, { label: "Homeowner looking to move" }, { label: "Buying a second property" }, { label: "Real estate investor" }, { label: "Just browseing" }])
    const [sec, setSec] = useState([{ label: "Yes" }, { label: "No" }, { label: "Paying in cash" }])
    const [thr, setThr] = useState([{ label: "Yes" }, { label: "No" }])
    // const [firstClick, setFirstClick] = useState()
    // const [secClick, setSecClick] = useState()
    // const [thrClick, setThrClick] = useState()
    let [slide, setSlide] = useState(0)
    console.log("slide", slide);
    // let slide = 0
    const now = 60;
    return (
        <div className='flex justify-center'>
            <div className='lg:w-[55%] md:w-[55%] sm:w-[70%] w-[75%] '>
                <div className='flex justify-center my-3'>
                    <img className='   w-[50px] h-[50px]' src={require('../../img/user.png')} alt="" />
                </div>
                <div className='flex justify-center text-center '>
                    <p className='text-[24px] textt font-bold'>{slide === 0 ? "Let’s set up your profile!" : slide === 1 ? "Are you pre-approved for a mortgage?" : slide === 2 ? "Are you already committed to a real estate agent?" : ""}</p>
                </div>
                {
                    slide === 0 ?
                        <div className='flex justify-center my-3'>
                            <p className='text-[14px] w-full text-center font-semibold text-gray-700'>
                                Complete your profile to customize your experience.
                            </p>
                        </div>
                        : ""
                }

                {slide === 0 &&
                    <div>
                        {
                            first.map((data, i) => {
                                return (
                                    <div  className='lg:h-[55px] md:h-[55px] sm:h-[55px]  cursor-pointer' onClick={()=>stepOne(data.label , i , 'stepOne')}>
                                        <div  className={` ${firstClick === i ? "border-2 border-blue-600" : "Button_border"} flex items-center rounded-full py-[10px] hover:border-2 hover:border-blue-600  px-3 my-2 `}>
                                            <span className={`text-[16px] w-full  font-semibold ${firstClick === i ? "text-blue-600" : "text-gray-700"}   `}>{data.label}</span>
                                            <div class="round">
                                                {/* <input type="checkbox"  id="createaccount" />
                         <label for="checkbox"></label> */}
                                                {
                                                    firstClick === i ?
                                                        <BiSolidCheckCircle className=' primery text-[30px]' />
                                                        :
                                                        <div className='Button_border w-[30px] h-[30px] rounded-full'></div>


                                                }

                                            </div>
                                            {/* <img className='lg:flex md:flex sm:hidden hidden w-[20px] h-[20px]' src={require('../../img/apple.png')} alt="" /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }





                {slide === 1 &&
                    <div>
                        {
                            sec.map((data, i) => {
                                return (
                                    <div className='h-[55px] cursor-pointer' onClick={() =>  stepOne(data.label , i , 'stepSec')} >
                                        <div className={` ${secClick === i ? "border-2 border-blue-600" : "Button_border"} flex rounded-full py-[10px] hover:border-2 hover:border-blue-600  px-3 my-2 `}>
                                            <span className={`text-[16px] w-full  font-semibold ${secClick === i ? "text-blue-600" : "text-gray-700"}   `}>{data.label}</span>
                                            <div class="round">
                                                {/* <input type="checkbox"  id="createaccount" />
                         <label for="checkbox"></label> */}
                                                {
                                                    secClick === i ?
                                                        <BiSolidCheckCircle className='text-blue-600 text-[30px]' />
                                                        :
                                                        <div className='Button_border w-[30px] h-[30px] rounded-full'></div>


                                                }

                                            </div>
                                            {/* <img className='lg:flex md:flex sm:hidden hidden w-[20px] h-[20px]' src={require('../../img/apple.png')} alt="" /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }


                {slide === 2 &&
                    <div>
                        {
                            thr.map((data, i) => {
                                return (
                                    <div className='h-[55px] cursor-pointer'  onClick={() =>  stepOne(data.label , i , 'stepthr')}>
                                        <div className={` ${thrClick === i ? "border-2 border-blue-600" : "Button_border"} flex rounded-full py-[10px] hover:border-2 hover:border-blue-600  px-3 my-2 `}>
                                            <span className={`text-[16px] w-full  font-semibold ${thrClick === i ? "text-blue-600" : "text-gray-700"}   `}>{data.label}</span>
                                            <div class="round">
                                                {/* <input type="checkbox"  id="createaccount" />
                         <label for="checkbox"></label> */}
                                                {
                                                    thrClick === i ?
                                                        <BiSolidCheckCircle className='text-blue-600 text-[30px]' />
                                                        :
                                                        <div className='Button_border w-[30px] h-[30px] rounded-full'></div>


                                                }

                                            </div>
                                            {/* <img className='lg:flex md:flex sm:hidden hidden w-[20px] h-[20px]' src={require('../../img/apple.png')} alt="" /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }




                <div className='flex justify-between mt-3 mb-5 items-center'>

                    <div className='w-{54px] h-[54px]  '>

                        <div onClick={() => {
                            let update = slide - 1
                            setSlide(update)

                        }} className={`Button_border ${slide === 0 ? "hidden" : "block"} w-fit cursor-pointer flex rounded-full py-3 px-3 `}>
                            <img className='w-[20px] h-[20px]' src={require('../../img/prev.png')} alt="" />
                        </div>
                        <div className={` ${slide > 0 ? "hidden" : "block"} w-fit flex rounded-full py-3 px-3 `}>
                            <img className='w-[20px] h-[20px]' src={require('../../img/white.jpg')} alt="" />
                        </div>
                    </div>




                    <div className=' w-[30%]'>
                        <ProgressBar now={`${slide === 0 ? 33 : slide === 1 ? 66 : slide === 2 ? 100 : 0}`} className='h-[2px]   relative m-auto' />
                    </div>
                    {
                        slide != 2 ?
                            <div onClick={() => {
                                let update = slide + 1
                                setSlide(update)

                            }} className='Button_border w-fit flex rounded-full py-3 px-3 my-2  cursor-pointer '>
                                <img className='   w-[20px] h-[20px]' src={require('../../img/next.png')} alt="" />
                            </div>
                            :
                            <div onClick={steperDataSubmit} className='Button_border w-fit flex rounded-full py-3 px-3 my-2  cursor-pointer' >
                                <img className='  w-[20px] h-[20px]' src={require('../../img/check.png')} alt="" />
                            </div>
                    }

                </div>

            </div>
        </div>
    );
}

export default Stapper;
