import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewHomeCard from '../NewListCard/NewListCard';
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import jsonData from '../../data/data.json';
import './style.css'

const NewHomeList = () => {
    const limitedData = jsonData.results.slice(0, 9);
    // const limitedData = Array.isArray(jsonData.results) ? jsonData.items.slice(0, 5) : [];
console.log('json data' , limitedData);

    let Navigate = useNavigate()

    const [card, setCard] = useState([1, 2, 3, 4, 5, 6, 7]);

   

    const responsive = {
        // superLargeDesktop: {
        //     // the naming can be any, depends on you.
        //     breakpoint: { max: 4000, min: 3000 },
        //     items: 5
        // },
        // desktop: {
        //     breakpoint: { max: 3000, min: 1300 },
        //     items: 4
        // },
        // tablet: {
        //     breakpoint: { max: 1000, min: 400 },
        //     items: 4
        // },

        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }



    };



    return (
        <div className='w-full newestHome '>
            <div className='mt-[30px] mb-3  '>
                <div className='w-full text-center'>
                    <p className='text-xs font-bold text-gray-500 '>FRESH LISTINGS</p>
                </div>
                <div className='lg:flex md:flex sm:flex block text-center w-fit relative m-auto '>
                    <p className='text-2xl font-bold'>Newest Homes for Sale in  </p>
                    <p className='text-2xl font-bold  text-colour sm:ml-1'>  Toronto </p>

                </div>
            </div>
            <div>
                <Carousel className='max-w-[1400px] h-[330px] relative m-auto ' responsive={responsive}>
                    {
                        limitedData.map((data , i) => {
                            return (<div className='px-2  transition ease-in-out delay-150   hover:-translate-y-1 hover:scale-110  duration-300 '>
                                <NewHomeCard data={data} />
                            </div>
                            )
                        })
                    }

                </Carousel>
            </div>
            <div className=' w-full flex justify-center pt-4'>
                {/* <button onClick={()=> Navigate('/list')} className=' bgPrimery text-white font-bold px-[30px] py-[10px] rounded-full'>View All New Homes</button> */}
                <button onClick={() => Navigate('/list')}  className='btn-bg text-white font-bold px-[30px] py-[10px] rounded-lg'>View All New Homes</button>

            </div>
        </div>
    );
}

export default NewHomeList;
