import React, { useState } from 'react'
import { BiAbacus, BiCurrentLocation, BiFilter, BiHomeAlt2 } from 'react-icons/bi'

import SortTooltip from '../sortTooltip/SortTooltip';
import HomeTypePopup from '../homeType/HomeType';
import FeatureTooltip from '../featureTooltip/FeatureTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";
import { useEffect } from 'react';
import { useRef } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import FeaturePopup from '../featureTooltip/featurePopup';
import BedsPopup from '../bedsPopup/BedsPopup';
import BathPopup from '../bathsPopup/BathPopup';
import SoldPopup from '../soldPopup/SoldPopup';
import FilterDrawar from '../drawar/FilterDrawar';
import PriceFilterPopup from '../priceFilterPopup/PriceFilterPopup';
const MapPageFilterNav = ({ setHiddenMAp }) => {
    const [list, setList] = useState([{ name: "Map View", icon: "Map" }, { name: "Filter", icon: "filter" }, { name: "Sort", icon: "sort", iconD: "DropDown" }, { name: "Price", iconD: "DropDown" }, { name: "Features", iconD: "DropDown" }, { name: "Beds", iconD: "DropDown" }, { name: "Baths", iconD: "DropDown" }, { name: "Home Type", iconD: "DropDown" }, { name: "New" }, { name: "Price Redued" }, { name: "3D Tour" }, { name: "Sold", iconD: "DropDown" }])
    const [open, setOpen] = useState(55)
    const [show, setShow] = useState(false);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    console.log(isPopoverOpen);
    const clickMeButtonRef = useRef();



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let dispatch = useDispatch();
    let selectedFeatures = useSelector((store) => store.productSection.selectedFeatures)
    let SortAdd = useSelector((store) => store.productSection.sortAdd)
    let bedAdd = useSelector((store) => store.productSection.bedAdd)
    let bathAdd = useSelector((store) => store.productSection.bathAdd)
    let soldAdd = useSelector((store) => store.productSection.soldAdd)
    let selectedPropertyType = useSelector((store) => store.productSection.selectedPropertyType)


    return (
        <div className=' overflow-x-auto '>
            <div className='flex pl-1 shadow-md py-2 min-w-fit '>
                {
                    list.map((data, i) => {
                        return (
                            <div className='cursor-pointer'>

                                <Popover
                                    isOpen={open === i}
                                    positions={['bottom']}
                                    padding={10}
                                    onClickOutside={() => setIsPopoverOpen(false)}
                                    ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                                    content={({ position, childRect, popoverRect }) => (
                                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                            position={position}
                                            childRect={childRect}
                                            popoverRect={popoverRect}
                                            arrowColor={'#D3D3D3'}
                                            arrowSize={10}
                                            arrowStyle={{ opacity: 0.7 }}
                                            className='popover-arrow-container'
                                            arrowClassName='popover-arrow'
                                        >
                                            {
                                                i === 2 ?
                                                    <SortTooltip />
                                                    : i === 4 ?
                                                        <FeaturePopup />
                                                        : i === 5 ?
                                                            <BedsPopup />
                                                            : i === 6 ?
                                                                <BathPopup />
                                                                : i === 11 ?
                                                                    <SoldPopup />
                                                                    : i === 7 ?
                                                                        <HomeTypePopup />
                                                                        : i === 3 ?
                                                                            <PriceFilterPopup />
                                                                            :
                                                                            <FeaturePopup />

                                            }



                                        </ArrowContainer>
                                    )}
                                >
                                    <div onClick={() => {
                                        if (data.iconD === "DropDown" && open != i) {
                                            setOpen(i)
                                        } else {
                                            setOpen(55)
                                        }
                                        setIsPopoverOpen(!isPopoverOpen)

                                        if (i === 1) {
                                            handleShow()
                                        }
                                        if (i === 0) {
                                            setHiddenMAp()
                                        }
                                    }} className={` ${data.name === "Features" && selectedFeatures.length > 0 || i === 5 && bedAdd.length > 0 || i === 6 && bathAdd.length > 0 || i === 11 && soldAdd.length > 0 || i === 7 && selectedPropertyType.length > 0 || i === 2 && SortAdd.length > 0 ? ' bgLightGray borderPrimery rounded-full  flex font-semibold items-center mx-[2px] py-[3px] px-3 primery' : "border rounded-full  flex font-semibold items-center  textBlack border-gray-400 mx-[2px] py-[3px] px-[10px]"} ${i === 10 || i === 9 || i === 8 || i === 7 ? 'xl:flex lg:hidden md:hidden sm:hidden hidden' : ''} ${i === 0 || i === 2 ? 'xl:flex lg:flex md:flex sm:hidden hidden' : ''}`}>
                                        {
                                            data.icon === "Map" ?
                                                <BiCurrentLocation className='mr-2' />
                                                :
                                                data.icon === "filter" ?

                                                    <BiAbacus className='mr-2' />
                                                    :
                                                    data.icon === "sort" ?
                                                        <BiFilter className='mr-2' /> : ""
                                        }

                                        {
                                            i === 2 ?
                                                <p>{SortAdd != '' ? SortAdd : data.name}</p>
                                                : i === 5 ?
                                                    <p>{bedAdd != '' && bedAdd}{data.name}</p>
                                                    : i === 6 ?
                                                        <p>{bathAdd != '' && bathAdd}{data.name}</p>
                                                        : i === 11 ?
                                                            <p>{soldAdd != '' ? soldAdd : data.name}</p>
                                                            : i === 7 ?
                                                                <div className='flex'>
                                                                    {selectedPropertyType.length > 0 ?
                                                                        selectedPropertyType.map((data) => {
                                                                            return (
                                                                                <div
                                                                                    className={` cursor-pointer    w-fit   flex font-semibold items-center  primery `}>
                                                                                    <BiHomeAlt2 className='' />
                                                                                    <p className='  '>{data},</p>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <p>{data.name}</p>
                                                                    }

                                                                </div>
                                                                :
                                                                <p>{data.name}</p>
                                        }


                                        {
                                            data.name === "Features" && selectedFeatures.length > 0 ?
                                                <span className='flex items-center'>
                                                    <p className='bgPrimery text-white text-center rounded-full w-[22px] h-[22px] ml-2 text-sm '>{selectedFeatures.length}</p>

                                                </span>
                                                : ''
                                        }
                                        {
                                            data.name === "Filter" ?
                                                <div>
                                                    {
                                                        selectedFeatures.length > 0 || SortAdd.length > 0 ||  bedAdd.length > 0 || bathAdd.length > 0 || soldAdd.length > 0 || selectedPropertyType.length > 0  ?
                                                            <span className='flex bgPrimery w-[4px] h-[4px] rounded-full -translate-x- -translate-y-2 '>
                                                                {/* <p className='bgPrimery text-white text-center rounded-full w-[4px] h-[4px] ml-2 text-sm '>0</p> */}

                                                            </span>
                                                            : ''
                                                    }
                                                </div>

                                                : ''
                                        }

                                        {
                                            data.iconD === "DropDown" &&

                                            <div className='ml-1'>

                                                {
                                                    data.name === "Features" && selectedFeatures.length > 0 ?
                                                        <IoIosClose onClick={() => {
                                                            dispatch({
                                                                type: "D_SLECTED_FEATURE",
                                                            })

                                                            console.log("clicked");
                                                        }} className='text-[20px] ml-1 primery' />
                                                        : i === 5 && bedAdd.length > 0 ?
                                                            <IoIosClose onClick={() => {
                                                                dispatch({
                                                                    type: "BEDS_SLECT",
                                                                    payload: ''
                                                                })

                                                                console.log("clicked");
                                                            }} className='text-[20px] ml-1 primery' />
                                                            : i === 6 && bathAdd.length > 0 ?
                                                                <IoIosClose onClick={() => {
                                                                    dispatch({
                                                                        type: "BATH_SLECT",
                                                                        payload: ''
                                                                    })

                                                                    console.log("clicked");
                                                                }} className='text-[20px] ml-1 primery' />
                                                                : i === 11 && soldAdd.length > 0 ?
                                                                    <IoIosClose onClick={() => {
                                                                        dispatch({
                                                                            type: "SOLD_SLECT",
                                                                            payload: ''
                                                                        })

                                                                        console.log("clicked");
                                                                    }} className='text-[20px] ml-1 primery' />
                                                                    : i === 2 && SortAdd.length > 0 ?
                                                                        <IoIosClose onClick={() => {
                                                                            dispatch({
                                                                                type: "SORT_SLECT",
                                                                                payload: ''
                                                                            })

                                                                            console.log("clicked");
                                                                        }} className='text-[20px] ml-1 primery' />
                                                                        :
                                                                        <div>
                                                                            {
                                                                                open === i ?
                                                                                    <img className='  ' src={require('../../img/dropUp.png')} width={20} height={20} alt="" />

                                                                                    :

                                                                                    <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
                                                                            }
                                                                        </div>
                                                }
                                            </div>

                                        }

                                    </div>
                                </Popover>


                            </div>
                        )
                    })
                }
            </div>
            <FilterDrawar show={show} handleClose={handleClose} handleShow={handleShow} />
        </div>
    )
}

export default MapPageFilterNav
