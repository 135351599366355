import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const PropertyDetailCrouser = () => {
    const responsive = {
        // superLargeDesktop: {
        //     // the naming can be any, depends on you.
        //     breakpoint: { max: 4000, min: 3000 },
        //     items: 5
        // },
        // desktop: {
        //     breakpoint: { max: 3000, min: 1300 },
        //     items: 4
        // },
        // tablet: {
        //     breakpoint: { max: 1000, min: 400 },
        //     items: 4
        // },

        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }



    };

    return (
        <div className='w-full'>
            <Carousel className='' responsive={responsive}>
                <div className='m-[1px]'><img className='w-full ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" /></div>
                <div>
                    <div className='flex my-[1px]'>
                    <img className='w-2/4' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img className='w-2/4' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    </div >
                  <div className='flex my-[1px]'>
                    <img className='w-2/4' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img className='w-2/4' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                  </div>
                </div>
                <div>
                    <div className='flex my-[1px]'>
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    </div >
                  <div className='flex my-[1px]'>
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                  </div>
                </div>
                <div>
                    <div className='flex my-[1px]'>
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    </div >
                  <div className='flex my-[1px]'>
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                    <img  className='w-2/4 ' src="https://photos.zillowstatic.com/fp/12b9be9bbbac1cbd4a332a4decce091d-p_e.jpg" alt="" />
                  </div>
                </div>

            </Carousel>
        </div>
    );
}

export default PropertyDetailCrouser;
