import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "r-range-slider";
const PriceFilterPopup = (props) => {

    const [data, setData] = useState({ value: 0, value2: 10020000 });
    const [cahngeData, setChangeData] = useState({ value: 0, Value2: 10020000 })
    const inputRef = useRef(null)
    const inputRefMin = useRef(null)



    const handleFocusMin = () => {
        if (inputRefMin.current && inputRefMin.current.value.endsWith('k')) {

            const numericValue = parseFloat(inputRefMin.current.value.replace('k', '')) * 1000;
            inputRefMin.current.value = numericValue;
        } else if (inputRefMin.current && inputRefMin.current.value.endsWith('M')) {
            const numericValue = parseFloat(inputRefMin.current.value.replace('M', '')) * 1000000;
            inputRefMin.current.value = numericValue;
        }
    }


    const handleChangeMin = (e) => {
        if (inputRefMin.current.value >= 1000000) {
            let inChangeing = (inputRefMin.current.value / 1000000).toFixed(1) + 'M';
            inputRefMin.current.value = inChangeing
        } else if (inputRefMin.current.value >= 1000) {
            let inChangeing = (inputRefMin.current.value / 1000).toFixed(1) + 'k'
          
            inputRefMin.current.value = inChangeing;
        }
    }

    const handleFocusMax = () => {
        if (inputRef.current && inputRef.current.value.endsWith('k')) {

            const numericValue = parseFloat(inputRef.current.value.replace('k', '')) * 1000;
            inputRef.current.value = numericValue;
        } else if (inputRef.current && inputRef.current.value.endsWith('M')) {
            const numericValue = parseFloat(inputRef.current.value.replace('M', '')) * 1000000;
            inputRef.current.value = numericValue;
        }
    }



    const handleChangeMax = () => {

        if (inputRef.current.value >= 1000000) {
            let inChangeing = (inputRef.current.value / 1000000).toFixed(1) + 'M';
            inputRef.current.value = inChangeing
        } else if (inputRef.current.value >= 1000) {
            let inChangeing = (inputRef.current.value / 1000).toFixed(1) + 'k'
    
            inputRef.current.value = inChangeing;
        }

        // if(e.target.value > data.value && e.target.value.length > 2){
        //     setData({ ...data, value2: Number(e.target.value) })


        // }
    }





const ChangeSlider =(points) =>{
    setData({value:points[0] , value2:points[1]})

    inputRefMin.current.value = points[0]
    
    if (points[0] >= 1000000) {
        let inChangeing = (points[0] / 1000000).toFixed(1) + 'M';
        inputRefMin.current.value = inChangeing
    } else if (points[0] >= 1000) {
        let inChangeing = (points[0] / 1000).toFixed(1) + 'k'
        // console.log(inChangeing);
        inputRefMin.current.value = inChangeing;
    }



    if (points[1] >= 1000000) {
        let inChangeing = (points[1] / 1000000).toFixed(1) + 'M';
        inputRef.current.value = inChangeing
    } else if (points[1] >= 1000) {
        let inChangeing = (points[1] / 1000).toFixed(1) + 'k'
        // console.log(inChangeing);
        inputRef.current.value = inChangeing;
    }


}


    return (


        <div className='  bg-white  p-[10px] w-[300px] rounded-2xl '>
            <p className='font-bold text mb-2'>Price</p>
            <div>

                <div className='flex items-center'>
                    <div className='my-1 cursor-pointer flex justify-between   primeryH border rounded-lg min-w-[120px]    items-center m-1  textBlack border-gray-400 py-2 px-2'>

                        <input className='w-full outline-none placeholder-black' ref={inputRefMin } onBlur={handleChangeMin} onFocus={handleFocusMin} role='textbox' placeholder='No Min' />
                    </div>
                    <p className='mx-1'>-</p>
                    <div className='my-1 cursor-pointer flex justify-between   primeryH border rounded-lg min-w-[120px]    items-center m-1  textBlack border-gray-400 py-2 px-2'>
                        <input className='w-full outline-none placeholder-black' ref={inputRef} role='textbox' onBlur={handleChangeMax} onFocus={handleFocusMax} placeholder='No Max' />


                    </div>
                </div>

            </div>
            <Slider
                start={0}
                end={10020000}
                step={5}
                points={[data.value, data.value2]}
                onChange={ChangeSlider}
            />
        </div>
    );
}

export default PriceFilterPopup;
