import React from 'react';

const FooterDrawer = () => {
    return (
        <div className='flex'>
            <div className='w-2/4'>
                <div className=' w-full  flex justify-center pt-4'>
                    <button className='  font-bold px-[30px] w-full  shadow-md mx-1 py-[10px] rounded-full'>Save Search</button>
                </div>
            </div>
            <div className='w-2/4'>
                <div className=' w-full flex justify-center pt-4'>
                    <button className=' bgPrimery text-white font-bold px-[30px] mx-1 w-full py-[10px] rounded-full'>See 143 Homes</button>
                </div>
            </div>

        </div>
    );
}

export default FooterDrawer;
