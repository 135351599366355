import React from 'react'
import { LuMapPin } from "react-icons/lu";
import { IoMdHeartEmpty } from "react-icons/io";
import { FiShare2 } from "react-icons/fi";

const HomeDetail = () => {
  return (
       <div className='text-center xl:bg-gray-100 lg:rounded-md font-semibold xl:text-sm text-base xl:block flex justify-between py-2'>
      <div className='lg:mx-2 xl:w-[80%] w-[24%] h-[70px]  xl:h-[56px] border rounded shadow-[0px_0px_4px_-2px_rgba(0,0,0,0.75)] m-auto mt-2 cursor-pointer pt-2 bg-white hover:text-blue-900'>
        <div>22</div>
        <div>Photos</div>
      </div>
      <div className='lg:mx-2 xl:w-[80%] w-[24%] h-[70px]  xl:h-[56px] border rounded shadow-[0px_0px_4px_-2px_rgba(0,0,0,0.75)] m-auto mt-2 cursor-pointer pt-2 bg-white hover:text-blue-900'>
        <div> <center><LuMapPin/></center></div>
        <div>Map</div>
      </div>
      <div className='lg:mx-2 xl:w-[80%] w-[24%] h-[70px]  xl:h-[56px] border rounded shadow-[0px_0px_4px_-2px_rgba(0,0,0,0.75)] m-auto mt-2 cursor-pointer pt-2 bg-white hover:text-blue-900'>
        <div> <center><IoMdHeartEmpty/></center> </div>
        <div>Save</div>
      </div>
      <div className='lg:mx-2 xl:w-[80%] h-[70px]  xl:h-[56px] w-[24%] border rounded shadow-[0px_0px_4px_-2px_rgba(0,0,0,0.75)] m-auto mt-2 mb-2 cursor-pointer pt-2 bg-white hover:text-blue-900'>
        <div><center><FiShare2/></center></div>
        <div>Share</div>
      </div>
    </div>
  )
}

export default HomeDetail
