import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
const SellTooltip = (props) => {
    // const [forSale] = useState([{ title: "Homes for Sale Near Me" }, { title: "Contact an Agent" }]);
    const forSale = [{ title: "Home Selling How-To" }, { title: "How to Stage a Home" }, { title: "How to Do an Open House" }];
    const buyingR = [{ title: "How Much Home Can i Afford" }, { title: "Home Buying Checklist" }, { title: "Tips for First-Time Homebuyers" }]
    return (

        <Popover className=' widthP' body {...props}>
            <div className='flex coo w-[400px]'>
                <div>   <p className='mb-3 mt-1 text-gray-700 font-bold'>Selling Resources</p>
                    {
                        forSale.map((data) => {
                            return <div className='my-3 font-semibold'>
                                <p>{data.title}</p>
                            </div>
                        })
                    }
                </div>
                <div className='ml-4'>
                    <p className='mb-3 mt-1 text-gray-700 font-bold'>Buying Resources</p>
                    {
                        buyingR.map((data) => {
                            return <div className='my-3 font-semibold'>
                                <p>{data.title}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </Popover>
    )


}

export default SellTooltip;
