import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'

const Cities = () => {
 
    const data = ['Toronto', 'Markham', 'Mississauga' , 'Vaughan' , 'Richmond Hill' , 'Picering' , 'Brampton', 'East Bayfront' , 'Ajax' , 'Whitchurch-Stouffville' , 'King']
    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Cities</p>
            <div className='flex flex-wrap  items-center'>

                {
                    data.map((item, index) => {
                        return (
                            <div className='my-1 cursor-pointer flex justify-center  hover:bg-gray-100 primeryH border rounded-md min-w-[80px]   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2'>
                                <p className=''>{item}</p>

                            </div>
                        )
                    })
                }


            </div>

        </div>
    );
}

export default Cities;
