import React , {useEffect , useState} from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { useDispatch, useSelector } from 'react-redux';

const ExpandSearch = () => {

    const data = ['2 KM', '5 Km', '10 KM', '20 KM', '30 KM', '50 KM']
    const [slectedExpandC , setSleStoriesC] = useState()

    let dispatch = useDispatch()

    const slectedExpand = useSelector((store) => store.productSection.slectedExpand)


    useEffect(() => {
        if (Array.isArray(slectedExpand)) {
            const result = slectedExpand.join('').replace(/\s+/g, ' ');
            setSleStoriesC(result);
        }
    }, [slectedExpand]);

    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Expand Search</p>
            <div className='flex items-center'>

                {
                    data.map((item, index) => {
                        return (
                            <div onClick={() => {

                                dispatch({
                                    type: "EXPAND_SLECT",
                                    payload: item
                                })

                            }} className={`${slectedExpandC === item && 'borderPrimery bgLightGray primery'} my-1 cursor-pointer flex justify-center  hover:bg-gray-100 primeryH border rounded-md min-w-[80px]   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2`}>
                                <p className=''>{item}</p>

                            </div>
                        )
                    })
                }


            </div>

        </div>
    );
}

export default ExpandSearch;
