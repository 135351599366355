import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiPlus, BiSearchAlt2, BiSolidDownArrowAlt, BiSolidUpArrowAlt, BiUpArrow } from 'react-icons/bi';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { IoIosClose } from 'react-icons/io';
const FeatureDrawer = (props) => {

    const clickMeButtonRef = useRef();

    const [name, setName] = useState("Ali Ahmad")
    const sortData = [{ name: "Brick", }, { name: "Ideal For Outdoor Living", }, { name: "Perfectn For Pets", }, { name: "Staycation Ready", }, { name: "Backyard", }]
    let dispatch = useDispatch();


    let selectedFeatures = useSelector((store) => store.productSection.selectedFeatures)



    return (


        <div className=''>
            <p className='font-bold text mb-4 mt-4'>Features</p>
            <div className='  w-full flex justify-center rounded-full border mb-1 border-gray-600  items-center px-[3px]'>
                <input className='w-[90%]  py-1  input-border bg-inherit px-2' placeholder='Search by address, city, neighbourhood, postal code' type="text" />
                <BiSearchAlt2 className='text-[16px]' />
            </div>
            <div>

                <div className='flex flex-wrap' >
                    {
                        selectedFeatures.map((data) => {
                            return (
                                <div className='bgLightGray borderPrimery w-fit rounded-full text-[14px]  flex font-semibold items-center py-1 px-2 m-1 primery'>
                                    <p>{data}</p>

                                    <IoIosClose onClick={() => {
                                        dispatch({
                                            type: "D_UN_SLECTED_FEATURE",
                                            payload: data
                                        })


                                    }} className='text-[20px] ml-1 primery' />
                                </div>

                            )
                        })
                    }

                </div>


            </div>
            <div className='flex flex-wrap'>
                {
                    sortData.map((data) => {
                        const checkFeatures = selectedFeatures.includes(data.name);
                        return (
                            <div>
                                {!checkFeatures ?
                                    <div onClick={() => {
                                        dispatch({
                                            type: "SLECTED_FEATURE",
                                            payload: data.name
                                        })

                                    }}
                                        className='my-1 cursor-pointer flex  items-center hover:bg-gray-100 primeryH  justify-between border rounded-full py-1 px-2'>



                                        <p className='text-[14px] font-semibold '>{data.name}</p>

                                        <BiPlus className='text-[16px]' />
                                    </div>
                                    : ''}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default FeatureDrawer;
