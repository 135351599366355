// import MyNavbar from './components/Navbar';
import TrendStatus from '../../component/trendStatus/TrendStatus';
// import './App.css';
import './MarketTrendPage.css'

import { useEffect } from 'react';


function MarketTrendPage() {

  return (
  <>

 {/* <MyNavbar/> */}
 <div className='mx-3 col-11'>
    <ul className='list-unstyled my-5'>
      <li className='fn-style'> VIVA House <i className='ms-2 fw-light'> { ">" } </i> ON <i className='fw-light ms-2'> { ">" } </i><i className='text-muted'> Kitchener Market Trends </i> </li>
    </ul>
  </div> 

  <TrendStatus/>
 </>
 
  );
}

export default MarketTrendPage;
