import React from 'react'
import { HiMiniQuestionMarkCircle } from "react-icons/hi2"
import { MdSort } from "react-icons/md";
import { Link } from 'react-router-dom';
import { BiSortDown } from "react-icons/bi";

const SchoolTab1 = () => {
  return (
    <div>
      <div className="overflow-auto ">
        <table className='lg:w-full min-w-[960px] '>
          <thead>
            <th className='text-sm flex w-full h-8 items-center bg-gray-100 px-2'>
    <td className='w-[10%] flex items-center'><MdSort className='mx-1'/>Score<HiMiniQuestionMarkCircle className='mx-1'/></td>
    <td className='w-[50%]'>School Name</td>
    <div  className='w-[40%] flex justify-between'>
    <td className='flex items-center' ><BiSortDown className='mx-1'/> Distance</td>
    <td>Grade</td>
    <td>School Board</td>
    <td>Nearby Homes</td>
    </div>
            </th>
            </thead>
<tbody>
<tr className='text-base flex w-full items-center border-b p-2'>
    <td className='w-[10%]'> <div className='h-12 w-12 bg-[#BF1017] rounded-full m-auto text-white font-semibold text-[24px] flex items-center justify-center'>7.1</div> </td>
    <td className='w-[50%]'>
        <Link className='text-colour text-lg font-semibold'>Father John Redmond Catholic Secondary School</Link>
        <div className='flex flex-wrap'>  <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 mx-1 my-1 rounded'>Secondary</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Catholic program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Congregated advanced placement program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Regional arts program program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>English</p>
        </div>
       
        </td>
    <div  className='w-[40%] flex justify-between text-base font-semibold'>
    <td>Distance<div className='text-[#1F8265] text-sm bg-[#E2F3EC] px-1 mt-1'>Assigned</div></td>
    <td>JK-8</td>
    <td>Toronto DSB</td>
    <td className='text-colour'>View Homes</td>
    </div>
            </tr>
            <tr className='text-base flex w-full items-center border-b p-2'>
    <td className='w-[10%]'><div className='h-12 w-12 bg-[#BF1017] rounded-full m-auto text-white font-semibold text-[24px] flex items-center justify-center'>12</div> </td>
    <td className='w-[50%]'>
        <Link className='text-colour text-lg font-semibold'>Father John Redmond Catholic Secondary School</Link>
        <div className='flex flex-wrap'>  <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 mx-1 my-1 rounded'>Secondary</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Catholic program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Congregated advanced placement program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Regional arts program program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>English</p>
        </div>
       
        </td>
    <div  className='w-[40%] flex justify-between text-base font-semibold'>
    <td>Distance<div className='text-colour text-sm bg-[#E6EBF3] px-1 mt-1'>Nearby</div></td>
    <td>9-12</td>
    <td>School Board</td>
    <td className='text-colour'>View Homes</td>
    </div>
            </tr>
            <tr className='text-base flex w-full items-center border-b p-2'>
    <td className='w-[10%]'><div className='h-12 w-12 bg-[#BF1017] rounded-full m-auto text-white font-semibold text-[24px] flex items-center justify-center'> 9.1</div> </td>
    <td className='w-[50%]'>
        <Link className='text-colour text-lg font-semibold'>Father John Redmond Catholic Secondary School</Link>
        <div className='flex flex-wrap'>  <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 mx-1 my-1 rounded'>Secondary</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Catholic program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Congregated advanced placement program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>Regional arts program program</p>
        <p className='text-[#021B42] text-xs bg-[#EEEEEE] p-1 rounded mx-1 my-1'>English</p>
        </div>
       
        </td>
    <div  className='w-[40%] flex justify-between text-base font-semibold'>
    <td>Distance<div className='text-colour text-sm bg-[#E6EBF3] px-1 mt-1'>Nearby</div></td>
    <td>9-12</td>
    <td>School Board</td>
    <td className='text-colour'>View Homes</td>
    </div>
            </tr>
</tbody>

        </table>
       
      </div>
      <div className='flex mt-2'>
        <div className='w-1/2 text-sm'>
        <p> School Score and Ranking is provided by the <Link className='text-[#BF1017] font-semibold'>Fraser Institute</Link> Copyright 2017 The Fraser Institute.  </p> 
        <p>School boundary data is provided by <Link className='text-[#BF1017] font-semibold'>Local Logic</Link>To verify enrollment eligibility for a property, contact the school directly. </p>
      </div>
      <div className='w-1/2'> <Link className='text-[#BF1017] text-lg font-bold float-right'>View 10 More Schools</Link></div>
      </div>
    </div>
  )
}

export default SchoolTab1