
import Offcanvas from 'react-bootstrap/Offcanvas';
import PropertyType from '../filterDrawerComponent/PropertyType';
import FeatureDrawer from '../filterDrawerComponent/FeatureDrawer';
import SoldDrawer from '../filterDrawerComponent/SoldDrawer';
import BedsDrawer from '../filterDrawerComponent/BedsDrawer';
import BathsDrawer from '../filterDrawerComponent/BathsDrawer';
import LotSizeDrawer from '../filterDrawerComponent/LotSizeDrawer';
import DaysOnHouseful from '../filterDrawerComponent/DaysOnHouseful';
import YeareBuilt from '../filterDrawerComponent/YearBuilt';
import MaintenanceFee from '../filterDrawerComponent/MaintenanceFee';
import Stories from '../filterDrawerComponent/Storeys';
import ExpandSearch from '../filterDrawerComponent/ExpandSearch';
import Neighbourhoods from '../filterDrawerComponent/Neighbourhoods';
import Cities from '../filterDrawerComponent/Cities';
import FooterDrawer from '../filterDrawerComponent/FooterDrawer';
import Tour from '../filterDrawerComponent/Tour';
import HideNoPhotoListing from '../filterDrawerComponent/HideNoPhoto';
import PriceDrawer from '../filterDrawerComponent/PriceDrawer';
import HomeSize from '../filterDrawerComponent/HomeSize';


function FilterDrawar({ show, handleClose }) {


    return (
        <>


            <Offcanvas show={show} onHide={handleClose} style={{ width: '700px' }}>
                <Offcanvas.Header >
                    <p>Reset</p>
                    <Offcanvas.Title>All Filterfs</Offcanvas.Title>
                    <div className='flex pr-2 pt-2 justify-end'>
                        <img className=' w-[20px] h-[20px] cursor-pointer' onClick={handleClose} src={require('../../img/close.png')} alt="" />
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <div className='flex '>
                            <div className=' w-2/4'>
                                <PropertyType />
                            </div>
                            <div>
                                <PriceDrawer/>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className=' w-2/4 mr-3'>
                                <FeatureDrawer />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <SoldDrawer />
                            </div>
                        </div>
                        <div className='flex'>
                            <div className=' w-2/4 mr-2'>
                                <BedsDrawer />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <BathsDrawer />
                            </div>
                        </div>

                        <div className='flex'>
                            <div className=' w-2/4 mr-2'>
                                <Stories />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <DaysOnHouseful />
                            </div>
                        </div>

                        <div className='flex'>
                            <div className=' w-2/4 mr-2'>
                                <LotSizeDrawer />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <YeareBuilt />
                            </div>
                        </div>


                        <div className='flex'>
                            <div className=' w-2/4 mr-2'>
                                <MaintenanceFee />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <HomeSize />
                            </div>
                        </div>
                        <div className='flex'>
                            <div className=' w-2/4 mr-2'>
                                <Tour />
                            </div>
                            <div className=' w-2/4 ml-3 '>
                                <HideNoPhotoListing />
                            </div>
                        </div>
                        <div>
                            <ExpandSearch />
                        </div>
                        <div>
                            <Neighbourhoods />
                        </div>
                        <div>
                            <Cities />
                        </div>
                        <div>
                            <FooterDrawer />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default FilterDrawar;