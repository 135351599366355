import React, { useState } from 'react';
import LatestListingCard from '../LatestListingCard/LatestListingCard';
const NearbyMapCard = () => {

    const [data, SetData] = useState([{ title: "Cities", img: require(`../../img/newCity.jpg`) }, { title: "Neighbourhoods", img: require(`../../img/modren_city.jpg`) }, { title: "FSA", img: require(`../../img/Near_House.jpg`) }])
    return (
        <div className='bg-gray-50 pt-5 mt-3'>
            <div className='max-w-[1400px] relative m-auto'>
                <div className=' mb-5'>

                    <p className='text-2xl font-bold '>Real Estate & Homes For Sale Nearby Kitchener, ON </p>
                </div>
                <div className='w-[70%] relative m-auto'>
                    <div className='grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3'>
                        {
                            data.map((data) => {
                                return <LatestListingCard data={data} />
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NearbyMapCard;
