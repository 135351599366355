import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OptionCard.css'

const OptionCard = (props) => {
    const Navigate = useNavigate();
    return (
        <div className='OptionBorder rounded-xl '>
            <div className='p-5'>
                <div className=''>
                  <span className=''>{props.data.icon}</span>        
                </div>
                <div>
                    <p className='text-2xl font-bold'>{props.data.title} </p>
                </div>
                <p className='text-gray-500  text-[16px]'>
                  {props.data.para}
                </p>
                
                <button className='text-[16px] mt-4 w-[200px]  OptionBorder px-4 py-2 rounded-lg font-semibold' onClick={() => Navigate(props?.data?.link)}>{props.data.button}</button>
            </div>

        </div>
    );
}

export default OptionCard;
