import React from 'react';
import Form from 'react-bootstrap/Form';
const HideNoPhotoListing = () => {
    return (
        <div className='flex justify-between mt-4 items-center'>
            <p className='font-bold text  '>Hide No Photo Listings</p>
            <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"

            />
        </div>
    );
}

export default HideNoPhotoListing;
