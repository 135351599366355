import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Header from '../../component/Header';
import MapNav from '../../component/mapNave/MapNav';
import Footer from '../../component/footer/Footer';
import PasswordValidator from "password-validator";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiHide, BiShowAlt } from 'react-icons/bi';
// import { useParams } from 'react-router-dom';
const ResetPassword = () => {
    const { id } = useParams();
    const [match, setMatch] = useState(false)
    const [createValid, setCreateValid] = useState(false)
    const [data, setData] = useState('')
    const [hide, setHide] = useState(false)
    const [hide1, setHide1] = useState(false)
    const [sendData, setSendData] = useState({
        token: id,
        password: data
    })
    const createPass = useRef('')
    const conPass = useRef(null)

    const schema = new PasswordValidator();

    schema.is().min(6).has().not();



    console.log('check id ', id);
    // schema
    //     .is().min(8)                                    // Minimum length 8
    //     .is().max(100)                                  // Maximum length 100
    //     .has().uppercase()                              // Must have uppercase letters
    //     .has().lowercase()                              // Must have lowercase letters
    //     .has().digits(2)                                // Must have at least 2 digits
    //     .has().not().spaces()                           // Should not have spaces
    //     .is().not().oneOf(['Passw0rd', 'Password123']);





    const pass = {
        password: "123456788"
    }

    let Navigate = useNavigate()
    const savePassword = async () => {
        const isValid = schema.validate(data);


        console.log('check in  front end', sendData);


        if (isValid) {

            if (createPass.current.value != conPass.current.value) {

                setMatch(true)

            } else {
                try {
                    let response = await axios.post(`http://localhost:8000/api/v1/users/verifyAccount`, sendData);
                    console.log(response.status);
                    if (response.status === 200) {
                        Navigate('/')
                        toast.success("Password added");
                        console.log('done ho gia hai');
                        // setSignUp(true)
                        // setStapper(true)
                        // console.log('data', response.data.data.email);
                        // localStorage.setItem('Token', response.data.token);
                        // localStorage.setItem('email', response.data.data.email)

                        // dispatch({
                        //     type: 'SIGHN_UP_COMPLETE',

                        // })
                    }

                } catch (error) {
                    console.log('error', error);
                }
            }

        } else {


            setCreateValid(true)
        }




    }

    const setPass = (e) => {
        setData(e.target.value)
        const isValid = schema.validate(e.target.value);
        console.log('done hoa hai', isValid);
        setSendData({ ...sendData, password: e.target.value })
    }


    // const { token } = useParams();
    // console.log('get tokken', token);
    return (
        <div className=''>
            <MapNav />
            <div className='flex justify-center mb-10 mt-5 '>

                <div className='w-[350px] text-center'>
                    <div className='mb-2'>
                        <p className='font-semibold text-[24px]'>Setup Your Password</p>
                        <p>Enter your password</p>
                    </div>
                    <div>
                        <div>
                            <div className='flex items-center '>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Create new Password"
                                    className="lg:mb-0 w-full md:mb-0  text-sm"

                                >
                                    <Form.Control ref={createPass} onChange={setPass} type={hide1 ? "text" : "password"} id='inputH' className=' ' placeholder="name@example.com" />
                                </FloatingLabel>
                                {
                                    hide1 ?
                                        <BiHide onClick={() => setHide1(false)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />
                                        :
                                        <BiShowAlt onClick={() => setHide1(true)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />

                                }
                            </div>

                        </div>
                        <div className='h-5' >
                            {
                                createValid &&
                                <p className='text-red-600 text-sm'> Password must have 6 digit</p>

                            }

                        </div>
                        <div>
                            <div className='flex items-center '>

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Verify password"
                                    className="lg:mb-1 md:mb-1 w-full text-sm"

                                >
                                    <Form.Control ref={conPass} type={hide ? "text" : "password"} id='inputH' className='  ' placeholder="name@example.com" />

                                </FloatingLabel>
                                {
                                    hide ?
                                        <BiHide onClick={() => setHide(false)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />
                                        :
                                        <BiShowAlt onClick={() => setHide(true)} className='text-[22px] cursor-pointer -translate-x-8 -translate-y-2 ' />

                                }

                            </div>

                        </div>
                        <div className='h-6'>
                            {
                                match &&
                                <p className='text-red-600  '>Password does not match</p>
                            }

                        </div>
                    </div>
                    <button onClick={savePassword} className='border bgLightGray borderPrimery  w-[350px] py-2 rounded-full font-semibold text-[18px] mt-1'> Save Password </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ResetPassword;
