import React , {useState , useEffect} from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';

const MaintenanceFee = () => {



    const itemList = ['No Min', '$5', '$50', '$100', '$150', '$200', '$250', '$300', '$350', '$400', '$450', '$500', '$600', '$700', '$800', '$900', '$1000', '$1250', '$1500', '$2000', '$2500', '$3000']
    const itemList2 = ['No Max', 'No Maint', '$5', '$50', '$100', '$150', '$200', '$250', '$300', '$350', '$400', '$450', '$500', '$600', '$700', '$800', '$900', '$1000', '$1250', '$1500', '$2000', '$2500', '$3000']
    const [slectedMaintenanceC,  setSlectedMaintenanceC] = useState();
    const [minI, setMinI] = useState();
    let dispatch = useDispatch()

    let slectedMaintenance = useSelector((store) => store.productSection.slectedMaintenance)
    let slectedMaintenanceMax = useSelector((store) => store.productSection.slectedMaintenanceMax)

    useEffect(() => {
        if (slectedMaintenanceC) {
          let find = itemList.indexOf(slectedMaintenanceC);
          setMinI(find)
          console.log('indexFound', find);
        }
      }, [slectedMaintenanceC]);
    
      useEffect(() => {
        if (Array.isArray(slectedMaintenance)) {
          const result = slectedMaintenance.join('').replace(/\s+/g, ' ');
          setSlectedMaintenanceC(result);
        }
      }, [slectedMaintenance]);
    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Maintenance Fee</p>
            <div className='flex items-center'>
                <Dropdown>
                    <Dropdown.Toggle
                        bsPrefix="dropdown"
                        className="bg-transparent  border-0 p-0 h-full     ">
                        <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
                            <p>{!slectedMaintenance > 0 ? 'No Min' : slectedMaintenance}</p>
                            <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
                        {
                            itemList.map((item) => {
                                return <Dropdown.Item
                                    onClick={() => {
                                        dispatch({ type: 'MAINTENANCEFEE_MIN', payload: item })
                                    }}
                                    className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                            })
                        }

                    </Dropdown.Menu>
                </Dropdown>





                <p className='mx-1'>-</p>

                <Dropdown>
                    <Dropdown.Toggle
                        bsPrefix="dropdown"
                        className="bg-transparent  border-0 p-0 h-full     ">
                        <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
                            <p className=''>{!slectedMaintenanceMax > 0 ? 'No Max' : slectedMaintenanceMax}</p>
                            <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-0 max-h-[450px] overflow-auto'>
                        {
                            itemList2.map((item , i ) => {
                                return <div>
                                    {
                                        minI ?
                                            <div>
                                                {
                                                    minI < i &&

                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            dispatch({ type: 'MAINTENANCEFEE_MAX', payload: item })
                                                        }}
                                                        className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                                                }
                                            </div>
                                            :
                                            <Dropdown.Item
                                                onClick={() => {
                                                    dispatch({ type: 'MAINTENANCEFEE_MAX', payload: item })
                                                }}
                                                className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                                    }
                                </div>
                            })
                        }

                    </Dropdown.Menu>
                </Dropdown>
            </div>

        </div>
    );
}

export default MaintenanceFee;
