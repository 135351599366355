import React from 'react'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Home from '../../img/Near_House.jpg'
import { Link } from 'react-router-dom'
import ContactAgent from './ContactAgent'
const ContactAgentModal = () => {
    const [open, setOpen] = useState(true)
    const [show, setShow] = useState(false)
    const [showdetail,setShowDetail]=useState(false)

    const cancelButtonRef = useRef(null)
  return (
   

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity  " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                   
                    <div className="text-center  sm:mt-0 sm:text-left">
                     
                      <div>
                        <p className="text-sm text-gray-500 bg-gray-100 p-2 rounded ">
                           
                          {/* <ContactAgent/> */}
              <p className='text-sm sm:text-[24px] font-bold mt-2'><center>Contact Agent for 2330 Bridletown Cir #1604</center> </p>
             <div className='flex mt-2'>
              <div ><img src={Home} alt="" className='h-[120px] w-[120px] rounded-xl' /> </div>
              <div className='mx-2'>
                 <div className='text-[20px] font-bold'>#9999,90</div>
                 <div className='text-base font-semibold'>3 bd, 2 bt </div>
                 </div>
             </div>

             <form className='mt-3'>
                 <input type="text" className='rounded-md w-full h-10 p-2' placeholder='Full Name' />
                 <input type="text" className='rounded-md w-full my-2 h-10 p-2' placeholder='Email Address' />
                 <input type="text" className='rounded-md w-full h-10 p-2' placeholder='Phone Number' />
             </form>
             <div className='mt-3 text-xs leading-5'>
                     We'll call you within the next business hour to match you with an agent from the Houseful™ agent network.
              <p className={`text-colour font-semibold py-2 cursor-pointer ${showdetail == false ? 'block' : 'hidden'}`} onClick={() => setShowDetail(true)}>See More</p>
              <div className={`${showdetail == false ? 'hidden' : 'block'}`}>
                      By submitting this information, I acknowledge I have read and agree to the
                 <Link className='text-xs font-semibold text-colour'> Terms of use</Link>
                 <span className='text-xs'> including its Privacy section.</span>
                 <p className={`text-colour font-semibold py-2 cursor-pointer ${showdetail == true ? 'block' : 'hidden'}`} onClick={() => setShowDetail(false)}>See Less</p>
                 </div>
             </div>
             <div className='text-xs flex mt-3'> 
             <input type="checkbox" className='mx-2 h-6 w-6 border' />
             <div>
              <span>I would like to receive marketing and promotional messages by telephone, text message, and email from Houseful,
              <p className={`text-colour font-semibold py-2 cursor-pointer ${show == false ? 'block' : 'hidden'}`} onClick={() => setShow(true)}>See More</p>
                <div className={`${show == false ? 'hidden' : 'block'}`}>
                 including information and updates about properties of interest and the services and features of Houseful and our selected partners. I may withdraw my consent at any time. Message and data rates may apply. Consent is not required to receive real estate services.
                 <p className={`text-colour font-semibold py-2 cursor-pointer ${show == true ? 'block' : 'hidden'}`} onClick={() => setShow(false)}>See Less</p>
                  </div></span></div>
              </div>

              <button className='mt-3 font-bold btn-bg rounded-full w-full text-white m-auto h-10'>Contact Agent</button>
              <hr  className='mt-3'/>

              <div className='font-bold mt-3 '>
                 <div className='text-sm text-center'>Not a good item?</div>
                 <div className='text-colour text-base text-center'><Link>Schedule a Call</Link></div>
              </div>


                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md btn-bg px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button>
                  {/* <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ContactAgentModal
