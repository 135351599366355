import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from '../view/Home';
import PageNotFound from '../view/PageNotFound';
import MapList from '../view/mapList/MapList';
import Office from '../view/officePage/OfficePage';
import SetPassword from '../view/setPassword/SetPassword';
import ResetPassword from '../view/resetPassword/ResetPassword';
import PropertyCard from '../view/testView/TestView';
import MarketTrendPage from '../view/marketTrendPage/MarketTrenPage';
import ErrorPAge from '../view/404page/ErrorPAge';
import PropertyDetail from '../view/propertyDetail/PropertyDetail';
// import Test from '../view/test/Test';
const AppRoutes = () => {
  return (
    <>

      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/list" element={<MapList />}></Route>
            <Route path="/office" element={<Office />}></Route>
            <Route path="/verifyAccount/:id" element={<SetPassword />}></Route>
            <Route path="/passwordReset/:id" element={<ResetPassword />}></Route>
            <Route path="/testNav" element={<PropertyCard/>}></Route>
            <Route path="/market" element={<MarketTrendPage />}></Route>
            <Route path="/404-page" element={<PageNotFound />}></Route>
            <Route path="/propertyDetail" element={<PropertyDetail />}></Route>
            <Route path="*" element={<ErrorPAge />}></Route>
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default AppRoutes;
