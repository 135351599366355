import React, { useEffect, useState } from 'react';
import { IoIosClose, IoMdArrowDropright } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
const SoldDrawer = () => {
    const [soldState, setSoldState] = useState('');
    let dispatch = useDispatch();
    let Sold = ['Sold < 1yr', "Sold < 1 mo", "Sold < 3 mo", "Sold < 6 mo",]


    let soldAdd = useSelector((store) => store.productSection.soldAdd)

    useEffect(() => {
        if (Array.isArray(soldAdd)) {
            const result = soldAdd.join('').replace(/\s+/g, ' ');
            setSoldState(result);
        }
    }, [soldAdd]);
    console.log('sold drawer', soldState);

    return (
        <div>
            <p className='font-bold text mb-4 mt-4'>Property Status</p>


            <div onClick={() => {

                dispatch({
                    type: "SOLD_SLECT",
                    payload: ''
                })

            }} className={`${soldState.length > 0 ? 'bgLightGray borderPrimery rounded-full w-fit m-1  flex font-semibold items-center py-1 px-2 primery my-1' : 'my-1 cursor-pointer flex  hover:bg-gray-100 primeryH border rounded-full w-fit   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2'}`}>
                <p className='font-semibold text-[14px] '>Sold</p>
                <IoMdArrowDropright className={`text-[20px] ml-1 ${soldState.length > 0 && 'primery'} `} />
            </div>

            <div className=' flex flex-wrap'>

                {
                    Sold.map((data, i) => {
                        return <div onClick={() => {

                            dispatch({
                                type: "SOLD_SLECT",
                                payload: data
                            })

                        }} className={`${soldState === data ? 'bgLightGray borderPrimery rounded-full m-1  flex font-semibold items-center py-1 px-2 primery my-1' : 'my-1 cursor-pointer flex  hover:bg-gray-100 primeryH border rounded-full w-fit   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2'}`}>
                            <p className='font-semibold text-[14px] min-w-[80px]'>{data}</p>
                        </div>
                    })
                }

            </div>
        </div>
    );
}

export default SoldDrawer;
