import React from 'react';
import Carousel from "react-multi-carousel";

const PropertyDetailTab = ({active , tabSet}) => {
  const responsive = {

    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 5 // optional, default to 5.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 740, min: 200 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }



};
  return (
<>

    <div>
                        <Carousel className='mx-3 mt-3 ' responsive={responsive}> 
                        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 0 ? 'text-[#BF1017] border-b-2 border-[#BF1017]' : 'text-gray-600'}`} onClick={()=>tabSet(0)}>Highlights</div>

                        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 1 ? 'text-[#BF1017] border-b-2 border-[#BF1017]' : 'text-gray-600'}`} onClick={()=>tabSet(1)}>Our Features & insights</div>

                        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 2 ? 'text-[#BF1017] border-b-2 border-[#BF1017]' : 'text-gray-600'}`} onClick={()=>tabSet(2)}>Neighbourhood</div>

                        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 3 ? 'text-[#BF1017] border-b-2 border-[#BF1017]' : 'text-gray-600'}`} onClick={()=>tabSet(3)}>Schools</div>

                        </Carousel>
                    </div>
     
 {/* <div id="controls-carousel" className="relative w-full" data-carousel="static">
    <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
        <div className="hidden duration-700 ease-in-out" data-carousel-item>
        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 0 ? 'primery border-b-2 border-b-blue-600' : 'text-gray-600'}`} onClick={()=>tabSet(0)}>Highlights</div>
        </div>
        <div className="hidden duration-700 ease-in-out" data-carousel-item="active">
        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 1 ? 'primery border-b-2 border-b-blue-600' : 'text-gray-600'}`} onClick={()=>tabSet(1)}>Our Features & insights</div>

        </div>
        <div className="hidden duration-700 ease-in-out" data-carousel-item>
        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 2 ? 'primery border-b-2 border-b-blue-600' : 'text-gray-600'}`} onClick={()=>tabSet(2)}>Payment</div>

        </div>
        <div className="hidden duration-700 ease-in-out" data-carousel-item>
        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 3 ? 'primery border-b-2 border-b-blue-600' : 'text-gray-600'}`} onClick={()=>tabSet(3)}>Neighbourhood</div>

        </div>
        <div className="hidden duration-700 ease-in-out" data-carousel-item>
        <div className={`text-center px-4 font-bold cursor-pointer py-2  ${active == 4 ? 'primery border-b-2 border-b-blue-600' : 'text-gray-600'}`} onClick={()=>tabSet(4)}>Schools</div>

        </div>
    </div>
    <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span className="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span className="sr-only">Next</span>
        </span>
    </button>
</div>  */}
</>
  );
}

export default PropertyDetailTab;
