import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from 'react-avatar';
import { PiPhoneBold } from "react-icons/pi";
import LoginModal from '../modal/LoginModal';
import Drawar from '../drawar/Drawar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BuyTooltip from '../buyTooltip/BuyTooltip';
import SellTooltip from '../sellTooltip/SellTooltip';
import { BiSearch, BiSolidNavigation, BiBarChart, BiHeart, BiNotification, BiMenu } from "react-icons/bi";
import './MapNav.css'
import Collapse from 'react-bootstrap/Collapse';
import { useNavigate } from 'react-router-dom';

function MapNav(props) {
    const Navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setShow(false)
        console.log("drawar running");
    };
    const handleShow = () => setShow(true);
    return (
        <Navbar className=" p-0 ">


            <div className='flex justify-between w-full items-center py-2 border border-y-gray-400 bg-white '>

                {/* <div className='lg:hidden md:hidden sm:flex flex mr-2 w-full justify-between ml-2'>
                    <div>
                        <img onClick={handleShow} className='lg:hidden md:hidden cursor-pointer sm:block  ' src={require('../../img/menu.png')} width={20} height={20} alt="" />
                    </div>

                    <div>
                        <img className='lg:hidden md:hidden sm:block w-[50px] h-[20px] ' src={require('../../img/logo.png')} width={20} height={20} alt="" />
                    </div>
                    <div>
                        <PiPhoneBold className='textBlack w-[20px] cursor-pointer h-[20px]' />
                    </div>
                </div> */}












                <div className=' lg:w-[50%]  md:w-[100%] sm:w-[100%] w-[100%]  '>
                    {
                        props.page === 'error' ?
                            '' :
                            <div className='flex'>
                                <div className='ml-2 flex mr-2 lg:w-fit items-center md:fit sm:[200px] '>
                                    <BiMenu onClick={handleShow} className=' lg:hidden md:block sm:block mr-2 block w-[30px] h-[30px] primery' />
                                    <div onClick={() => Navigate('/')} className='text-black text-lg font-bold cursor-pointer'>
                                        VIVA House
                                    </div>
                                    {/* <img onClick={() => Navigate('/')} className='lg:flex md:flex sm:flex hidden  w-[50px] h-[20px] cursor-pointer' src={require('../../img/logo.png')} alt="" /> */}
                                </div>

                                <div className='relitive lg:w-[55%] md:w-[70%] sm:w-[60%] w-[60%] ' >
                                    <div className='lg:flex md:flex sm:flex hidden justify-center  '>
                                        <div className=' h-[40px] flex justify-center'>
                                            <div className={` lg:w-[25%] md:w-[60%] sm:w-[60%] cursor-pointer w-[60%] bg-gray-100 absolute  border-gray rounded-3xl pt-1  `}>
                                                <div className='flex items-center pb-1 w-full px-2'>
                                                    <input className='w-[90%] input-border bg-inherit px-2' placeholder='Search by address, city, neighbourhood, postal code' type="text" />
                                                    <div className='w-[10%] flex justify-end'>

                                                        <BiSearch className='w-[20px] h-[20px] text-gray-400' onClick={() => setOpen(!open)} />
                                                    </div>
                                                </div>
                                                <Collapse in={open}>

                                                    <div id="example-collapse-text  " className='bg-white'>
                                                        <div className='flex justify-between  t-border  py-1 items-center px-2'>
                                                            <p>Current Location</p>
                                                            <BiSolidNavigation className='w-[20px] h-[15px] ' />
                                                        </div>
                                                        <div className='t-border px-2'>
                                                            <p className='text-[10px] font-bold text-gray-500 mt-2'>RECENT SEARCHES</p>

                                                            <div className='flex justify-between   py-1 items-center'>
                                                                <div className='pl-2'>
                                                                    <p className='text-sm font-bold p-0  '>kichener ON</p>
                                                                    <p className='text-[10px] text-gray-500 font-semibold '>Canada</p>
                                                                </div>
                                                                < BiSearch className='w-[20px] h-[20px] text-gray-400 ' />
                                                            </div>
                                                        </div>
                                                        <div className='t-border px-2'>
                                                            <p className='text-[10px] font-bold text-gray-500 mt-2'>MARKET TRENDS</p>

                                                            <div className='flex justify-between   py-1 items-center'>
                                                                <div className='pl-2'>
                                                                    <p className='text-sm font-bold p-0  '> Markit trend in kichener ON</p>

                                                                </div>
                                                                < BiBarChart className='w-[20px] h-[20px] text-gray-400 ' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className='bgPrimery py-2  px-3 rounded-full flex items-center ml-2 '>
                                    <BiNotification className='text-white font-[16px] mr-2 ' />
                                    <p className='text-white lg:flex md:flex sm:hidden hidden font-semibold'>Save Search</p>
                                    <p className='text-white lg:hidden md:hidden sm:flex flex font-semibold'>Save</p>
                                </div>
                            </div>
                    }
                </div>





                <Nav className=" lg:flex md:hidden sm:hidden hidden text-xs font-bold textBlack ">
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={BuyTooltip}

                    >
                        <Nav.Link className='textBlack' >Buy</Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={SellTooltip}

                    >
                        <Nav.Link className='textBlack' href="#link">Sell</Nav.Link>
                    </OverlayTrigger>

                    <Nav.Link className='textBlack' href="#link">Resource Center</Nav.Link>
                    <Nav.Link className='textBlack' href="#link">1-243-764-9867</Nav.Link>
                    <div onClick={() => setModalShow(true)} className='border border-gray-400 rounded-full cursor-pointer py-2 px-3 mx-2 '>
                        Login or Sign up
                    </div>
                    <Avatar className='mr-2' name="Wim Mostmans" size="35" color="#D3D3D3" round={true} />
                </Nav>

            </div>
            <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Drawar show={show} handleClose={handleClose} />
        </Navbar>
    );
}

export default MapNav;