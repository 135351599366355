import React, { useState } from 'react';
import MapNav from '../../component/mapNave/MapNav';
import MapPageFilterNav from '../../component/mapPageFilterNav/MapPageFilterNav';
import MapListing from '../../component/mapListing/MapListing';
import MapTest from '../../component/mapListing/MapTest';
import MapListingCard from '../../component/mapListListing/MapListingCard';
import { BiCurrentLocation } from 'react-icons/bi';


const MapList = () => {
  const [hide, setHide] = useState(true)

  const setHiddenMAp = () => {
    setHide(!hide)
  }


  return (
    <div>
      <MapNav />
      <MapPageFilterNav setHiddenMAp={setHiddenMAp} />


      <div className='flex '>
        <div className={`${hide ? 'hidden' : 'block'}  xl:w-[55%] lg:w-[55%] md:w-[55%] sm:w-full w-full`}>
          <div className=' relative w-full bg-black '>
            <div className='absolute w-full'>
              <MapListing />
            </div>
          </div>
        </div>
        <div className={`${hide ? 'w-full' : 'w-[45%] xl:block lg:block md:block sm:hidden hidden'}   `}>
         
            <MapListingCard hide={hide} />
     
        </div>
      </div>
      <div>
        <div onClick={() => setHide(!hide)} className='fixed xl:hidden lg:hidden md:hidden sm:flex cursor-pointer  bottom-4 border rounded-full right-4 flex font-semibold items-center  bg-white  textBlack border-gray-400 mx-[2px] py-[3px] px-[10px]'>
          <BiCurrentLocation className='mr-2 primery' />
          <p className='primery' >Map View</p>
        </div>
      </div>
    </div>
  );
}

export default MapList;
