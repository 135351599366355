import React, { useState } from 'react';
import LatestListingCard from '../LatestListingCard/LatestListingCard';
import {
    newlyAddedHouses,
    popularCities,
    nearHouses,
    openHouses
} from '../LatestListingCard/citiesList';

const LatestListing = () => {

    const data = [
        {
            title: "Newly Added Cities",
            img: require(`../../img/house.jpg`),
            list: newlyAddedHouses
        },
        {
            title: "Popular Cities Searched",
            img: require(`../../img/modren_city.jpg`),
            list: popularCities
        },
        {
            title: "Search Near You",
            img: require(`../../img/Near_House.jpg`),
            list: nearHouses
        },
        {
            title: "Find Open Houses",
            img: require(`../../img/newHome.jpg`),
            list: openHouses
        }
    ]
    
    return (
        <div className='bg-gray-50 pt-5 mt-3'>
            <div className='max-w-[1400px] relative m-auto'>
                <div className='text-center mb-5'>
                    <p className='text-xs font-bold text-gray-500 '>FRESH LISTINGS</p>
                    <p className='text-2xl font-bold '>Newest Homes for Sale in </p>
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3'>
                    {
                        data.map((data, i) => {
                            return <LatestListingCard data={data} index={i} />
                        })
                    }

                </div>
            </div>
        </div>
    );
}

export default LatestListing;
