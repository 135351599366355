import React, { useEffect, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';

const LotSizeDrawer = () => {
  const itemList = ['No Min', '1,000 Sqft', '2,000 Sqft', '4,500 Sqft', '6,500 Sqft', '8000 sqft', '1 Acere', '2 Aceres', '3 Aceres', '4 Aceres', '5 Aceres', '10 Aceres', '40 Aceres', '100 Aceres']
  const itemList2 = ['No Max', '1,000 Sqft', '2,000 Sqft', '4,500 Sqft', '6,500 Sqft', '8000 sqft', '1 Acere', '2 Aceres', '3 Aceres', '4 Aceres', '5 Aceres', '10 Aceres', '40 Aceres', '100 Aceres']
  const [slectedLotSizeC, setSlectedLotSizeC] = useState();
  const [minI, setMinI] = useState();
  let dispatch = useDispatch()

  let slectedLotSize = useSelector((store) => store.productSection.slectedLotSize)
  let slectedLotSizeMax = useSelector((store) => store.productSection.slectedLotSizeMax)

  useEffect(() => {
    if (slectedLotSizeC) {
      let find = itemList.indexOf(slectedLotSizeC);
      setMinI(find)
      console.log('indexFound', find);
    }
  }, [slectedLotSizeC]);

  useEffect(() => {
    if (Array.isArray(slectedLotSize)) {
      const result = slectedLotSize.join('').replace(/\s+/g, ' ');
      setSlectedLotSizeC(result);
    }
  }, [slectedLotSize]);

  console.log('slectedLotSize', slectedLotSize);

  return (
    <div>
      <p className='font-bold text mb-4 mt-4'>Lot Size (sq ft)</p>
      <div className='flex items-center'>
        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p>{!slectedLotSize > 0 ? 'No Min' : slectedLotSize}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0'>
            {
              itemList.map((item) => {
                return <Dropdown.Item
                  onClick={() => {
                    dispatch({ type: 'LOT_SIZE_MIN', payload: item })
                  }}
                  className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
              })
            }

          </Dropdown.Menu>
        </Dropdown>





        <p className='mx-1'>-</p>

        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="dropdown"
            className="bg-transparent  border-0 p-0 h-full     ">
            <div className='my-1 cursor-pointer flex justify-between  hover:bg-gray-100 primeryH border rounded-lg min-w-[120px]   font-semibold items-center m-1  textBlack border-gray-400 py-2 px-2'>
              <p className=''>{!slectedLotSizeMax > 0 ? 'No Max' : slectedLotSizeMax}</p>
              <img className='  ' src={require('../../img/dropDown.png')} width={20} height={20} alt="" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='p-0'>
            {
              itemList2.map((item, i) => {
                return <div>
                  {
                    minI ?
                      <div>
                        {
                          minI < i &&

                          <Dropdown.Item
                            onClick={() => {
                              dispatch({ type: 'LOT_SIZE_MAX', payload: item })
                            }}
                            className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                        }
                      </div>
                      :
                      <Dropdown.Item
                        onClick={() => {
                          dispatch({ type: 'LOT_SIZE_MAX', payload: item })
                        }}
                        className='py-[0px] text-base hover:text-white hover:bg-blue-600' href="#/action-1">{item}</Dropdown.Item>
                  }
                </div>
              })
            }

          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
}

export default LotSizeDrawer;
