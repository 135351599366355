import React from 'react';
import { BiLogoInstagramAlt, BiLogoPinterest, BiLogoTwitter, BiSolidMessageRounded } from 'react-icons/bi';
import { PiFacebookLogoFill, PiPhoneBold, PiUserSwitchLight, PiUsersThreeFill } from 'react-icons/pi';

const Footer = () => {
    return (
        <div>
            <div className='lg:flex md:flex sm:block block text-center  justify-center items-center my-3'>
                <p className='lg:text-[20px] md:text-[18px]  font-bold'>Meet with a real estate agent today-Call</p>
              <div className='flex items-center  justify-center'>
                <PiPhoneBold className='text-colour w-[20px] h-[20px] mx-1' />
                <p className='text-[20px] text-colour font-bold'>1-833-709-1946</p>
                </div>
            </div>

            <div className='btn-bg lg:flex md:flex pl-5 sm:block block justify-around'>
                <div className='lg:w-[20%] md:w-[20%] sm:w-full   lg:flex md:flex sm:block  block justify-center  py-4'>
                    <div>
                        <p className='text-white font-bold text-[16px]'>About</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>About VIVA House</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Sitemap</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Schools</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Terms of Use</p>
                    </div>
                </div>
                <div className='lg:w-[20%] md:w-[20%] sm:w-full   lg:flex md:flex sm:block  block justify-center  py-4'>
                    <div>
                        <p className='text-white font-bold text-[16px]'>Homes</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Homes for Sale Near Me</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Open Houses Near Me</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Homes Recently Sold Near Me</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Home Journey How-to's</p>
                    </div>
                </div>
                <div className='lg:w-[20%] md:w-[20%] sm:w-full   lg:flex md:flex sm:block  block justify-center  py-4'>
                    <div>
                        <p className='text-white font-bold text-[16px]'>VIVA House Brokerage</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Brokerage Office</p>
                        <p className='text-white font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>Apply</p>
                        <div className='flex justify-between lg:w-full md:w-full sm:w-[20%] w-[30%] text-white mt-[40px]'>
                            <PiFacebookLogoFill className='w-[20px] h-[20px]' />
                            <BiLogoInstagramAlt className='w-[20px] h-[20px]' />
                            <BiLogoTwitter className='w-[20px] h-[20px]' />
                            <BiLogoPinterest className='w-[20px] h-[20px]' />

                        </div>
                    </div>
                </div>

                <div className='lg:w-[20%] md:w-[20%] sm:w-full   lg:flex md:flex sm:block  block justify-center  py-4'>
                    <div>
                        <div className='flex text-white items-center'>
                            <BiSolidMessageRounded className='w-[16px] h-[16px] mr-1' />
                            <p className='text-white font-bold text-[16px]'>Drop a message</p>
                        </div>
                        <div className='flex text-white items-center'>
                            <PiPhoneBold className='w-[16px] h-[16px] mr-1' />
                            <p className=' font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'>1-833-709-1946</p>
                        </div>
                        <div className='flex text-white items-center'>
                            <PiUsersThreeFill className='w-[16px] h-[16px] mr-1' />
                            <p className=' font-semibold text-[16px] my-2 hover:text-gray-400 cursor-pointer'> Help Center</p>
                        </div>

                    </div>
                </div>
               
            </div>
            
        </div>
    );
}

export default Footer;
