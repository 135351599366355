import { createStore, combineReducers } from "redux";


let initial = {
   selectedFeatures: [],
   selectedPropertyType: [],
   emailVerify: [],
   userData: [],
   slectedStories: '',
   slectedExpand: '',
   slectedMaintenance: '',
   slectedMaintenanceMax: '',
   slectedHouseSize: '',
   slectedHouseSizeMax: '',
   signUpComplete: '' ,
   dayofHousFull: '',
   dayofHousFullMax: '',
   slectedLotSize: '',
   slectedYeareBuilt: '',
   slectedYeareBuiltMax: '',
   slectedLotSizeMax: '',
   sortAdd: '',
   bedAdd: '',
   bathAdd: '',
   soldAdd: '',
   favList:[]
}


function productSection(oldData = initial, newData) {


   if (newData.type == "SLECTED_FEATURE") {
      oldData.selectedFeatures.push(newData.payload);
      console.log("slected redux", oldData.selectedFeatures);
      return { ...oldData, selectedFeatures: [...oldData.selectedFeatures] }


   } else if (newData.type == "SORT_SLECT") {
      oldData.sortAdd = newData.payload

      return { ...oldData, sortAdd: [...oldData.sortAdd] }

   } else if (newData.type == "BEDS_SLECT") {
      oldData.bedAdd = newData.payload

      return { ...oldData, bedAdd: [...oldData.bedAdd] }

   } else if (newData.type == "BATH_SLECT") {
      oldData.bathAdd = newData.payload


      return { ...oldData, bathAdd: [...oldData.bathAdd] }

   } else if (newData.type == "SOLD_SLECT") {
      oldData.soldAdd = newData.payload


      return { ...oldData, soldAdd: [...oldData.soldAdd] }

   } else if (newData.type == "D_SLECTED_FEATURE") {
      oldData.selectedFeatures = "";
      return { ...oldData, selectedFeatures: [...oldData.selectedFeatures] }
   } else if (newData.type == "D_UN_SLECTED_FEATURE") {
      let index = oldData.selectedFeatures.indexOf(newData.payload);
      if (index !== -1) {
         oldData.selectedFeatures.splice(index, 1);
         return { ...oldData, selectedFeatures: [...oldData.selectedFeatures] }
      }
   } else if (newData.type == "PROPERTY_SLECT") {
      oldData.selectedPropertyType.push(newData.payload);
      // console.log("slected redux", oldData.selectedPropertyType);
      return { ...oldData, selectedPropertyType: [...oldData.selectedPropertyType] }


   } else if (newData.type == "STORIES_SLECT") {
      oldData.slectedStories = newData.payload

      return { ...oldData, slectedStories: [...oldData.slectedStories] }
   } else if (newData.type == "LOT_SIZE_MIN") {
      oldData.slectedLotSize = newData.payload

      return { ...oldData, slectedLotSize: [...oldData.slectedLotSize] }
   } else if (newData.type == "LOT_SIZE_MAX") {
      oldData.slectedLotSizeMax = newData.payload

      return { ...oldData, slectedLotSizeMax: [...oldData.slectedLotSizeMax] }
   } else if (newData.type == "DaysONHOUSEFULL_MIN") {
      oldData.dayofHousFull = newData.payload

      return { ...oldData, dayofHousFull: [...oldData.dayofHousFull] }
   } else if (newData.type == "DaysONHOUSEFULL_MAX") {
      oldData.dayofHousFullMax = newData.payload

      return { ...oldData, dayofHousFullMax: [...oldData.dayofHousFullMax] }
   } else if (newData.type == "YEAR_BUILT_MIN") {
      oldData.slectedYeareBuilt = newData.payload

      return { ...oldData, slectedYeareBuilt: [...oldData.slectedYeareBuilt] }
   } else if (newData.type == "YEAR_BUILT_MAX") {
      oldData.slectedYeareBuiltMax = newData.payload

      return { ...oldData, slectedYeareBuiltMax: [...oldData.slectedYeareBuiltMax] }
   } else if (newData.type == "MAINTENANCEFEE_MIN") {
      oldData.slectedMaintenance = newData.payload

      return { ...oldData, slectedMaintenance: [...oldData.slectedMaintenance] }
   } else if (newData.type == "MAINTENANCEFEE_MAX") {
      oldData.slectedMaintenanceMax = newData.payload

      return { ...oldData, slectedMaintenanceMax: [...oldData.slectedMaintenanceMax] }
   } else if (newData.type == "HOMESIZE_MIN") {
      oldData.slectedHouseSize = newData.payload

      return { ...oldData, slectedHouseSize: [...oldData.slectedHouseSize] }
   } else if (newData.type == "HOMESIZE_MAX") {
      oldData.slectedHouseSizeMax = newData.payload

      return { ...oldData, slectedHouseSizeMax: [...oldData.slectedHouseSizeMax] }
   } else if (newData.type == "EXPAND_SLECT") {
      oldData.slectedExpand = newData.payload

      return { ...oldData, slectedExpand: [...oldData.slectedExpand] }
   }else if (newData.type == "FAV") {
      oldData.favList.push(newData.payload) 
console.log('store fav' , oldData.favList);
      return { ...oldData, favList: [...oldData.favList] }
   }  else if (newData.type == "PROPERTY_UNSLECT") {
      let index = oldData.selectedPropertyType.indexOf(newData.payload);
      if (index !== -1) {
         oldData.selectedPropertyType.splice(index, 1);
         return { ...oldData, selectedPropertyType: [...oldData.selectedPropertyType] }
      }
   }


   return { ...oldData };

}


function signUp(oldData = initial, newData) {
   if (newData.type == "EMAIL_VERIFY") {


      console.log('sign chulling');
      oldData.emailVerify.push(newData.payload);


      console.log("checking sign", oldData.signupData);

      return { ...oldData, emailVerify: [...oldData.emailVerify] }
   } else if (newData.type == "SIGN_UP") {
      oldData.userData.push(newData.payload);

      console.log("checking userDartta", oldData.userData);
      return { ...oldData, userData: [...oldData.userData] }

   } else if (newData.type == "SIGHN_UP_COMPLETE") {
      oldData.signUpComplete = 'login';

      console.log("store signup complete", oldData.signUpComplete);
      return { ...oldData, signUpComplete: [...oldData.signUpComplete] }

   }

   return { ...oldData };
}


let data = combineReducers({ productSection, signUp });

export let meraStore = createStore(data);