import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const BedsDrawer = () => {

    let dispatch = useDispatch();
    let beds = ['Any', "1+", "2+", "3+", "4+", "5+"]
    const [bedState, setBedState] = useState('');

    let bedAdd = useSelector((store) => store.productSection.bedAdd)


    useEffect(() => {
        if (Array.isArray(bedAdd)) {
            const result = bedAdd.join('').replace(/\s+/g, ' ');
            setBedState(result);
        }
    }, [bedAdd]);
    return (
        <div className=''>
  <p className='font-bold text mb-4 mt-4'>Beds</p>

        <div className='flex flex-wrap'>
            {
                beds.map((data , i ) => {
                    return <div onClick={() => {

                        dispatch({
                            type: "BEDS_SLECT",
                            payload: i === 0 ? '' :  data
                        })

                    }} className={` ${!bedState.length > 0 && i === 0 ? 'bgLightGray borderPrimery rounded-full w-fit m-1  flex font-semibold items-center py-1 px-2 primery my-1' : ''} ${bedState === data  && 'bgLightGray borderPrimery rounded-full w-fit m-1  flex font-semibold items-center py-1 px-2 primery my-1' } my-1 cursor-pointer flex  hover:bg-gray-100 primeryH border rounded-full w-fit   font-semibold items-center m-1  textBlack border-gray-400 py-1 px-2`}>
                        <p className='font-semibold min-w-[40px]  text-[14px]'>{data}</p>
                    </div>
                })
            }

        </div>
        </div>
    );
}

export default BedsDrawer;
