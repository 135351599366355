import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './AgentForm.css'
const AgentForm = () => {
    return (
        <div className='w-full mt-[80px] flex justify-center '>
            <div className=' w-fit lg:m-2 md:m-2 sm:m-2 max-w-[1400px] lg:w-full btn-bg lg:rounded-3xl md:rounded-3xl sm:rounded-3xl overflow-hidden  '>
                <div className='flex  lg:h-[640px] md:h-[640px] sm: '>
                    <div className='w-[50%] lg:block md:block sm:block hidden'>
                        <img src={require(`../../img/client.jpg`)} className='w-[100%] h-[100%]  object-fit' alt="" />
                    </div>
                    <div className='lg:w-[50%] md:w-[65%] sm:w-[65%]   lg:mt-6 md:mt-6 h-fit'>
                        <div className='text-center lg:mt-16 md:mt-16 sm:mt-6 mt-6 items-center'>
                            <span className='text-white font-bold text-[28px]'>Make your next move</span>
                            <div className='text-white font-bold lg:text-[18px] md:text-[14px] '>
                                Get matched with a local agent to help with all of your home buying and selling needs.
                            </div>
                        </div>
                        <div className='w-11/12  relative m-auto '>
                            <div
                                className="block  rounded-lg  p-6  w-full ">
                                <form>
                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 smgrid-cols-1 gap-2 ">

                                        <div className="relative mb-2">

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Full Name"
                                                className="lg:mb-3 md:mb-3  text-sm"

                                            >
                                                <Form.Control type="email" id='inputH' className=' ' placeholder="name@example.com" />
                                            </FloatingLabel>
                                        </div>


                                        <div className="relative mb-3" >

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Phone Number"
                                                className="lg:mb-3 md:mb-3 text-sm"

                                            >
                                                <Form.Control type="email" id='inputH' className=' ' placeholder="name@example.com" />
                                            </FloatingLabel>
                                        </div>
                                    </div>



                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 smgrid-cols-1 gap-2">

                                        <div className="relative mb-2">

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Email address"
                                                className="lg:mb-3 md:mb-3  text-sm"

                                            >
                                                <Form.Control type="email" id='inputH' className=' ' placeholder="name@example.com" />
                                            </FloatingLabel>
                                        </div>


                                        <div className="relative mb-3" data-te-input-wrapper-init>

                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Postal code of intrest"
                                                className="lg:mb-3 md:mb-3  text-sm"

                                            >
                                                <Form.Control type="email" id='inputH' className=' ' placeholder="name@example.com" />
                                            </FloatingLabel>
                                        </div>
                                    </div>



                                    <div className="relative mb-6" data-te-input-wrapper-init>

                                        <FloatingLabel controlId="floatingTextarea2" label="Question or Comments">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: '100px' }}
                                            />
                                        </FloatingLabel>
                                    </div>





                                    <div
                                        className="mb-6 flex min-h-[1.5rem] items-center justify-center pl-[1.5rem]">

                                        <label
                                            className="inline-block pl-[0.15rem] text-[10px] text-white hover:cursor-pointer"
                                            for="exampleCheck25">
                                            By submitting this information, I acknowledge I have read and agree to the Terms & Conditions and the Privacy & Security.
                                        </label>
                                    </div>

                                    <div className='w-full flex justify-center'>
                                        <button
                                            type="submit"
                                            className="inline-block w-[250px] rounded-lg   px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal bg-white text-colour     transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                            data-te-ripple-init
                                            data-te-ripple-color="light">
                                            Match with an agent
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgentForm;
