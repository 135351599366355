import React from 'react'
import { useState } from 'react'
import SchoolTab1 from '../SchoolTabs/SchoolTab1';

const PropertyDetailTabFour = () => {
  const [isActiveTab,setIsActiveTab]=useState();
  const [pub_pri_school,setpub_pri_school]=useState('Public');
  const responsive = {

    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 5 // optional, default to 5.
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 740, min: 200 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
  }
  const Pri_School=()=>{
    setpub_pri_school('Private');
  }
  const Pub_School=()=>{
    setpub_pri_school('Public');
  }
  return (
    <div className='px-2 w-full'>
      <p className='text-[32px] text-[#212529] font-bold'>Schools</p>
      <div className="overflow-auto">
      <div className='min-w-[755px] text-sm flex items-center '>
        <p className=' text-colour'>Filter Schools</p>
        <div className='flex mx-2 '>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(1)}>131 All</div>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(2)}>37 Assigned</div>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(3)}>94 Nearby</div>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(4)}>61 Elementary</div>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(5)}>37 Secondary</div>
        <div className='flex flex-row p-2 rounded-full mx-1 btn-bg text-white' onClick={()=>setIsActiveTab(6)}>4 French Immersion</div>
        </div>
      </div>
      </div>
      <div className='flex text-base text-[#757575] mt-3 mb-3 font-bold'> 
      <div className={`${pub_pri_school == 'Public' ? 'text-colour border-b-2 border-[#BF1017] ' : ''} p-2 cursor-pointer`} onClick={Pub_School}>91 Public Schools</div>
      <div className={`${pub_pri_school == 'Private' ? 'text-colour border-b-2 border-[#BF1017] ' : ''}  p-2 mx-2 cursor-pointer`} onClick={Pri_School} >40 Private Schools</div>
      </div>
      <SchoolTab1/>
      
    </div>
  )
}

export default PropertyDetailTabFour
