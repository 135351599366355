import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Collapse from 'react-bootstrap/Collapse';
import LoginModal from '../modal/LoginModal';

const Drawar = (props) => {
    const [open, setOpen] = useState(false);
    const [openSell, setOpenSell] = useState(false);
    const [buyData, setBuyData] = useState([{ title: "Home for Sale" }, { title: "Homes for Sale Near Me" }, { title: "Contact an Agent" }, { title: "Buying Resources" }, { title: "How Much Home Can i Afford" }, { title: "Home Buying Checklist" }, { title: "Tips for First-Time Homebuyers" }])
    const [forSale, setForSale] = useState([{ title: "Homes for Sale Near Me" }, { title: "Contact an Agent" }])
    const [buyingR, setBuyingR] = useState([{ title: "How Much Home Can i Afford" }, { title: "Home Buying Checklist" }, { title: "Tips for First-Time Homebuyers" }])
    const [sellR, setSellR] = useState([{ title: "Home Selling How-To" }, { title: "How to Stage a Home" }, { title: "How to Do an Open House" }])
    const [isClosed, setIsClosed] = useState(false);
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const { handleClose } = props
    return (
        <div>
            <Offcanvas show={props.show} onHide={handleClose}>

                <Offcanvas.Body>
                    <div className=''>
                        <div className='flex pr-2 pt-2 justify-end'>
                            <img className=' w-[20px] h-[20px] cursor-pointer' onClick={handleClose} src={require('../../img/close.png')} alt="" />
                        </div>
                        <p  onClick={() => setModalShow(true)} className='text-[16px] font-semibold cursor-pointer text-blue-900'>Login or Sign up</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-[16px] font-semibold'>Home</p>
                    </div>
                    <div className='my-3 cursor-pointer'>
                        <div onClick={() => {
                            setOpen(!open)
                            setOpenSell(false)
                        }
                        } className='flex justify-between'>
                            <p className='text-[16px] cursor-pointer font-semibold'>Buy</p>
                            {
                                open ?
                                    <img className='lg:hidden md:hidden sm:block  ' src={require('../../img/dropUp.png')} width={30} height={20} alt="" />

                                    :
                                    <img className='lg:hidden md:hidden sm:block  ' src={require('../../img/dropDown.png')} width={30} height={20} alt="" />
                            }


                        </div>
                        <Collapse in={open}>
                            <div className='pl-5'>
                                <p className='my-3 text-gray-700 font-bold'>Home for Sale</p>
                                {
                                    forSale.map((data) => {
                                        return <div className='my-3 font-semibold'>
                                            <p>{data.title}</p>
                                        </div>
                                    })
                                }
                                <p className='my-3 text-gray-700 font-bold'>Buying Resources</p>
                                {
                                    buyingR.map((data) => {
                                        return <div className='my-3 font-semibold'>
                                            <p>{data.title}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </Collapse>
                        <div onClick={() => {
                            setOpenSell(!openSell)
                            setOpen(false)
                        }} className='flex justify-between py-4 '>
                            <p className='text-[16px]  cursor-pointer font-semibold'>Sell</p>
                            {
                                openSell ?
                                    <img className='lg:hidden md:hidden sm:block  ' src={require('../../img/dropUp.png')} width={30} height={20} alt="" />

                                    :
                                    <img className='lg:hidden md:hidden sm:block  ' src={require('../../img/dropDown.png')} width={30} height={20} alt="" />
                            }

                        </div>
                        <Collapse in={openSell}>
                            <div className='pl-5'>
                                <p className=' text-gray-700 font-bold'>Selling Resources</p>
                                {
                                    sellR.map((data) => {
                                        return <div className='py-2 font-semibold'>
                                            <p>{data.title}</p>
                                        </div>
                                    })
                                }

                            </div>
                        </Collapse>
                        <div className='py-2 flex items-center '>
                        <p onClick={() => setOpenSell(!openSell)} className='text-[16px]  font-semibold'>Agents</p>
                        </div>
                       
                        <p onClick={() => setOpenSell(!openSell)} className='text-[16px] my-4 font-semibold'>Resource Center</p>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
            <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}

export default Drawar;
