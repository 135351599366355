import React from 'react';
import PropertyDetailCrouser from '../../component/propertyDetailCrouser/PropertyDetailCrouser';
import PropertyDetailDiscription from '../../component/PropertyDetailDiscription/PropertyDetailDiscription';

const PropertyDetail = () => {




  
  return (
    <div>
      <PropertyDetailCrouser />
      <PropertyDetailDiscription/>
    </div>
  );
}

export default PropertyDetail;
